import { PRICING_SECTION_ID } from 'common/constants';
import { ROUTES } from 'common/routes';

export const companiesData = {
  rsbsAddressData: [
    {
      id: 1,
      content: 'Remote Sensing Business Solutions Sp. Z O. O.',
    },
    {
      id: 2,
      content: 'Długosza 60A, 51-162 Wrocław, Poland',
    },
    {
      id: 3,
      content: 'sales@terraeye.co',
    },
  ],
  fourPointAddressData: [
    {
      id: 1,
      content: 'Four Point Space SARL',
    },
    {
      id: 2,
      content: 'Technoport, 9 Av. des Hauts-Fourneaux',
    },
    {
      id: 3,
      content: 'L-4362 Esch-sur-Alzette, Luxembourg',
    },
  ],
  linkedin: {
    content: 'Find us on LinkedIn',
    url: 'https://www.linkedin.com/company/terraeye/mycompany/',
  },
  links: [
    {
      id: 0,
      content: 'T & C of Digital Goods Provision',
      path: ROUTES.termsAndConditionsDgp,
      newTab: false,
      smooth: false,
    },
    {
      id: 1,
      content: 'Pricing',
      path: `/#${PRICING_SECTION_ID}`,
      newTab: false,
      smooth: true,
    },
    {
      id: 2,
      content: 'Contact Us',
      path: ROUTES.contactUs,
      newTab: false,
      smooth: false,
    },
    {
      id: 3,
      content: 'Terms & Conditions',
      path: ROUTES.termsAndConditions,
      newTab: false,
      smooth: false,
    },
    {
      id: 4,
      content: 'Digital Twin',
      path: ROUTES.digitalTwin,
      newTab: true,
      smooth: false,
    },
    {
      id: 5,
      content: 'Privacy Policy',
      path: ROUTES.privacyPolicy,
      newTab: false,
      smooth: false,
    },
    {
      id: 6,
      content: 'EU Grants - EcoSat',
      path: ROUTES.ecoSat,
      newTab: false,
      smooth: false,
    },
    {
      id: 7,
      content: 'Data',
      path: ROUTES.data,
      newTab: false,
      smooth: false,
    },
    {
      id: 8,
      content: 'Privacy Policy of the TerraEye App',
      path: ROUTES.privacyPolicyTerraeyeApp,
      newTab: false,
      smooth: false,
    },
    {
      id: 9,
      content: 'EU Grants - MineCam',
      path: ROUTES.mineCam,
      newTab: false,
      smooth: false,
    },
    {
      id: 10,
      content: 'Reports',
      path: ROUTES.reports,
      newTab: false,
      smooth: false,
    },
    {
      id: 11,
      content: 'Newsletter Regulations',
      path: ROUTES.newsletterRegulations,
      newTab: false,
      smooth: false,
    },
    {
      id: 12,
      content: 'EU Grants - Hype4Explor',
      path: ROUTES.hype4Explor,
      newTab: false,
      smooth: false,
    },
  ],
};
