import { t } from 'i18next';

import PageHelmetWrapper from 'components/PageHelmetWrapper';
import SubPageLayout from 'components/SubPage';
import RequestAccessSection from './RequestAccessSection';
import { SubPageImage } from 'components/SubPageImage';
import requestAccessBackgroundDesktop from 'assets/images/requestAccess/request-access-desktop.jpg';
import requestAccessBackgroundTablet from 'assets/images/requestAccess/request-access-tablet.jpg';
import digitalTwinBackgroundDesktop from 'assets/images/digitalTwin/digital-twin-desktop.jpg';
import digitalTwinBackgroundTablet from 'assets/images/digitalTwin/digital-twin-tablet.jpg';

const RequestAccessPage = ({ digitalTwin = false }) => {
  return (
    <PageHelmetWrapper page={digitalTwin ? 'digitalTwinContactUs' : 'requestAccess'}>
      <SubPageLayout
        image={
          <SubPageImage
            desktopUrl={digitalTwin ? digitalTwinBackgroundDesktop : requestAccessBackgroundDesktop}
            tabletUrl={digitalTwin ? digitalTwinBackgroundTablet : requestAccessBackgroundTablet}
          />
        }
      >
        <RequestAccessSection
          title={digitalTwin ? t('contact.title') : 'Contact us'}
          description={
            digitalTwin
              ? t('contact.description')
              : 'Feel free to reach out to us with questions regarding collaboration or specific areas of interest for your exploration needs.'
          }
          digitalTwin={digitalTwin}
        />
      </SubPageLayout>
    </PageHelmetWrapper>
  );
};

export default RequestAccessPage;
