import { ROUTES } from 'common/routes';
import { ArticlesList } from 'components/Article/styled';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import LegalPageLayout from 'components/LegalContentWrapper';
import { ArticleOrderedList, ArticleOrderedSublist, ArticleTitle, TableWrapper } from 'components/Article/styled';
import { Container } from 'ui/atoms/container';
import { TextLink } from 'ui/atoms/textLink';
import PageHelmetWrapper from 'components/PageHelmetWrapper';

const TermsAndConditions = () => {
  return (
    <PageHelmetWrapper page="termsAndConditions">
      <LegalPageLayout title="Terms & Conditions">
        <ArticlesList isNarrow>
          <ArticleWrapper title="§ 1. General information">
            <ArticleOrderedList>
              <li>
                prices, constitute an invitation to conclude a contract for the delivery of services within the meaning
                of art. 71 of the Act of 23 April 1964 Civil Code (hereinafter: "Civil Code").
              </li>
              <li>
                The Service Recipient and the User are obliged to read the Terms and Conditions before using the App.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 2. Definitions">
            Capitalized words used in the Regulations have the following meaning:
            <ArticleOrderedList>
              <li>
                Account - a panel created in the IT system of the Application, enabling the User to use its
                functionalities, including the Account Service;
              </li>
              <li>
                Account Service - a digital service within the meaning of the provisions of the Act on consumer rights,
                consisting in the creation and maintenance of an Account by the Service Provider for the User;
              </li>
              <li>Act on the provision of electronic services - a term defined in § 1 section 2 of the Regulations;</li>
              <li>
                Agreement - Agreement for the provision of the Account Service and the Agreement for the provision of
                the Application Use Service;
              </li>
              <li>
                Agreement for the provision of the Account Service - an agreement for the provision of a digital service
                within the meaning of the Act on consumer rights, under which the Service Provider undertakes to provide
                the Account Service to the User for an specified period of time, and the User undertakes to provide the
                Service Provider with his data, including personal data;
              </li>
              <li>
                Agreement for the provision of the Application Use Service - an agreement for the provision of a digital
                service within the meaning of the Act on consumer rights, under which the Service Provider undertakes to
                provide the Service Recipient with the Application Use Service for a fee; the condition for concluding
                the Agreement for the provision of the Service for the use of the Application is the prior conclusion of
                the Agreement for the provision of the Account Service;
              </li>
              <li>
                Application Use Service - a digital service within the meaning of the Act on consumer rights, consisting
                in enabling the Client to use the functionality of the Application consisting in satelite data
                analytics, report generation.
              </li>
              <li>Civil Code - a term defined in § 1 section 6 of the Terms and Conditions;</li>
              <li>
                Consumer - a natural person performing a legal transaction with the Service Provider not directly
                related to its business or professional activity;
              </li>
              <li>Consumer Rights Act - the Act of 30 May 2014 on consumer rights;</li>
              <li>Digital Service - Account Service and Application Use Service;</li>
              <li>
                Entrepreneur - a natural person, a legal person or an organizational unit without legal personality, to
                which special provisions grant legal capacity, conducting business or professional activity on its own
                behalf;
              </li>
              <li>
                Entrepreneur with Consumer Rights - a natural person conducting business or professional activity on his
                own behalf, who has concluded an Agreement with the Service Provider directly related to his business
                activity, but not having a professional character for that person, resulting in particular from the
                subject of his business activity;
              </li>
              <li>License - term defined in § 9 section 7 of the Regulations;</li>
              <li>
                Non-compliance - it is understood as non-compliance of the Subject of the Digital Service with the
                Agreement regarding its provision (the criteria for assessing the compliance of the Subject of the
                Digital Service with the agreement regarding its delivery are set out in Article 43k paragraphs 1-2 of
                the Act on consumer rights);
              </li>
              <li>
                Package - a set of specific Services offered by the Service Provider via the Application; currently
                available Packages and fees for them are shown in the Price List;
              </li>
              <li>
                Personal Data Processing Agreement - an agreement specifying the terms and conditions for entrusting by
                the Service Recipient (acting as a personal data controller) to the Service Provider (acting as a
                processor) the processing of personal data (other than the Service Recipient's personal data) stored on
                the Client Account;
              </li>
              <li>
                Price List - a document or information specifying the current price of the Digital Service and Service
                Packages for the use of the App;
              </li>
              <li>
                Privacy Policy - a document containing information on the processing of personal data of Service
                Recipients by the Service Provider;
              </li>
              <li>
                Review - the Client's opinion on the Service of using the Application, including a description of the
                Client's experience related to the use of the above-mentioned Services;
              </li>
              <li>Service Provider - a term defined in § 1 section 3 of the Regulations;</li>
              <li>
                Service Recipient or Client - a client using the Application, being an Entrepreneur, Entrepreneur with
                Consumer rights or Consumer;
              </li>
              <li>Subject of digital provision - Account Service or Application Use Service;</li>
              <li>
                Subscription Fee - a fee paid by the Client in in advance in exchange for the Service of using the
                Application determined in accordance with the Price List in force at the time of its order;
              </li>
              <li>
                Subscription Period - the period for which the Service Provider provides the Client with the Service
                Recipient to use the Application in accordance with the Price List and the Package selected by the
                Client;
              </li>
              <li>Terms and Conditions or Regulations - a term defined in § 1 section 1 of the Regulations;</li>
              <li>
                User - a person who has an Account in the Application who is a Consumer or a person authorized to
                represent the Service Recipient and use the Application on his behalf.
              </li>
              <li>
                User Content - all data (including personal data), electronic files, information and materials recorded
                by the User on the Account;
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 3. Technical requirements">
            <ArticleOrderedList>
              <li>
                In order to properly use the services provided by the Service Provider via the App, all of the following
                requirements must be satisfied jointly:
                <ArticleOrderedSublist>
                  <li>a device with a monitor (screen);</li>
                  <li>connection to the Internet;</li>
                  <li>equipment allowing the use of Internet resources;</li>
                  <li>
                    the current version of the Chrome web browser allowing hypertext documents to be displayed on the
                    screen of the device, linked to the Internet via the World Wide Web service and supporting the
                    JavaScript programming language, as well as accepting cookies;
                  </li>
                  <li>an active e-mail account.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The use of viruses, bots, worms or other computer codes, files or programmes (in particular process
                automation scripts and applications or other codes, files or tools) is prohibited on the App.
              </li>
              <li>
                The Service Provider informs that it uses cryptographic protection of electronic transfer and digital
                content by means of appropriate logical, organisational and technical measures, in particular to
                third-party access to the data, including by SSL encryption, use of passwords and anti-virus or
                anti-malware programmes.
              </li>
              <li>
                The Service Provider advises that despite the safeguards referred to in Section 3 above, the use of the
                Internet and services provided by electronic means may be at risk of malicious software entering the
                Client's computer system and device or of third parties gaining access to the data on that device. In
                order to minimise the aforementioned risk, the Service Provider recommends the use of anti-virus
                programmes or means of protecting identification on the Internet.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 4. General principles of Service provision">
            <ArticleOrderedList>
              <li>
                The Service Recipient is obliged to use the services provided by the Service Provider in a manner
                consistent with the provisions of generally applicable law, the provisions of the Regulations, as well
                as with good customs.
              </li>
              <li>Providing illegal content by the User is prohibited.</li>
              <li>
                The Service Recipient using the services provided by the Service Provider is obliged to provide only
                data (including personal data) consistent with the actual state. The Service Provider shall not be
                liable for the consequences of providing false or incomplete data by the Client.
              </li>
              <li>
                If the activities in the Application (in particular the conclusion of the Agreement) are performed by a
                natural person acting on behalf of the Client who is not a natural person, performing such an action is
                tantamount to submitting by the natural person performing such an action a statement that it is entitled
                to represent the Service Recipient. The Service Provider is entitled to require such a natural person to
                provide proof of its authorization to represent the Client, in particular a power of attorney document
                or an extract from the relevant register. In the case of performing an action on behalf of the Client
                despite the lack of authorization to represent him, the natural person performing this action bears the
                responsibility provided for in the provisions of the Civil Code.
              </li>
              <li>
                One Service Recipient may conclude only one Agreement for the provision of the Application Use Service.
              </li>
              <li>Prices of services and Packages are expressed in USD and are net and do not include VAT.</li>
              <li>
                The conclusion of the Agreement for the provision of the Service for the use of the Application requires
                the prior conclusion of the Agreement for the provision of the Account Service.
              </li>
              <li>
                The following shall be considered a violation of the Regulations:
                <ArticleOrderedSublist>
                  <li>providing illegal content by the User;</li>
                  <li>misuse of the Digital Services;</li>
                  <li>providing false or incomplete data by the Service Recipient or User;</li>
                  <li>
                    a natural person performs an action on behalf of the Client despite the lack of authorization to
                    represent him;
                  </li>
                  <li>
                    conclusion by the Client of more than one Agreement for the provision of the Application Use Service
                    without the consent of the Service Provider.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                In the event of a violation of the Regulations, the Service Provider may call the Client to remove it
                and set a deadline of not less than 7 (seven) days for this purpose.
              </li>
              <li>
                All statements, calls, notifications, and information referred to in the Regulations may be submitted by
                e-mail, unless a specific provision of the Regulations provides otherwise.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 5. Account Service Agreement">
            <ArticleOrderedList>
              <li>
                In order to conclude the Agreement for the provision of the Account Service, the User should perform the
                following actions:
                <ArticleOrderedSublist>
                  <li>enter the website of the Application and then click on the "Sign up" tab;</li>
                  <li>in the form that appears, enter the name, e-mail address (which is also the User's login);</li>
                  <li>
                    it is obligatory to mark the checkbox next to the statement of reading the Regulations and Privacy
                    Policy and accepting their provisions;
                  </li>
                  <li>click "Register".</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                After clicking the "Register" option, a link to activate the Account is sent to the e-mail address
                provided by the User. After clicking on the link, the User gains access to the Account. Clicking on the
                sent link by the User is tantamount to the conclusion by the User of the Agreement for the provision of
                the Account Service.
              </li>
              <li>
                With the help of the Account, the User may in particular:
                <ArticleOrderedSublist>
                  <li>store their data and the data of the Client represented by him;</li>
                  <li>purchase Packages;</li>
                  <li>use the services included in the purchased Package.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider informs, and the Client acknowledges that maintaining compliance of the Account
                Service with the Agreement for the provision of the Account Service does not require the Client to
                install its updates.
              </li>
              <li>
                If the User is not granted access to the Account immediately after the conclusion of the Agreement for
                the provision of the Account Service, the User calls the Service Provider to immediately grant access to
                the Account. The summons referred to in the preceding sentence may be sent by e-mail to the address
                indicated in § 1 section 4 point 1 of the Regulations. In the event that the Service Provider does not
                grant the User access to the Account immediately after receiving the request referred to in the
                preceding sentence, the User may withdraw from the Agreement for the provision of the Account Service.
              </li>
              <li>
                Notwithstanding the provisions of paragraph 6 above, in the absence of access to the Account to the
                User, the User may withdraw from the Agreement for the provision of the Account Service without calling
                the Service Provider to grant access to the Account, if at least one of the cases indicated in Article
                43j paragraph 5 of the Act on consumer rights occurs.
              </li>
              <li>
                The provisions of sections 6-7 above apply only to Service Recipients who are Consumers or Entrepreneurs
                with Consumer rights.
              </li>
              <li>
                Notwithstanding the provisions of sections 6-7 above, the User may at any time and without giving a
                reason terminate the Agreement for the provision of the Account Service with a notice period of 14
                (fourteen) days.
              </li>
              <li>
                Withdrawal by the User from the Agreement for the provision of the Account Service or its termination,
                regardless of the basis for this action, takes place by submitting to the Service Provider a statement
                of withdrawal from the Agreement for the provision of the Account Service or its termination. The
                statement referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1
                section 4 point 1 of the Regulations. The Service Provider deletes the Account immediately after
                receiving the statement referred to in the preceding sentence, or after the expiry of the notice period
                indicated in the Regulations.
              </li>
              <li>
                In the event that the User uses the Account in a manner contrary to the provisions of generally
                applicable law, the provisions of the Terms and Conditions or good practice, as well as the provision of
                illegal content by the User, the Service Provider may terminate the Agreement for the provision of the
                Account Service with a notice period of 7 (seven) days, by submitting a notice of termination to the
                User via e-mail. After the expiry of the notice period indicated in the preceding sentence, the Account
                is permanently deleted. During the notice period, the Service Provider may block the User's access to
                the Account for a period not exceeding 7 (seven) days if necessary to prevent further infringements by
                the User.
              </li>
              <li>
                In the case of applying the measure referred to in section 11 above, the User against whom the measure
                has been applied may file an appeal on the terms described in § 9 of the Terms and Conditions.
              </li>
              <li>
                If, before withdrawing from the Agreement for the provision of the Account Service or its termination,
                regardless of the basis for this action, the Service Recipient concluded an Agreement for the provision
                of the Service for the use of the Application, withdrawal from the Agreement for the provision of the
                Account Service or its termination by either party is tantamount to simultaneous withdrawal from the
                Agreement for the provision of the Application Use Service and its termination.
              </li>
              <li>
                If the Service Provider terminates the Agreement for the provision of the Account Service to the User
                who is a Consumer or Entrepreneur with the rights of a Consumer, the User who is a Consumer is entitled
                to a refund of part of the remuneration in the amount proportional to the period remaining from the end
                of the notice period specified in the Agreement for the provision of the Account Service.
              </li>
              <li>
                The return of part of the remuneration referred to in paragraph 13 above takes place within 14
                (fourteen) days from the date of termination of the Agreement for the provision of the Account Service
                by the Service Provider.
              </li>
              <li>
                Suspension of the provision of the Account Service (regardless of the basis for this action) is
                tantamount to deleting the Account and all Content stored on it.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 6. Agreement for the provision of the Application Use Service">
            <ArticleOrderedList>
              <li>
                On the basis of the Agreement for the provision of the Application Use Service, the Service Provider
                enables the Client (to the extent resulting from the Package purchased by the Client) to use the
                following functions of the Application:
                <ArticleOrderedSublist>
                  <li>spectral classification algorithm (SAM - Spectral Angle Mapper);</li>
                  <li>spectral unmixing algorithm (MTMF - Mixture Tuned Matched Filtering).</li>
                  <li>Land Segmentation and Masking</li>
                  <li>Bare Earth Composite</li>
                  <li>Machine Learning classification Algorithms</li>
                  <li>
                    Connecting Remote Sensing data with Ground Samples
                    <br />
                    Those functionalities will be rolled out throughout 2024
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                To conclude the Agreement for the provision of the Service for the use of the Application, the Service
                Recipient should perform the following actions:
                <ArticleOrderedSublist>
                  <li>enter the Application and go to the registration form;</li>
                  <li>
                    in the form that appears, enter the e-mail address and password created by the Service Recipient;
                  </li>
                  <li>
                    it is obligatory to tick the checkbox next to the declaration of consent to start providing the
                    Application Use Service before the deadline for withdrawal from the Agreement for the provision of
                    the Application Use Service (the right of withdrawal referred to in this point 4 is granted only to
                    Service Recipients who are Consumers or Entrepreneurs with Consumer rights);
                  </li>
                  <li>
                    it is obligatory to mark the checkbox next to the statement of reading the Regulations and Privacy
                    Policy and accepting their provisions;
                  </li>
                  <li>
                    make a payment of remuneration for the Application Use Service (unless, in accordance with the Price
                    List, the Service Recipient may use the Application Use Service free of charge);
                  </li>
                  <li>click on the "Order with obligation to pay" option.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Clicking the "Order with obligation to pay" option and making the payment of remuneration (if required)
                is tantamount to the conclusion by the Client of the Agreement for the provision of the Service for the
                use of the Application.
              </li>
              <li>The contract for the provision of the Application Use Service is concluded for a definite period.</li>
              <li>
                The use of the Application Use Service after the end of the term of the Application Use Agreement
                requires the conclusion of another Agreement for the provision of the Application Use Service.
              </li>
              <li>
                After clicking the "Order with the obligation to pay" option, the Client should authenticate the
                previously provided e-mail address and supplement the Account with the following data:
                <ArticleOrderedSublist>
                  <li>name and surname;</li>
                  <li>telephone number;</li>
                  <li>
                    company, NIP, invoice address, bank name and bank account number - if the Service Recipient is an
                    Entrepreneur or an Entrepreneur with Consumer rights.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider informs, and the Service Recipient acknowledges that maintaining the compliance of
                the Application Use Service with the Agreement for the provision of the Application Use Service does not
                require to install its update.
              </li>
              <li>
                If the Client is not granted access to the Application Use Service immediately after the conclusion of
                the Agreement for the provision of the Application Use Service, the Service Recipient calls the Service
                Provider to immediately grant access to the Application Use Service. The summons referred to in the
                preceding sentence may be sent by e-mail to the address indicated in § 1 section 4 point 1 of the
                Regulations. If the Service Provider does not grant the Client access to the Application Use Service
                immediately after receiving the call referred to in the preceding sentence, the Client may withdraw from
                the Agreement for the provision of the Application Use Service.
              </li>
              <li>
                Notwithstanding the provisions of paragraph 8 above, in the absence of granting the Client access to the
                Application Use Service, the Client may withdraw from the Agreement for the provision of the Application
                Use Service without calling the Service Provider to grant access to the Application Use Service, if at
                least one of the cases indicated in Article 43j paragraph 5 of the Act on consumer rights occurs.
              </li>
              <li>
                The provisions of sections 8-9 above apply only to Service Recipients who are Consumers or Entrepreneurs
                with Consumer rights.
              </li>
              <li>
                In the event of withdrawal by the Client from the Agreement for the provision of the Application Use
                Service pursuant to sections 8-9 above, the Service Provider shall immediately suspend the provision of
                the Application Use Service and return the fee to the Service Recipient (if it has been paid) within 14
                (fourteen) days from the date of receipt of the statement of withdrawal from the Agreement for the
                provision of the Application Use Service.
              </li>
              <li>
                In the event of a breach by the Client of the provisions of the Regulations and failure to remove this
                violation despite receiving the request referred to in § 4 section 8 of the Regulations, the Service
                Provider may terminate the Agreement for the provision of the Service for the use of the Application
                with a notice period of 7 (seven) days, by submitting a notice statement of termination to the Client by
                e-mail. After the expiry of the notice period indicated in the preceding sentence, the Service Provider
                suspends the provision of the Application Use Service. During the notice period, the Service Provider
                may block the Service Recipient's access to the Application Use Service if it is necessary to prevent
                further violations by the Client.
              </li>
              <li>
                If the Service Provider terminates the Agreement for the provision of the Application Use Service, the
                Service Recipient who is a Consumer or an Entrepreneur with the rights of a Consumer is entitled to a
                refund of part of the remuneration in the amount proportional to the period remaining from the end of
                the notice period remaining until the end of the Agreement specified in the Agreement for the provision
                of the Application Use Service.
              </li>
              <li>
                The refund of part of the remuneration referred to in paragraph 13 above takes place within 14
                (fourteen) days from the date of termination of the Agreement for the provision of the Service for the
                use of the Application by the Service Provider.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 7. Fees & Billing">
            <ArticleOrderedList>
              <li>
                Unless a specific provision of the Regulations or individual arrangements with the Service Provider
                provide otherwise, all payments due to the Service Provider are made by the Service Recipients using the
                payment systems made available as part of the Application.
              </li>
              <li>
                For the provision of Services, the Client is obliged to pay the Subscription Fee according to the
                amounts indicated in the Price List and Supplements. Changes in prices indicated in the Price List and
                add-ons are announced on the Website and do not constitute changes to these Regulations.
              </li>
              <li>
                The date of payment is the date of booking the amount of the Subscription Fee due for the Service of
                using the Application on the Service Provider's bank account. After the payment is posted, the Service
                Provider sends to the Client's e-mail address information about granting access to the purchased Service
                of using the Application together with a VAT invoice.
              </li>
              <li>
                Payments for the Service of using the Application may be made on a subscription basis (hereinafter:
                "Subscription") - during the Subscription period, the amounts due are automatically charged to the
                User's account for each subsequent Subscription Period;
              </li>
              <li>
                Before the end of the current Subscription Period, the Service Provider shall inform the Client via
                e-mail about the expiry of the Subscription Period and about the amount of fees for extending the period
                of providing the Access to the Application Service for the next Subscription Period. The extension of
                the Subscription Period is tantamount to concluding another Agreement for the provision of the
                Application Use Service on the terms set out in the Regulations.
              </li>
              <li>
                The Service Recipient shall extend the Subscription Period by making a payment to the bank account
                indicated by the Service Provider for the use of the Application Use Service in the next Subscription
                Period.
              </li>
              <li>
                If the Subscription Fee was paid before the date of expiry of the previous Subscription Period, the next
                Subscription Period shall be counted from the date following the end of the given Subscription Period.
              </li>
              <li>
                If the Subscription Fee was paid after the end of the previous Subscription Period, the next
                Subscription Period will be counted from the date of payment of the Subscription Fee towards the next
                Subscription Period.
              </li>
              <li>
                Lack of payment on the Service Provider's bank account for the use of the Services in the next
                Subscription Period will result in blocking access to the Application Use Service. In this case, the
                Client's data will be stored by the Service Provider for a period of 30 [thirty] calendar days, counting
                from the day following the date of expiry of the Subscription Period. During this time, the Client may
                at any time resume using the Services by paying the Subscription Fee or delete his Account from the
                Application.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 8. User Content and Reviews">
            <ArticleOrderedList>
              <li>
                The Service Recipient may send the Service Provider a Review regarding the services provided by the
                Service Provider.
              </li>
              <li>Reviews may be sent by any means, including by e-mail.</li>
              <li>Sending a Review does not impose an obligation on the Service Provider to publish it.</li>
              <li>
                It is prohibited to post User Content and Reviews:
                <ArticleOrderedSublist>
                  <li>containing false data, contrary to the law, the Terms and Conditions or good practice;</li>
                  <li>
                    Content that is prohibited by law, incites violence, hatred, or insults any group of people or
                    individuals.
                  </li>
                  <li>
                    containing content that may infringe personal rights, copyrights, image rights or other rights of
                    third parties;
                  </li>
                  <li>Content that is advertising, promotional, political, religious, or discriminatory</li>
                  <li>containing content promoting competitive activities against the Service Provider.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Each person using the Application (hereinafter referred to as the "Reporting User") is entitled to
                report User Content or Reviews that may violate the Terms and Conditions.
              </li>
              <li>
                You can make your application in the following ways:
                <ArticleOrderedSublist>
                  <li>by e-mail to the following address: app@terraeye.co,</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Te application should include the following information:
                <ArticleOrderedSublist>
                  <li>
                    a sufficiently substantiated explanation of the reasons why the User Content or Review in question
                    constitutes illegal content;
                  </li>
                  <li>
                    A clear indication of the exact electronic location of the information, such as the exact URL or
                    URLs, and, where applicable, additional information to identify the User Content or Reviews, as
                    appropriate to its type and the functionality of the Application;
                  </li>
                  <li>
                    the name and email address of the Reporter, with the exception of the report concerning information
                    considered to be related to one of the offences referred to in Articles 3 to 7 of Directive
                    2011/93/EU,
                  </li>
                  <li>
                    a statement confirming the Reporting User's good faith belief that the information and allegations
                    contained therein are correct and complete.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Upon receipt of the application, the Service Provider sends the Reporting User a confirmation of its
                receipt to the e-mail address provided by the Reporting User.
              </li>
              <li>
                In the event that the application does not contain the elements indicated in section 8 above or contains
                errors, the Service Provider may ask the Reporting User to supplement or correct the application within
                14 days from the date of receipt of the above-mentioned request. In the event that the Reporting User
                fails to complete or correct the application within the time limit specified in the preceding sentence,
                the Service Provider may leave the application unexamined.
              </li>
              <li>
                The Service Provider verifies the submitted User Content or Review within 14 days from the date of
                receipt of a complete and correct application. As part of the verification process, if necessary, we
                will ask you to submit any additional information or documents. Until we investigate your report, we may
                block the visibility of your User Content or Review.
              </li>
              <li>
                After verifying the Application, the Service Provider:
                <ArticleOrderedSublist>
                  <li>removes any User Content or Review that violates the Terms and Conditions;</li>
                  <li>
                    restores the User Content or the Review that does not violate the rules resulting from the Terms and
                    Conditions (if its visibility was blocked at the stage of verification of the Application) giving
                    the reasons for our decision.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                In the event of removal of User Content or Review, the Service Provider shall immediately notify both
                the Reporting User and the User who published the removed User Content or Review, providing the reasons
                for their decision.
              </li>
              <li>
                The justification of the Service Provider's decision includes:
                <ArticleOrderedSublist>
                  <li>
                    an indication of whether the decision includes the removal, disappearance, depositioning, or other
                    measures referred to in the Terms and Conditions in relation to that Review and, where applicable,
                    the territorial scope of the decision and its duration;
                  </li>
                  <li>
                    the facts and circumstances on the basis of which the decision was made, including, where
                    applicable, whether the decision was made on the basis of a report made by the Reporting User or on
                    the basis of voluntary verification activities carried out at the initiative of the Service Provider
                    and, where strictly necessary, the identity of the Reporting User;
                  </li>
                  <li>
                    where applicable, information about the use of automated means in decision-making, including whether
                    a decision was made in relation to User Content/Reviews detected or identified using automated
                    tools;
                  </li>
                  <li>
                    if the decision relates to potentially prohibited User Content/Review, an indication of the legal or
                    contractual basis on which the decision is based and an explanation of the reasons why the Review is
                    considered to be prohibited on that basis;
                  </li>
                  <li>clear and user-friendly information about their options to appeal the decision.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                A User whose User Content or Review has been removed or a Reporter who is refused removal by the Service
                Provider may appeal against the Service Provider's decision.
              </li>
              <li>
                You can file an appeal in the following ways:
                <ArticleOrderedSublist>
                  <li>by e-mail - to the following address: sales@terraeye.co</li>
                  <li>
                    in writing, preferably by registered mail, to the following address: Jana Długosza 60a, 51-162,
                    Wrocław
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The appeal should include:
                <ArticleOrderedSublist>
                  <li>the name of the appellant,</li>
                  <li>contact details (e-mail address, correspondence address),</li>
                  <li>
                    a detailed justification of why, in the appellant's Review, why the Service Provider's decision is
                    erroneous and should be changed.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider shall immediately confirm receipt of the appeal by sending a notification to the
                e-mail address provided by the Appellant.
              </li>
              <li>
                Appeals are considered within 14 days from the date of their receipt by the Seller's authorized team
                (these activities will not be performed in an automated manner, without human intervention).
              </li>
              <li>
                By submitting User Content or Review, the Service Recipient declares that he/she is the sole author of
                the Content. The User bears full responsibility for the User Content/the content of the Review and the
                consequences of its publication (including infringement of personal rights and intellectual property
                rights of third parties).
              </li>
              <li>
                By submitting User Content/Review, the Service Recipient grants the Service Provider a free,
                non-exclusive license for its use (hereinafter referred to as the "License"). The License is granted for
                an indefinite period of time (with the possibility of termination two years in advance, effective at the
                end of the calendar year) and without territorial limitations, and covers the use of the User
                Content/Review in the following fields of use: recording and reproduction in any quantity, by any
                technique and in any format; dissemination in any way, by any means of communication, in particular by
                publication in the Application and in the Service Provider's social media. The License entitles the
                Service Provider to modify the Review if it is necessary to disseminate it in a specific way, without
                changing its essence and content. The License authorizes the Service Provider to grant any selected
                third parties further licenses to use the Reviews. Further license referred to in the preceding sentence
                may be granted by the Service Provider for a fee or free of charge.
              </li>
              <li>
                The Service Recipient undertakes not to exercise his/her moral rights to the User Content/Reviews
                (including the right to indicate the authorship of the User Content/Reviews and the right to supervise
                the use of the User Content/Reviews) and authorizes the Service Provider to exercise these rights on
                behalf of the Service Recipient.
              </li>
              <li>
                Notwithstanding the provisions of paragraphs 1-25 above, the Service Recipient grants the Service
                Provider a free, non-exclusive license to use the name and logo of the Service Recipient (hereinafter:
                "Logo").
              </li>
              <li>
                The licence referred to in section 12 above shall be granted for an indefinite period of time (with the
                possibility of its termination for two years in advance, effective at the end of the calendar year) and
                without territorial limitations, and shall cover the use of the Logo in the following fields of
                exploitation:
                <ArticleOrderedSublist>
                  <li>recording and reproduction in any quantity, by any technique and in any format;</li>
                  <li>
                    dissemination in any way, by any means of communication, in particular by publication in the
                    Application and in the Service Provider's social media.
                  </li>
                </ArticleOrderedSublist>
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 9. Right of withdrawal">
            <ArticleOrderedList>
              <li>
                The provisions of this § 9 apply only to Service Recipients who are Consumers or Entrepreneurs with
                Consumer rights.
              </li>
              <li>
                Pursuant to Article 27 et seq. of the Act on consumer rights, the Client has the right to withdraw from
                the Agreement without giving any reason within 14 (fourteen) days from the date of its conclusion.
              </li>
              <li>
                The right to withdraw from the Agreement, the Service Recipient performs by submitting to the Service
                Provider a statement of withdrawal from the Agreement. To meet the deadline for withdrawal from the
                contract, it is sufficient to send a statement before the deadline referred to in paragraph 2 above.
              </li>
              <li>
                The statement of withdrawal from the Agreement may be submitted by the Client in any form, in particular
                on the form constituting Annex 2 to the Act on consumer rights.
              </li>
              <li>
                In the event of submitting a statement of withdrawal from the Agreement, the Service Provider shall
                immediately send the Client a confirmation of its receipt by e-mail.
              </li>
              <li>
                The Service Provider informs, and the Client acknowledges that the right to withdraw from the Agreement
                referred to in this paragraph is not entitled to the Client in relation to the Agreement for the
                provision of a service that has been fully performed (pursuant to Article 38 paragraph 1 point 1 of the
                Act on consumer rights).
              </li>
              <li>
                If the withdrawal from the Agreement concerns the Agreement for the provision of a service that has not
                been fully performed, the Service Provider shall return to the Client the price of the Package reduced
                by the value of services used by the Client until the withdrawal from the Agreement. The refund of the
                price takes place within 14 (fourteen) days from the date of receipt by the Service Provider of the
                declaration of withdrawal from the Agreement and it is made using the same method of payment as used by
                the Client in the original transaction unless the Client expressly agrees to a different solution. The
                Service Recipient does not bear the costs of returning the payment made.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 10. Liability of the Service Provider">
            <ArticleOrderedList>
              <li>The Service Provider undertakes to provide services with due diligence.</li>
              <li>
                To the extent permitted by the provisions of the Civil Code and the Act on consumer rights, the Service
                Provider shall not be liable to the Clients for the consequences of:
                <ArticleOrderedSublist>
                  <li>
                    use by the Clients of any services available as part of the Application contrary to their intended
                    purpose;
                  </li>
                  <li>
                    providing incorrect or false data by Users, in particular data on the duration of cleaning or
                    repair;
                  </li>
                  <li>
                    the effects of using data authorizing access to the Account Service by third parties if these
                    persons came into possession of such data as a result of their disclosure by the User or as a result
                    of their insufficient protection by the Service Recipient against access by such persons.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                In relation to Service Recipients who are not Consumers or Entrepreneurs with consumer rights, the
                Service Provider's liability for damages incurred by the Client as a result of non-performance or
                improper performance of the obligation by the Service Provider is limited to damage in the form of
                actual losses incurred by the Service Recipient and up to an amount not exceeding the total amount of
                the Service Provider's remuneration paid by the Client for the last 12 (twelve) months before the damage
                occurred.
              </li>
              <li>
                To the extent permitted by the provisions of the Civil Code and the Act on consumer rights, the Service
                Provider shall not be liable for disruptions in the functioning of the Application resulting from:
                <ArticleOrderedSublist>
                  <li>force majeure;</li>
                  <li>necessary conservation and modernization works carried out in the Application;</li>
                  <li>reasons attributable to the Client;</li>
                  <li>
                    reasons beyond the Service Provider's control, in particular actions of third parties, for which the
                    Service Provider is not responsible.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider undertakes to carry out the works referred to in section 4 point 2 above in the
                least burdensome way possible for the Clients and, if possible, to inform them in advance about the
                planned works.
              </li>
              <li>
                The Service Provider undertakes, as far as possible, to remove disruptions in the functioning of the
                Application on an ongoing basis.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 11. Complaints regarding the Subject of the Digital Service">
            <ArticleOrderedList>
              <li>
                The provisions of this § 11 shall apply only:
                <ArticleOrderedSublist>
                  <li>Service Recipients who are Consumers or Entrepreneurs with Consumer rights</li>
                  <li>
                    Agreements for the provision of the Account Service, Agreements for the provision of the Service for
                    the use of the Application, unless a specific provision of the Regulations applies only to one of
                    these Agreements;
                  </li>
                  <li>
                    Non-compliance of the Account Service with the Agreement for the provision of the Account Service,
                    Non-compliance of the Application Use Service with the Agreement for the provision of the
                    Application Use Service.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Subject of the Digital Service Provided to the Client by the Service Provider must comply with the
                Agreement for its delivery:
                <ArticleOrderedSublist>
                  <li>
                    at the time of its delivery - if the Subject of the digital service is delivered once or in parts;
                  </li>
                  <li>
                    throughout the entire period of delivery of a given Digital Service Subject - if the Digital Subject
                    is delivered continuously.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider shall be liable for Non-compliance:
                <ArticleOrderedSublist>
                  <li>
                    existing at the time of delivery of the Digital Service Subject and disclosed within 2 (two) years
                    from that moment - if the Digital Benefit Subject is delivered once or in parts;
                  </li>
                  <li>
                    disclosed during the period of delivery of a given Digital Service Subject - if the Subject of the
                    digital service is delivered continuously.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                In the event of disclosure of Non-conformities, the Client may file a complaint containing a request to
                bring the Subject of the digital service into compliance with the Agreement regarding its delivery.
              </li>
              <li>
                The complaint is submitted by e-mail to the address indicated in § 1 section 4 point 1 of the
                Regulations.
              </li>
              <li>
                The complaint should include:
                <ArticleOrderedSublist>
                  <li>name and surname of the Client;</li>
                  <li>e-mail address;</li>
                  <li>a description of the Incompatibility revealed;</li>
                  <li>
                    a request to bring the Subject of the digital service into compliance with the Agreement regarding
                    its delivery.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider may refuse to bring the Subject of the digital service into compliance with the
                Agreement regarding its delivery, if this is impossible or would require the Service Provider to incur
                excessive costs.
              </li>
              <li>
                After considering the complaint, the Service Provider shall provide the Client with a response to the
                complaint, in which:
                <ArticleOrderedSublist>
                  <li>
                    acknowledges the complaint and indicates the planned date of bringing the Subject of the digital
                    service into compliance with the Agreement regarding its delivery;
                  </li>
                  <li>
                    refuses to bring the Subject of the digital service into compliance with the Agreement regarding its
                    delivery for the reasons indicated in paragraph 7 above;
                  </li>
                  <li>rejects the complaint because it is unfounded.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider shall respond to the complaint by e-mail within 14 (fourteen) days from the date of
                its receipt.
              </li>
              <li>
                If the complaint is accepted, the Service Provider shall, at its own expense, bring the Subject of the
                digital service into compliance with the Agreement regarding its delivery within a reasonable time from
                the receipt of the complaint and without undue inconvenience to the Client, considering the nature of
                the Digital Service Subject and the purpose for which it is used. The planned date of bringing the
                Subject of the digital service into compliance with the Agreement regarding its delivery is indicated by
                the Service Provider in response to the complaint.
              </li>
              <li>
                In the event of disclosure of Nonconformities, subject to section 14 below, the Client may submit to the
                Service Provider a statement on price reduction or withdrawal from the Agreement when:
                <ArticleOrderedSublist>
                  <li>
                    bringing the Subject of the Digital Service into conformity with the Agreement for its delivery is
                    impossible or requires excessive costs;
                  </li>
                  <li>
                    The Service Provider has not brought the Subject of the digital service into compliance with the
                    Agreement regarding its delivery in accordance with paragraph 10 above;
                  </li>
                  <li>
                    The non-compliance persists despite the Service Provider's attempt to bring the Subject of the
                    digital performance into conformity with the Agreement for its delivery;
                  </li>
                  <li>
                    The non-compliance is so significant that it justifies withdrawal from the Agreement for the
                    delivery of a given Digital Benefit Subject without first requesting the Service Provider to bring
                    the Digital Object into compliance with the Agreement regarding its delivery;
                  </li>
                  <li>
                    it is clear from the Service Provider's statement or circumstances that the Service Provider will
                    not bring the Subject of the digital service into compliance with the Agreement regarding its
                    delivery within a reasonable time or without undue inconvenience to the Service Recipient..
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                A statement on price reduction or withdrawal from the Agreement may be submitted by e-mail to the
                address indicated in § 1 section 4 point 1 of the Regulations.
              </li>
              <li>
                The statement on price reduction or withdrawal from the Agreement should contain:
                <ArticleOrderedSublist>
                  <li>name and surname of the Client;</li>
                  <li>e-mail address;</li>
                  <li>the date of delivery of the Digital Benefit Subject;</li>
                  <li>a description of the Incompatibility revealed;</li>
                  <li>
                    indication of the reason for making the declaration, chosen from among the reasons indicated in
                    paragraph 11 above;
                  </li>
                  <li>
                    statement on price reduction, together with an indication of the reduced price or a statement of
                    withdrawal from the Agreement.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Recipient may not submit to the Service Provider a statement on price reduction if the
                Incompatibility concerns the Account Service provided free of charge.
              </li>
              <li>
                The Service Provider is not entitled to demand payment for the time during which the Service of Use of
                the Application is inconsistent with the Agreement for its provision, even if the Client used it before
                withdrawing from the contract concerning it.
              </li>
              <li>
                The reduced price must remain in such proportion to the price resulting from the Agreement for the
                provision of the Application Use Service in which the value of the Application Use Service inconsistent
                with the agreement for its provision remains to the value of the Use Service in accordance with the
                Agreement for the provision of the Application Use Service (in accordance with the Package purchased by
                the Client).
              </li>
              <li>
                The Service Provider shall reimburse the Client the amounts due as a result of exercising the right to
                reduce the price or withdraw from the Agreement for the provision of the Application Use Service
                immediately, no later than within 14 (fourteen) days from the date of receipt of the statement on price
                reduction or withdrawal from the Agreement for the provision of the Application Use Service.
              </li>
              <li>
                In the event of withdrawal by the Client from the Agreement for the provision of the Account Service,
                the Service Provider deletes the Account immediately after receiving a statement of withdrawal from the
                Agreement for the provision of the Account Service.
              </li>
              <li>
                If, before withdrawing from the Agreement for the provision of the Account Service, the Service
                Recipient concluded an Agreement for the provision of the Service for the use of the Application,
                withdrawal from the Agreement for the provision of the Account Service is tantamount to simultaneous
                withdrawal from the Agreement for the provision of the Service for the use of the Application on the
                basis of the provisions of this § 11. Withdrawal from the Agreement for the provision of the Application
                Use Service in the mode indicated in this paragraph 19 has the same effects as withdrawal from the
                contract due to the occurrence of Incompatibilities.
              </li>
              <li>
                Pursuant to Article 34(1a) of the Act on consumer rights, if the Client withdraws from the Agreement
                regarding the delivery of the Subject of the Digital Service, the Client is obliged to cease using this
                Digital Benefit Object and make it available to third parties.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 12. Intellectual property of the Service Provider">
            <ArticleOrderedList>
              <li>
                All components of the Application, in particular:
                <ArticleOrderedSublist>
                  <li>the name of the Application;</li>
                  <li>the logo of the Application;</li>
                  <li>photos and descriptions;</li>
                  <li>
                    principles of operation of the Application, all its graphic elements, interface, software, source
                    code and databases
                    <br />- are subject to legal protection under the provisions of the Act of 4 February 1994 on
                    Copyright and Related Rights, the Act of 30 June 2000 - Industrial Property Law, the Act of 16 April
                    1993 on combating unfair competition and other provisions of generally applicable law, including the
                    provisions of European Union law.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Any use of the Provider's intellectual property without its prior, express permission is prohibited.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 13. Processing of personal data">
            <ArticleOrderedList>
              <li>
                Information on the processing of personal data by the Service Provider can be found in the Privacy
                Policy available at{' '}
                <TextLink to={ROUTES.privacyPolicy}>https://terraeye.co{ROUTES.privacyPolicy}</TextLink>.
              </li>
              <li>
                The terms and conditions for entrusting the processing of personal data by the Client to the Service
                Provider are specified in the Personal Data Processing Agreement, constituting Annex No. 1 to the
                Regulations.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 14. Out-of-court dispute resolution">
            <ArticleOrderedList>
              <li>The provisions of this § 16 apply only to Service Recipients who are Consumers.</li>
              <li>The Service Recipient has the option of using out-of-court complaint and redress methods.</li>
              <li>
                Detailed information on the possibility of using out-of-court complaint and redress methods by the
                Client and the rules of access to these procedures are available at the offices and on the websites:
                <ArticleOrderedSublist>
                  <li>
                    poviat (municipal) consumer ombudsmen, social organizations whose statutory tasks include consumer
                    protection;
                  </li>
                  <li>Provincial Inspectorates of Trade Inspection;</li>
                  <li>Office of Competition and Consumer Protection.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Recipient may also use the platform for online dispute resolution (ODR) available at:
                http://ec.europa.eu/consumers/odr/.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 15. Changing the Digital Service">
            <ArticleOrderedList>
              <li>
                Under this § 15:
                <ArticleOrderedSublist>
                  <li>
                    The Account Service and the Application Use Service are hereinafter jointly referred to as the
                    "Service", unless a specific provision of the Regulations applies only to one of these services;
                  </li>
                  <li>
                    The Agreement for the provision of the Account Service and the Agreement for the provision of the
                    Application Use Service are hereinafter jointly referred to as the "Service Provision Agreement",
                    unless a specific provision of the Regulations applies to only one of these agreements.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Service Provider may change the Service in the case of:
                <ArticleOrderedSublist>
                  <li>
                    the need to adapt the Service to newly created devices or software used by the Clients to use the
                    Service;
                  </li>
                  <li>
                    the Service Provider decides to improve the Service by adding new functionalities or modifying
                    existing functionalities;
                  </li>
                  <li>
                    legal obligation to make changes, including the obligation to adapt the Service to the current legal
                    status.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Changing the Service may not involve any costs on the part of the Client who is a Consumer or an
                Entrepreneur with Consumer rights.
              </li>
              <li>
                The Service Provider informs the Service Recipients about the change to the Service by placing a message
                on the Account informing about the changes. Independently, information about the change may be sent to
                the Clients via e-mail.
              </li>
              <li>
                If the change of the Service will significantly and negatively affect the access to the Service of the
                Client who is a Consumer or an Entrepreneur with consumer rights, the Service Provider is obliged to
                inform the Client about:
                <ArticleOrderedSublist>
                  <li>the nature and timing of the change, and</li>
                  <li>
                    the Client's right to terminate the Agreement for the provision of the Service with immediate effect
                    within 30 (thirty) days of making the change.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The information referred to in paragraph 5 above shall be sent by the Service Provider to the Clients
                via e-mail, no later than 7 (seven) days before the change.
              </li>
              <li>
                Termination by the Client of the Agreement for the provision of the Service pursuant to paragraph 5
                point 2 above takes place by submitting to the Service Provider a statement on termination of the
                Agreement for the provision of the Service. The statement referred to in the preceding sentence may be
                sent by e-mail to the address indicated in § 1 section 4 point 1 of the Regulations.
              </li>
              <li>
                Termination of the Agreement for the provision of Services pursuant to section 5 point 2 above has the
                same effects as § 13 of the Regulations provides in the event of withdrawal from the Agreement for the
                provision of Services due to the occurrence of Non-conformity.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 16. Price list">
            <ArticleOrderedList>
              <li>The current Price List is available at Client's Account.</li>
              <li>The Service Provider may change the Price List at any time.</li>
              <li>
                The change of the Price List in no way affects the amount of fees specified in the Agreements concluded
                before the change of the Price List.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 17. Amendments to the Terms and Conditions">
            <ArticleOrderedList>
              <li>
                The Service Provider may make changes to the Regulations in the case of:
                <ArticleOrderedSublist>
                  <li>changes in the subject of the Service Provider's activity;</li>
                  <li>
                    commencement of the provision of new services by the Service Provider, modification of services
                    previously provided or discontinuation of their provision;
                  </li>
                  <li>
                    make a technical modification of the Application requiring adaptation of the provisions of the
                    Regulations to them;
                  </li>
                  <li>
                    the legal obligation to make changes, including the obligation to adapt the Regulations to the
                    current legal status.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Users will be informed about the amendment to the Regulations by publishing its amended version in the
                Application. Independently, the amended version of the Regulations will be sent to the Clients by
                e-mail.
              </li>
              <li>
                The provisions of the then applicable Regulations shall apply to the Agreements for the provision of the
                Application Use Service concluded before the amendment of the Regulations.
              </li>
              <li>
                The Service Recipient who does not agree to the amendment of the Regulations may terminate the Agreement
                for the provision of the Account Service with immediate effect within 10 (ten) from the date of
                receiving information about the change in the Regulations. Lack of termination shall be deemed consent
                to change the Regulations.
              </li>
              <li>
                Termination of the Agreement for the provision of the Account Service takes place by submitting to the
                Service Provider by the Service Recipient a statement on termination of this Agreement. The statement
                referred to in the preceding sentence may be sent by e-mail to the address indicated in § 1 section 4
                point 1 of the Regulations.
              </li>
              <li>
                Immediately after receiving the statement referred to in paragraph 5 above, the Service Provider deletes
                the Account.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 18. Final provisions">
            <ArticleOrderedList>
              <li>The current version of the Regulations is effective from 17.02.2024 r.</li>
              <li>
                The Regulations are governed by Polish law. Any disputes under these Regulations shall be resolved by
                amicable negotiations, and in the event of no agreement - before a common court competent for the seat
                of the Service Provider.
              </li>
              <li>
                In matters not covered by the Regulations, the provisions of generally applicable Polish law shall
                apply.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper
            title={
              <span>
                Appendix No. 1 to the Terms and Conditions -<br />
                Entrustment Agreement
              </span>
            }
          >
            PERSONAL DATA PROCESSING AGREEMENT
            <br />
            (hereinafter: "Entrustment Agreement")
            <br />
            <br />
            concluded by and between:
            <br />
            <br />
            the Service Provider (hereinafter also: the "Processing Entity"),
            <br />
            <br />
            and
            <br />
            <br />
            the Service Recipient (hereinafter also: the "Controller"),
            <br />
            <br />
            hereinafter collectively referred to as the "Parties" and each of them individually as a "Party".
            <br />
            <br />
            Recitals
            <br />
            Whereas:
            <ArticleOrderedList>
              <li>
                the Service Provider and the Service Recipient have entered into the Service Agreement (hereinafter: the
                "Master Agreement");
              </li>
              <li>
                the provision of the Services requires the Service Provider to process the Participants' personal data
                within the meaning of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April
                2016 on the protection of natural persons in relation to the processing of personal data and on the free
                movement of such data and the repeal of Directive 95/46/EC (General Data Protection Regulation)
                (hereinafter: the "GDPR"), which gives rise to the obligation to comply with the requirements indicated
                in Article 28 of the GDPR, including the conclusion of the Agreement set out in this provision;
              </li>
            </ArticleOrderedList>
            <br />
            The Parties have agreed as follows:
            <br />
            <br />
            <Container variant="VERTICAL" align="flex-start" gap={20}>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 1. Entrustment of the processing of personal data
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    The Controller entrusts the Processing Entity with the processing of personal data pursuant to
                    Article 28 of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April
                    2016 on the protection of natural persons regarding the processing of personal data and on the free
                    movement of such data and repealing Directive 95/46/EC (hereinafter: "General Data Protection
                    Regulation" or "GDPR").
                  </li>
                  <li>
                    The Controller represents that it is the controller of the data entrusted to the Processing Entity
                    under the Entrustment Agreement or a processing entity authorized to further entrust the data to the
                    Processing Entity.
                  </li>
                  <li>
                    The Controller hereby entrusts the Processing Entity with the processing of personal data within the
                    scope specified in § 2 hereof.
                  </li>
                  <li>
                    Capitalized terms used herein shall have the meaning given to them in the Terms and Conditions or
                    GDPR, unless a specific provision hereof provides otherwise.
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 2. Subject matter, nature, purpose, and duration of data processing
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    Personal Data entrusted by the Controller shall be processed by the Processing Entity only upon the
                    Controller's documented instruction and solely for the purpose of providing the Services. In
                    particular, the Parties deem the conclusion of the Service Agreement to be a "documented
                    instruction".
                  </li>
                  <li>
                    The categories of personal data which are the subject of the entrustment (hereinafter: "entrusted
                    personal data") and the categories of entrusted data subjects are indicated in Appendix No. 1
                    hereto.
                  </li>
                  <li>
                    The personal data entrusted by the Controller hereunder shall not constitute special categories of
                    data referred to in Article 9 of the GDPR or data relating to criminal convictions and offences
                    referred to in Article 10 of the GDPR.
                  </li>
                  <li>
                    The processing of the entrusted personal data will be carried out using IT systems (in an automated
                    manner) and in paper form (in a non-automated manner).
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 3. Obligations, rights, and representations of the Processing Entity
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    The Processing Entity undertakes to secure the entrusted personal data by implementing (even prior
                    to the processing) and maintaining technical and organisational measures appropriate to the nature,
                    scope, context and purpose of the processing of the entrusted data, including those required by the
                    relevant provisions of generally applicable law, so that the processing of the entrusted personal
                    data meets the requirements of the General Data Protection Regulation.
                  </li>
                  <li>
                    The Processing Entity undertakes to ensure that the persons authorised to process the personal data
                    entrusted hereunder are bound by confidentiality obligations or are subject to an appropriate
                    statutory secrecy obligation.
                  </li>
                  <li>
                    The Processing Entity undertakes, to the extent justified by the subject matter of the Entrustment
                    Agreement, to assist the Controller, to the extent possible, in complying with the Controller's
                    obligation to respond to requests from data subjects in exercising their rights under generally
                    applicable law, including Chapter III of the General Data Protection Regulation.
                  </li>
                  <li>
                    The Processing Entity undertakes to immediately notify the Controller of:
                    <ArticleOrderedSublist>
                      <li>
                        any breach of the protection of the entrusted personal data, where "breach of the protection of
                        the entrusted data" shall mean any accidental or unlawful destruction, loss, modification,
                        unauthorised disclosure of or unauthorised access to the entrusted personal data. The
                        notification referred to in this Subsection 1 shall be made no later than within 24 hours from
                        the discovery of the breach of protection of the entrusted data;
                      </li>
                      <li>
                        any request received from the data subject, while refraining from responding to the request
                        until the Controller's opinion has been received. The notification referred to in this
                        Subsection 2 shall be made no later than 24 hours after receipt of the request;
                      </li>
                      <li>
                        any legally authorised request to disclose personal data to a competent state authority, unless
                        the prohibition to notify ensues from the provisions of the law, in particular from the
                        provisions of criminal proceedings, when the prohibition is aimed at ensuring the
                        confidentiality of an initiated investigation;
                      </li>
                      <li>
                        any compliance checks on the processing of personal data carried out by the President of the
                        Personal Data Protection Office or any other supervisory authority, and the results thereof, as
                        well as any other action taken by public authorities concerning such data.
                      </li>
                    </ArticleOrderedSublist>
                  </li>
                  <li>
                    The Processing Entity undertakes, to the extent justified by the subject matter hereof and the
                    information available to it, to assist the Controller in complying with the Controller's obligations
                    under generally applicable law, including Articles 32 to 36 of the General Data Protection
                    Regulation and concerning the security of the processing of personal data, notification of a
                    personal data breach to the supervisory authority and to the data subject, a data protection impact
                    assessment and related consultations with the supervisory authority.
                  </li>
                  <li>
                    The Processing Entity undertakes to:
                    <ArticleOrderedSublist>
                      <li>
                        provide the Controller, within 14 days from the date of receipt of the request, with all
                        information and documents necessary to demonstrate the Controller's compliance with its
                        obligations under generally applicable law;
                      </li>
                      <li>
                        enable the Controller or its authorised auditor to carry out audits, including inspections, and
                        contribute to such audits, on terms to be determined by the Parties from time to time and
                        subject to the provisions of this Section.
                      </li>
                    </ArticleOrderedSublist>
                  </li>
                  <li>
                    The audit referred to in Section 7(2) above may be carried out:
                    <ArticleOrderedSublist>
                      <li>
                        not earlier than 14 days from the date of receipt by the Processing Entity of a notice regarding
                        the intention to conduct it, on a date to be determined by the Parties, and
                      </li>
                      <li>
                        after the conclusion of a confidentiality agreement between the Processing Entity and the
                        Controller or an auditor authorised by the Controller.
                      </li>
                    </ArticleOrderedSublist>
                  </li>
                  <li>
                    Upon completion of the audit, the Parties shall draw up a report in 2 copies to be signed by
                    authorised representatives of both Parties. The Processing Entity may raise objections to the report
                    within 5 Business Days from the date of signing thereof by the representatives of the Parties.
                  </li>
                  <li>
                    In the event that any shortcomings affecting the security of processing of the entrusted personal
                    data are identified in the course of the audit, the Processing Entity undertakes to comply with the
                    recommendations formulated by the Controller or the auditor authorised by the Controller.
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 4. Controller's Obligations
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    The Controller is obliged to ensure that throughout the duration of the Entrustment Agreement the
                    Controller has a legal basis for the processing of the entrusted personal data and that the
                    Controller has appropriate entitlements to entrust the personal data to the Processing Entity.
                    Should the Controller lose the aforementioned legal basis or entitlements with regard to certain
                    entrusted personal data, the Controller shall immediately take steps necessary to cease entrusting
                    them, in particular notify the Processing Entity thereof.
                  </li>
                  <li>
                    The Controller undertakes not to give instructions to the Processing Entity regarding the processing
                    of the entrusted personal data which would be in conflict with generally applicable law, the
                    provisions of the Entrustment Agreement or other contractual obligations.
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 5. Further Entrustment of Personal Data
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    The Controller grants its general consent for the Processing Entity to further entrust the
                    processing of personal data (hereinafter: "subcontracting") to subcontractors of its choice.
                  </li>
                  <li>
                    The Processing Entity undertakes to ensure that:
                    <ArticleOrderedSublist>
                      <li>
                        the sub-processor applies appropriate technical and organisational measures to ensure the
                        processing of the subcontracted personal data in accordance with the GDPR;
                      </li>
                      <li>
                        the scope of the sub-processor's data protection obligations correspond to the Processing
                        Entity's obligations hereunder.
                      </li>
                    </ArticleOrderedSublist>
                  </li>
                  <li>
                    If the Processing Entity intends to subcontract the processing of personal data to a particular
                    subcontractor, the Processing Entity shall notify the Controller thereof by e-mail no later than 7
                    (seven) days prior to the subcontracting. The Controller may object to the subcontracting referred
                    to in the preceding sentence by raising an objection by e-mail within 7 (seven) days of receipt of
                    the subcontracting notification.
                  </li>
                  <li>
                    Upon the ineffective expiry of the objection period referred to in Section 3 above, the Processing
                    Entity may subcontract the processing of personal data to the selected subcontractor.
                  </li>
                  <li>
                    If the objection referred to in Section 3 above is raised, the Processing Entity may rescind the
                    Master Agreement with immediate effect.
                  </li>
                  <li>
                    The subcontracting referred to in Section 3 above shall not constitute an amendment to the
                    Entrustment Agreement.
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 6. Term of the Entrustment Agreement
                </ArticleTitle>
                The Entrustment Agreement is concluded for the duration of the Master Agreement and terminates upon
                termination, cancellation, or expiration of the Master Agreement.
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 7. Effects of Termination of the Entrustment Agreement
                </ArticleTitle>
                In the event of termination of the Entrustment Agreement, the Processing Entity, without delay, no later
                than within 14 (fourteen) Business Days from the date of termination of the Entrustment Agreement,
                undertakes to return to the Controller and remove from its own carriers all personal data the processing
                of which it has been entrusted with, including effectively removing it also from the electronic carriers
                at its disposal. The provisions of the preceding sentence shall not apply to the personal data the
                storage of which by the Processing Entity is required for a period longer than the duration of the
                Entrustment Agreement in accordance with generally applicable laws.
              </div>
              <div>
                <ArticleTitle as="h3" isSmall color="white">
                  § 8. Final Provisions
                </ArticleTitle>
                <ArticleOrderedList>
                  <li>
                    Annex No. 1 - Categories of personal data entrusted and categories of personal data subjects
                    constitutes an integral part of the Agreement.
                  </li>
                  <li>
                    The provisions of § 18 of the Terms and Conditions shall apply respectively to the amendments to the
                    Entrustment Agreement.
                  </li>
                  <li>
                    To all matters not regulated herein, the provisions of the Terms and Conditions, provisions of the
                    GDPR and relevant provisions of the Polish law shall apply.
                  </li>
                </ArticleOrderedList>
              </div>
              <div>
                Annex No. 2 to the Entrustment Agreement -
                <br />
                Categories of personal data entrusted and categories of personal data subjects
              </div>
              <TableWrapper gridCols="10fr 45fr 45fr" minWidth={500}>
                <table>
                  <thead>
                    <tr>
                      <th scope="row">No.</th>
                      <th scope="row">CATEGORIES OF DATA SUBJECTS</th>
                      <th scope="row">CATEGORIES OF PERSONAL DATA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="col">1.</th>
                      <td>Application Users</td>
                      <td>Name(s), surname, contact details, education, job title</td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </Container>
          </ArticleWrapper>
        </ArticlesList>
      </LegalPageLayout>
    </PageHelmetWrapper>
  );
};

export default TermsAndConditions;
