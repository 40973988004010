import { hyper4ExplorTableData } from './hyper4ExplorTableData';
import { StyledHype4ExplorTable, TableBody, TableFoot, TableHead } from './styled';
import { formatExpenses, getTotalExpenses } from './utils';

const Hype4ExplorTable = () => {
  const totalEligibleExpenses = getTotalExpenses('eligibleExpenses');
  const totalGrant = getTotalExpenses('grant');

  return (
    <StyledHype4ExplorTable>
      <TableHead>
        <tr>
          <th></th>
          <th scope="col">Eligible expenses (PLN)</th>
          <th scope="col">Grant (PLN)</th>
        </tr>
      </TableHead>
      <TableBody>
        {hyper4ExplorTableData.map(({ id, header, eligibleExpenses, grant }) => (
          <tr key={id}>
            <th scope="row">
              <span>{header}</span>
            </th>
            <td>{formatExpenses(eligibleExpenses)}</td>
            <td>{formatExpenses(grant)}</td>
          </tr>
        ))}
      </TableBody>
      <TableFoot>
        <tr>
          <th scope="row">Total</th>
          <td>{formatExpenses(totalEligibleExpenses)}</td>
          <td>{formatExpenses(totalGrant)}</td>
        </tr>
      </TableFoot>
    </StyledHype4ExplorTable>
  );
};

export default Hype4ExplorTable;
