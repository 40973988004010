import { useEffect, useRef } from 'react';

import { StyledVideo, VideoSideIcon, VideoTitle, VideoWrapper } from './styled';
import { ObserverOptions } from 'types';
import { getVideoFormat } from 'utils/getVideoFormat';

type VideoProps = {
  src: string;
  type: 'animation' | 'loop';
  titleSrc: string | null;
  sideIconSrc: string | null;
  observerOptions: ObserverOptions;
};

const Video = ({ src, type, titleSrc, sideIconSrc, observerOptions }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playbackRate = type === 'animation' ? 3 : 1;

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && videoElement) {
        videoElement.play();
        videoElement.playbackRate = playbackRate;
        observer.unobserve(videoElement);
      }
    }, observerOptions);

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoWrapper>
      <StyledVideo ref={videoRef} playsInline muted loop={type === 'loop'}>
        <source src={src} type={`video/${getVideoFormat()}`} />
      </StyledVideo>
      {titleSrc && <VideoTitle src={require(`assets/${titleSrc}`)} alt="" />}
      {sideIconSrc && <VideoSideIcon src={require(`assets/${sideIconSrc}`)} alt="" />}
    </VideoWrapper>
  );
};

export default Video;
