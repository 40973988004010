import axios from 'axios';

export const geocodeSearchClient = async (query: string) => {
  const apiKey = process.env.REACT_APP_TOMTOM_API_KEY;

  const geocodeBaseUrl = `https://api.tomtom.com/search/2/geocode/${query}.json?storeResult=false&view=Unified&key=${apiKey}`;
  const response = await axios.get(geocodeBaseUrl);

  return response.data.results;
};
