import { BENEFITS_SECTION_ID } from 'common/constants';
import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { CaseStudiesList } from 'pages/HomePage/components/CaseStudies/styled';
import Benefit from './Benefit';

interface BenefitsProps {
  title: string;
  description: string;
  data: {
    id: number,
    title: string,
    description: string,
    imageName: string
  }[],
}

const Benefits = ({ title, description, data }: BenefitsProps) => {
  return (
    <Section id={BENEFITS_SECTION_ID} light large>
      <SectionHeader
        variant="SECONDARY_H2"
        title={title}
        description={description}
        isBackgroundLight
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <CaseStudiesList digitalTwin>
            {data.map(({ id, title, description, imageName }) => (
              <li key={id}>
                <Benefit title={title} description={description} imageName={imageName} />
              </li>
            ))}
          </CaseStudiesList>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

export default Benefits;
