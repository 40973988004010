import { useLocation } from 'react-router-dom';
import { t } from 'i18next';

import { TERRAEYE_APP_BASE_URL, TERRAEYE_APP_SIGN_UP_URL } from 'common/constants';
import { ROUTES } from 'common/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { theme } from 'theme';
import LanguageSelector from 'components/LanguageSelector';
import { ListWrapper, NavigationListItem, SignUpLink, StyledHashLink, StyledNavigationList } from './styled';
import { navigationListData } from './navigationListData';

type NavigationListProps = {
  isMenuOpen?: boolean;
  closeMenu?: () => void;
  isLightSectionsInView: boolean;
  digitalTwin: boolean;
};

const NavigationList = ({ isMenuOpen, closeMenu, isLightSectionsInView, digitalTwin }: NavigationListProps) => {
  const { pathname } = useLocation();
  const matchesTablet = useMediaQuery(`(max-width: ${theme.breakpoints.tablet}px)`);

  const data = digitalTwin
    ? (t('navigation.data', { returnObjects: true }) as typeof navigationListData)
    : navigationListData;
  const baseUrl = digitalTwin ? ROUTES.digitalTwin : '/';

  return (
    <StyledNavigationList open={isMenuOpen} isLightSectionsInView={isLightSectionsInView}>
      <ListWrapper>
        {data.map(({ id, title, to }) => {
          return (
            <NavigationListItem key={id} onClick={closeMenu}>
              <StyledHashLink
                to={`${baseUrl}#${to}`}
                smooth={digitalTwin ? pathname === ROUTES.digitalTwin : pathname === '/'}
                $isLightSectionsInView={isLightSectionsInView}
              >
                {title}
              </StyledHashLink>
            </NavigationListItem>
          );
        })}

        {digitalTwin ? (
          <>
            <NavigationListItem>
              <StyledHashLink to={ROUTES.digitalTwinContactUs} onClick={closeMenu} $isLightSectionsInView={isLightSectionsInView}>
                {t('navigation.contactLabel')}
              </StyledHashLink>
            </NavigationListItem>
            <LanguageSelector isLightSectionsInView={isLightSectionsInView} />
          </>
        ) : (
          <>
            <NavigationListItem>
              <StyledHashLink to={ROUTES.data} onClick={closeMenu} $isLightSectionsInView={isLightSectionsInView}>
                data
              </StyledHashLink>
            </NavigationListItem>

            <NavigationListItem>
              <StyledHashLink to={ROUTES.reports} onClick={closeMenu} $isLightSectionsInView={isLightSectionsInView}>
                reports
              </StyledHashLink>
            </NavigationListItem>

            <NavigationListItem>
              <StyledHashLink to={ROUTES.digitalTwin} onClick={closeMenu} target="_blank" rel="noopener noreferrer" $altColor  $isLightSectionsInView={isLightSectionsInView}>
                digital twin
              </StyledHashLink>
            </NavigationListItem>

            {!matchesTablet && (
              <NavigationListItem>
                <StyledHashLink as="a" href={TERRAEYE_APP_BASE_URL} target="_blank" rel="noopener noreferrer"  $isLightSectionsInView={isLightSectionsInView}>
                  login
                </StyledHashLink>
              </NavigationListItem>
            )}

            <NavigationListItem onClick={closeMenu}>
              <SignUpLink
                as="a"
                href={TERRAEYE_APP_SIGN_UP_URL}
                target="_blank"
                rel="noopener noreferrer"
                variant="SECONDARY"
              >
                sign up
              </SignUpLink>
            </NavigationListItem>
          </>
        )}
      </ListWrapper>
    </StyledNavigationList>
  );
};

export default NavigationList;
