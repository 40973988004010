import { GeocodeSearchResult } from './types';

export const buildResultString = (result: GeocodeSearchResult) => {
  const segments = [
    result.address.freeformAddress,
    result.address.countrySecondarySubdivision,
    result.address.countrySubdivision,
    result.address.country,
  ];

  return segments.filter(Boolean).join(', ');
};

export const extractTextFromQuery = (str: string) => str.replaceAll(/[^\wżółćęśąźń ]+/g, '').replaceAll('_', '');
