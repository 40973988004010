import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';

import terraEyeImage from 'assets/images/terraEye/terraeye-info.jpg';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import SubPageLayout from 'components/SubPage';
import { theme } from 'theme';
import { LinkButton } from 'ui/atoms/button/Button';
import { TerraEyeAppInfoContent, TerraEyeAppInfoDescription, TerraEyeAppInfoPageImage } from './styled';

const TerraEyeAppInfoPage = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const handleChange = () => {
      if (window.innerWidth > theme.breakpoints.tablet) {
        navigate('/');
      }
    };

    handleChange();
    window.addEventListener('resize', handleChange);

    return () => window.removeEventListener('resize', handleChange);
  }, [navigate]);

  return (
    <SubPageLayout image={<TerraEyeAppInfoPageImage desktopUrl={terraEyeImage} tabletUrl={terraEyeImage} />}>
      <section>
        <TerraEyeAppInfoContent>
          <SectionHeader title="TerraEye App" />

          <PaddingWrapper size="large" type="right">
            <Container size="small" align="start">
              <PaddingWrapper size="large" type="left">
                <TerraEyeAppInfoDescription size="LG" fontFamily="Barlow Condensed">
                  Currently, our app is only recommended for the desktop resolution.
                </TerraEyeAppInfoDescription>
                <LinkButton to="/" variant="SECONDARY" height={52}>
                  back to homepage
                </LinkButton>
              </PaddingWrapper>
            </Container>
          </PaddingWrapper>
        </TerraEyeAppInfoContent>
      </section>
    </SubPageLayout>
  );
};

export default TerraEyeAppInfoPage;
