import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';

export const DataProcessingArticleWrapper = styled.div`
  display: block;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 100px 126px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 56px 40px;
  }
`;

export const StyledDataProcessingArticle = styled.article`
  color: ${({ theme }) => theme.colors.tuatara};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    max-width: 1120px;
  }
`;

export const DataProcessingArticleHeading = styled.h3`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 44px;
  font-weight: 600;
  margin: 0 0 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
    margin: 0 0 35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }
`;

export const DataProcessingArticleDescription = styled(Paragraph)`
  strong {
    font-weight: 600;
  }
`;
