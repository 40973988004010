import { ROUTES } from 'common/routes';
import { ArticlesList } from 'components/Article/styled';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import LegalPageLayout from 'components/LegalContentWrapper';
import {
  ArticleContent,
  ArticleOrderedList,
  ArticleOrderedSublist,
  ArticleUnorderedSublist,
  TableWrapper,
} from 'components/Article/styled';
import { Container } from 'ui/atoms/container';
import { TextLink } from 'ui/atoms/textLink';
import PageHelmetWrapper from 'components/PageHelmetWrapper';

const PrivacyPolicyTerraeyeApp = () => {
  return (
    <PageHelmetWrapper page="privacyPolicyTerraeyeApp">
      <LegalPageLayout
        title={
          <span>
            Privacy policy of the <br /> TerraEye App
          </span>
        }
      >
        <ArticlesList isNarrow>
          <ArticleContent>
            This Privacy Policy (hereinafter: "Policy") contains information on the processing of your personal data in
            connection with the use of the “TerraEye” app, operating at the internet address terraeye.co (hereinafter:
            "App").
            <br />
            <br />
            All capitalized terms that are not otherwise defined in the Policy shall have the meaning given to them in
            the Terms and Conditions, available at:{' '}
            <TextLink to={ROUTES.termsAndConditions}>https://terraeye.co{ROUTES.termsAndConditions}</TextLink>
          </ArticleContent>

          <ArticleWrapper title="Personal data Controller">
            The Controller of your personal data is Remote Sensing Business Solutions Sp. Z O. O., a simple joint stock
            company incorporated and registered in Poland whose registered office is located at Jana Dlugosza str. 60A,
            51-162 Wroclaw, Poland, registered in the Polish Court Registry under no. 0000970039, NIP: 8952241235, (the
            “Customer”), with a share capital of 723 403 zł paid in full (hereinafter: "Controller").
          </ArticleWrapper>

          <ArticleWrapper title="Contact with the Controller">
            In all matters related to the processing of personal data, you can contact the Controller using:
            <ArticleOrderedList>
              <li>e-mail - at: dgpr@fourpoint.space;</li>
              <li>traditional mail - at: Jana Długosza 60a, 51-162 Wrocław, Poland.</li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="Data Protection Officer">
            With the Data Protection Officer appointed by the Controller You can contact us using:
            <ArticleOrderedList>
              <li>e-mail - at: iod@fourpoint.space;</li>
              <li>traditional mail - at: Jana Długosza 60a, 51-162 Wrocław, Poland</li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="Measures to protect personal data">
            The Controller uses modern organizational and technical safeguards to ensure the best possible protection of
            your personal data and guarantees that it processes them in accordance with the provisions of Regulation
            (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of
            individuals with regard to the processing of personal data and on the free movement of such data, and
            repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter: "GDPR"), the Act of 10 May
            2018 on the protection of personal data and other provisions on the protection of personal data.
          </ArticleWrapper>

          <ArticleWrapper title="Information about the processed personal data">
            Using the App requires the processing of your personal data. Below you will find detailed information about
            the purposes and legal bases of processing, as well as the period of processing and the obligation or
            voluntariness to provide them.
          </ArticleWrapper>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Conclusion and performance of the Agreement for the provision of the Account Service</td>
                  <td>
                    <ArticleOrderedList>
                      <li>name and surname</li>
                      <li>e-mail address</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Account Service
                    Agreement concluded with the data subject or to take steps to conclude it)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is a condition for the conclusion and performance of the
            contract for the provision of the Account Service (their provision is voluntary, but the consequence of not
            providing them will be the inability to conclude and perform the above-mentioned contract, including the
            creation of an Account).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the claims arising from the Agreement
            for the provision of the Account Service expire.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Conclusion and performance of the Agreement for the use of the App</td>
                  <td>
                    <ArticleOrderedList>
                      <li>name and surname</li>
                      <li>e-mail address</li>
                      <li>telephone number</li>
                      <li>
                        address of residence/business activity (street, house number, apartment number, city, postal
                        code, country)
                      </li>
                      <li>delivery address (if different from the address of residence/business)</li>
                      <li>
                        optionally - company and NIP (if the Buyer is an Entrepreneur or Entrepreneur with consumer
                        rights)
                      </li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Agreement for the
                    use of the App concluded with the data subject or to take steps to conclude it)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is a condition for the conclusion and performance of the
            Agreement for the use of the App (their provision is voluntary, but the consequence of not providing them
            will be the inability to conclude and perform the Agreement for the use of the App).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the claims arising from the Agreement
            for the use of the App expire.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Conclusion and performance of the Newsletter Agreement/Digital Goods Provision Agreement</td>
                  <td>
                    <ArticleOrderedList>
                      <li>e-mail address</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(b) of the GDPR (processing is necessary for the performance of the Newsletter
                    Subscription Agreement/Digital Goods Provision Agreement concluded with the data subject or to take
                    action to conclude it)
                    <br />
                    <br /> and <br />
                    <br />
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case informing about new services and promotions available in the App)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary to receive the Newsletter (the
            consequence of not providing them will be the inability to receive the Newsletter/Digital Goods).
            <br />
            <br />
            The Controller will process the above-mentioned personal data, the time of effective objection or
            achievement of the purpose of processing or until the claims arising from the Newsletter Subscription
            Agreement/Digital Goods Provision Agreement are time-barred (whichever occurs first).
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Conducting a complaint procedure</td>
                  <td>
                    <ArticleOrderedList>
                      <li>name and surname</li>
                      <li>e-mail address</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(c) of the GDPR
                    <br />
                    <br />
                    (processing is necessary to fulfill a legal obligation incumbent on the Controller, in this case the
                    obligations of:
                    <ArticleUnorderedSublist mt={0}>
                      <li>responding to a complaint - Article 7a of the Act on consumer rights;</li>
                      <li>
                        exercise the Client's rights resulting from the provisions on the Controller's liability in the
                        event of non-compliance of the the Subject of Digital Services with the Agreement concerning it)
                      </li>
                    </ArticleUnorderedSublist>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is a condition for receiving a response to the complaint or
            exercising the Client's rights under the provisions on the Controller's liability in the event of
            non-compliance of the Subject of Digital Services with the Agreement concerning it (their application is
            voluntary, but the consequence of not providing them will be the inability to receive a response to the
            complaint and the implementation of the above-mentioned rights).
            <br />
            <br />
            The Controller will process the above-mentioned personal data for the duration of the complaint procedure,
            and in the case of the implementation of the above-mentioned rights of the Client - until they expire.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sending email notifications</td>
                  <td>e-mail address</td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary in order to implement the legitimate interest
                    of the Controller, in this case informing Clients about the actions taken related to the performance
                    of agreements concluded with Clients)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data voluntary, but necessary to receive information about activities
            related to the performance of agreements concluded with Clients (the consequence of not providing them will
            be the inability to receive the above-mentioned information).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the objection is effectively raised or
            the purpose of processing is achieved (whichever occurs first).
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Handling inquiries submitted by Clients</td>
                  <td>
                    <ArticleOrderedList>
                      <li>name</li>
                      <li>e-mail address</li>
                      <li>other data contained in the message to the Controller</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case to respond to the received inquiry)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary to receive an answer to the inquiry
            (the consequence of not providing them will be the inability to receive an answer).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the objection is effectively raised or
            the purpose of processing is achieved (whichever occurs first).
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Conducting verification proceedings and dealing with appeals against decisions on the handling of
                    unacceptable content
                  </td>
                  <td>
                    <ArticleOrderedList>
                      <li>name and surname</li>
                      <li>contact details, including email address</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(c) of the GDPR (processing is necessary for compliance with a legal obligation to which
                    the Controller is subject, in this case the obligations of:
                    <ArticleUnorderedSublist mt={0}>
                      <li>
                        provide a mechanism for reporting unacceptable content (Article 16 of Regulation 2022/2065 on
                        the Single Market for Digital Services and amending Directive 2000/31/EC (Digital Services Act)
                        (hereinafter: "DSA"),
                      </li>
                      <li>consideration of complaints (Article 20 of the DSA).</li>
                    </ArticleUnorderedSublist>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is a condition for receiving a response to the application or
            exercising the User's rights under the DSA regulations (their provision is voluntary, but the consequence of
            not providing them will be the inability to receive a response to the notification and the exercise of the
            above-mentioned rights).
            <br />
            <br />
            The Administrator will process the above-mentioned personal data for the duration of the complaint
            procedure, and in the case of exercising the above-mentioned rights of the User - until they expire.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sharing Reviews on Services</td>
                  <td>
                    <ArticleOrderedList>
                      <li>name</li>
                      <li>optionally - other data contained in the Review</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case providing the Review for information and promotional purposes)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary to add a Review (the consequence of
            not providing them will be the inability to add a Review).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the objection is effectively raised or
            the purpose of processing is achieved (whichever occurs first).
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Compliance with tax obligations (m.in issuing a VAT invoice, storing accounting documentation)
                  </td>
                  <td>
                    <ArticleOrderedList>
                      <li>Name and surname/company</li>
                      <li>address of residence/registered office</li>
                      <li>tac identification number</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(c) of the GDPR (processing is necessary to fulfill the legal obligation incumbent on
                    the Controller, in this case obligations arising from tax law)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary for the Controller to meet its tax
            obligations (the consequence of not providing them will be the Controller's inability to meet the
            above-mentioned obligations).
            <br />
            <br />
            The Controller will process the above-mentioned personal data for a period of 5 years from the end of the
            year in which the tax payment deadline for the previous year expired.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Fulfillment of obligations related to the protection of personal data</td>
                  <td>
                    <ArticleOrderedList>
                      <li>Name and surname</li>
                      <li>
                        contact details provided by you (e-mail address; correspondence address; telephone number)
                      </li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(c) of the GDPR (processing is necessary to fulfill the legal obligation incumbent on
                    the Controller, in this case the obligations arising from the provisions on the protection of
                    personal data)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary for the Controller to properly
            perform the obligations arising from the provisions on the protection of personal data, m.in. exercise the
            rights granted to you by the GDPR (the consequence of not providing the above-mentioned data will be the
            inability to properly implement the above-mentioned rights).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the expiry of the limitation periods for
            claims for violation of the provisions on the protection of personal data.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Establishing, investigating, or defending against claims</td>
                  <td>
                    <ArticleOrderedList>
                      <li>Name and surname/company</li>
                      <li>e-mail address</li>
                      <li>address of residence/registered office</li>
                      <li>PESEL number</li>
                      <li>Tax identification number</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case to establish, investigate or defend against claims that may arise in
                    connection with the performance of Contracts concluded with the Controller)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary to establish, investigate or defend
            against claims that may arise in connection with the performance of the agreements concluded with the
            Controller (the consequence of not providing the above-mentioned data will be the inability of the
            Controller to take the above-mentioned actions)
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the expiry of the limitation periods for
            claims that may arise in connection with the performance of the agreements concluded with the Controller.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Analysis of your activity in the App</td>
                  <td>
                    <ArticleOrderedList>
                      <li>Date and time of visit</li>
                      <li>IP number of the device</li>
                      <li>device operating system type</li>
                      <li>approximate location</li>
                      <li>type of web browser</li>
                      <li>time spent in the App</li>
                      <li>visited subpages and other activities undertaken as part of the App</li>
                    </ArticleOrderedList>
                  </td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case obtaining information about your activity in the App)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary for the Controller to obtain
            information about your activity in the App (the consequence of not providing them will be the Controller's
            inability to obtain the above-mentioned information).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the effective objection is raised or the
            purpose of processing is achieved.
          </ArticleContent>

          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th scope="row">
                    Purpose <br /> of processing
                  </th>
                  <th scope="row">Personal data processed</th>
                  <th scope="row">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>App administration</td>
                  <td>
                    <ArticleOrderedList>
                      <li>IP address</li>
                      <li>server date and time</li>
                      <li>web browser information</li>
                      <li>operating system information</li>
                    </ArticleOrderedList>
                    <br />
                    <br />
                    The above data is saved automatically in the so-called server logs, each time you use the App
                    (administering it without using server logs and automatic saving would not be possible).
                  </td>
                  <td>
                    Article 6(1)(f) of the GDPR (processing is necessary to implement the legitimate interest of the
                    Controller, in this case to ensure the proper operation of the App)
                  </td>
                </tr>
              </tbody>
            </table>
          </TableWrapper>

          <ArticleContent>
            Providing the above-mentioned personal data is voluntary, but necessary to ensure the proper operation of
            the App (the consequence of not providing them will be the inability to ensure the operation of the App in a
            proper manner).
            <br />
            <br />
            The Controller will process the above-mentioned personal data until the effective objection is raised or the
            purpose of processing is achieved.
          </ArticleContent>

          <ArticleWrapper title="Profiling">
            To create your profile for marketing purposes and direct marketing tailored to your preferences, the
            Controller will process your personal data in an automated manner, including profiling them - however, this
            will not cause any legal effects for you or similarly significantly affect your situation.
            <br />
            <br />
            The scope of profiled personal data corresponds to the scope indicated above in relation to the analysis of
            your activity in the App and the data you save on the Account.
            <br />
            <br />
            The legal basis for the processing of personal data for the above purpose is art. 6 par. 1 lit. f GDPR,
            according to which the Controller may process personal data to implement his legitimate interest, in this
            case to conduct marketing activities tailored to the preferences of recipients. Providing the
            above-mentioned personal data is voluntary, but necessary to achieve the above-mentioned purpose (the
            consequence of not providing them will be the inability of the Controller to conduct marketing activities
            tailored to the preferences of recipients).
            <br />
            <br />
            The Controller will process personal data for the purpose of profiling until an objection is effectively
            raised or the purpose of processing is achieved.
          </ArticleWrapper>

          <ArticleWrapper title="Recipients of personal data">
            The recipients of personal data will be the following external entities cooperating with the Controller:
            <ArticleOrderedList>
              <li>hosting company;</li>
              <li>providers of online payment systems;</li>
              <li>newsletter service provider;</li>
              <li>
                companies providing tools for analyzing activity in the App and directing direct marketing to people
                using it (m.in. Google Analytics);
              </li>
              <li>a company providing accounting services.</li>
            </ArticleOrderedList>
            In addition, personal data may also be transferred to public or private entities, if such an obligation
            results from generally applicable law, a final court judgment or a final administrative decision.
          </ArticleWrapper>

          <ArticleWrapper title="Transfer of personal data to a third country">
            In connection with the Controller's use of services provided by Google LLC, your personal data may be
            transferred to the following third countries: Great Britain, Canada, USA, Chile, Brazil, Israel, Saudi
            Arabia, Qatar, India, China, South Korea, Japan, Singapore, Taiwan (Republic of China), Indonesia and
            Australia. The basis for the transfer of data to the above-mentioned third countries are:
            <br />
            <br />
            <ArticleOrderedList>
              <li>
                in the case of the United Kingdom, Canada, Israel and Japan - decisions of the European Commission
                stating an adequate level of protection of personal data in each of the above-mentioned third countries;
              </li>
              <li>
                for the USA, Chile, Brazil, Saudi Arabia, Qatar, India, China, South Korea, Singapore, Taiwan (Republic
                of China), Indonesia and Australia, contractual clauses ensuring an adequate level of protection, in
                accordance with the standard contractual clauses set out in Commission Implementing Decision (EU)
                2021/914 of 4 June 2021 on standard contractual clauses for the transfer of personal data to third
                countries pursuant to a Regulation of the European Parliament and of the Council (EU) 2016/679.
              </li>
            </ArticleOrderedList>
            <br />
            <br />
            You can obtain from the Controller a copy of the data transferred to a third country.
          </ArticleWrapper>

          <ArticleWrapper title="Your rights">
            In connection with the processing of personal data, you have the following rights:
            <br />
            <br />
            <ArticleOrderedList>
              <li>
                the right to information about what personal data concerning you is processed by the Controller and to
                receive a copy of this data (the so-called right of access). Issuing the first copy of the data is free,
                for the next one the Controller may charge a fee;
              </li>
              <li>
                if the processed data becomes outdated or incomplete (or otherwise incorrect), you have the right to
                request its rectification;
                <ArticleOrderedSublist>
                  <li>in certain situations, you can ask the Controller to delete your personal data, e.g. when:</li>
                  <li>the data will no longer be needed by the Controller for the purposes of which he informed;</li>
                  <li>
                    you have effectively withdrawn your consent to the processing of data - unless the Controller has
                    the right to process data on another legal basis;
                  </li>
                  <li>the processing is unlawful;</li>
                  <li>the need to delete data results from the Controller's legal obligation.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                if personal data are processed by the Controller on the basis of consent to processing or for the
                purpose of performing the Agreement concluded with him, you have the right to transfer your data to
                another Controller;
              </li>
              <li>
                if personal data is processed by the Controller on the basis of your consent to processing, you have the
                right to withdraw this consent at any time (withdrawal of consent does not affect the lawfulness of
                processing that was made on the basis of consent before its withdrawal);
              </li>
              <li>
                if you consider that the processed personal data is incorrect, their processing is unlawful, or the
                Controller no longer needs certain data, you can request that for a specific, necessary time (e.g.
                checking the correctness of data or pursuing claims) the Controller does not perform any operations on
                the data, but only Apps them;
              </li>
              <li>
                you have the right to object to the processing of personal data whose basis for processing is the
                legitimate interest of the Controller. In the event of an effective objection, the Controller will cease
                to process personal data for the above-mentioned purpose;
              </li>
              <li>
                you have the right to lodge a complaint with the President of the Office for Personal Data Protection if
                you believe that the processing of personal data violates the provisions of the GDPR.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="Cookies">
            <ArticleOrderedList>
              <li>
                The Controller informs that the App uses "cookies" installed on your end device. These are small text
                files that can be read by the Controller's system, as well as by systems belonging to other entities
                whose services are used by the Controller (e.g. Facebook, Google).
              </li>
              <li>
                The Controller uses cookies for the following purposes:
                <ArticleOrderedSublist>
                  <li>
                    ensuring the proper operation of the App - thanks to cookies, it is possible to operate efficiently
                    the App, use its functions and conveniently move between individual subpages;
                  </li>
                  <li>
                    increasing the comfort of browsing the App - thanks to cookies, it is possible to detect errors on
                    some subpages and their constant improvement;
                  </li>
                  <li>
                    creating statistics - cookies are used to analyze how users use the App. Thanks to this, it is
                    possible to constantly improve the App and adapt its operation to the preferences of users;
                  </li>
                  <li>
                    conducting marketing activities - thanks to cookies, the Controller may direct advertisements
                    tailored to users' preferences.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Controller can place both permanent and temporary (session) files on your device. Session files are
                usually deleted when you close the browser, but closing the browser does not delete persistent files.
              </li>
              <li>
                Information about cookies used by the Controller is displayed in the panel located at the bottom of the
                App's website. Depending on your decision, you can enable or disable cookies of individual categories
                (except for necessary cookies) and change these settings at any time.
              </li>
              <li>Data collected using cookies do not allow the Controller to identify you.</li>
              <li>The Controller uses the following cookies or tools using them:</li>

              <Container ml={-16} mt={18} mb={18}>
                <TableWrapper isQuad gridCols="14fr 14fr 35fr 35fr">
                  <table>
                    <thead>
                      <tr>
                        <th scope="row">Tool</th>
                        <th scope="row">Supplier</th>
                        <th scope="row">Functions and Scope of Downloaded Data</th>
                        <th scope="row">Period of Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Necessary cookies</td>
                        <td>Controller</td>
                        <td>
                          The operation of these files is necessary for the proper functioning of the App, so you can
                          not disable them. Thanks to these files (collecting, m.in. the IP number of your device), it
                          is possible, m.in. to inform you about cookies operating on the App
                        </td>
                        <td>
                          most of the necessary cookies are session cookies, but some remain on your device for a period
                          of 6 months or until they are deleted
                        </td>
                      </tr>
                      <tr>
                        <td>Google Analytics</td>
                        <td>Google</td>
                        <td>
                          This tool allows you to collect statistical data on how users use the App, m.in. the number of
                          visits, the duration of visits, the search engine used, location. The collected data helps to
                          improve the App and make it more friendly to customers.
                        </td>
                        <td>up to 2 years or until they are removed (whichever occurs first)</td>
                      </tr>
                    </tbody>
                  </table>
                </TableWrapper>
              </Container>

              <li>
                Through most used browsers, you can check whether cookies have been installed on your end device, as
                well as delete installed cookies and block them from being installed by the App in the future. Disabling
                or limiting the use of cookies may, however, cause quite serious difficulties in using the App, e.g. in
                the form of the need to log in to each subpage, a longer loading period of the App's restrictions on the
                use of certain functionalities.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="Final provisions">
            To the extent not regulated by the Policy, generally applicable provisions on the protection of personal
            data shall apply.
            <br />
            <br />
            This policy is effective from 17.02.2024r.
          </ArticleWrapper>
        </ArticlesList>
      </LegalPageLayout>
    </PageHelmetWrapper>
  );
};

export default PrivacyPolicyTerraeyeApp;
