export const requestAccessCompanyData = {
  address: [
    {
      id: 1,
      content: 'Remote Sensing Business Solutions Sp. Z O. O.',
    },
    {
      id: 2,
      content: 'Długosza 60A, 51-162 Wrocław',
    },
    {
      id: 3,
      content: 'Poland, EU',
    },
    {
      id: 4,
      content: 'sales@terraeye.co',
    },
  ],
  details: [
    {
      id: 1,
      content: 'NIP 8952241235',
    },
    {
      id: 2,
      content: 'KRS 0000970039',
    },
  ],
  messages: [
    {
      id: 1,
      content:
        'We are a company that develops technologies that assist in caring for the environment and communities exposed to all kinds of mining, rehabilitation, or energy projects.',
    },
    {
      id: 2,
      content:
        'Our system monitors the environment and creates access to crucial information to prevent disasters and maintain safety at any stage of a project.',
    },
  ],
};
