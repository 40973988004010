import { ContainerAlign, ContainerSize, ThemeColors } from 'types';
import { Container } from '../Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import {
  HeadingWrapper,
  SectionHeaderDescriptionWrapper,
  SectionHeaderDescription,
  SectionHeaderHeadingWrapper,
} from './styled';
import { Heading } from 'ui/atoms/heading';

type Palette<TVariant extends string, TVariantProps> = {
  [variant in TVariant]: TVariantProps;
};

export type SectionHeaderVariant = 'PRIMARY' | 'SECONDARY_H1' | 'SECONDARY_H2';

type SectionHeaderVariantProps = Required<{
  as: 'h1' | 'h2';
  containerSize: ContainerSize;
  containerAlign: ContainerAlign;
  headingColor: ThemeColors | undefined;
  withDecorators: boolean;
}>;

const variants: Palette<SectionHeaderVariant, SectionHeaderVariantProps> = {
  PRIMARY: {
    as: 'h1',
    containerSize: 'full',
    containerAlign: 'start',
    headingColor: undefined,
    withDecorators: true,
  },
  SECONDARY_H1: {
    as: 'h1',
    containerSize: 'medium',
    containerAlign: 'center',
    headingColor: 'white',
    withDecorators: false,
  },
  SECONDARY_H2: {
    as: 'h2',
    containerSize: 'medium',
    containerAlign: 'center',
    headingColor: undefined,
    withDecorators: true,
  },
};

type SectionHeaderProps = {
  variant?: SectionHeaderVariant;
  title: React.ReactNode;
  nextLineTitle?: string | null;
  description?: React.ReactNode;
  additionalDescription?: React.ReactNode;
  isBackgroundLight?: boolean;
};

const SectionHeader = ({
  variant = 'PRIMARY',
  title,
  nextLineTitle,
  description,
  additionalDescription,
  isBackgroundLight,
}: SectionHeaderProps) => {
  return (
    <header>
      <HeadingWrapper>
        <PaddingWrapper size="large" type="inline">
          <Container size={variants[variant].containerSize} align={variants[variant].containerAlign}>
            <SectionHeaderHeadingWrapper withDecorators={variants[variant].withDecorators}>
              <Heading as={variants[variant].as} color={variants[variant].headingColor}>
                {title}
                {nextLineTitle && <span>{nextLineTitle}</span>}
              </Heading>
            </SectionHeaderHeadingWrapper>
          </Container>
        </PaddingWrapper>
      </HeadingWrapper>
      {description && (
        <PaddingWrapper size="large" type="inline">
          <Container size={variants[variant].containerSize} align={variants[variant].containerAlign}>
            <SectionHeaderDescriptionWrapper>
              <SectionHeaderDescription variant={isBackgroundLight ? 'SECONDARY' : 'PRIMARY'} size="XL">
                {description}
              </SectionHeaderDescription>
              {additionalDescription}
            </SectionHeaderDescriptionWrapper>
          </Container>
        </PaddingWrapper>
      )}
    </header>
  );
};

export default SectionHeader;
