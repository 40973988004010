export const dataServicesTypes = [
  {
    id: 1,
    title: 'Multispectral Data',
    description:
      'Multispectral data capture image data at specific frequency ranges across the electromagnetic spectrum. These ranges, or bands, are typically broader and fewer in number compared to hyperspectral data. Multispectral sensors, like those on Sentinel-2 and Landsat 8, are widely used for applications such as vegetation analysis, land cover classification, and water quality assessment.',
  },
  {
    id: 2,
    title: 'Synthetic Aperture Radar (SAR) Data',
    description:
      "SAR data use microwave radar to create high-resolution images of the Earth's surface, regardless of weather conditions or daylight. This capability makes SAR particularly valuable for applications such as surface deformation studies, biomass estimation, and monitoring of natural disasters like floods and earthquakes. ICEye SAR is one of the key SAR data sources we utilize.",
  },
  {
    id: 3,
    title: 'Hyperspectral Data',
    description:
      'Hyperspectral data consist of hundreds of narrow spectral bands, providing detailed spectral information for each pixel in the image. This high level of detail allows for the identification and analysis of materials based on their spectral signatures. Sensors like Prisma and Hyperion collect hyperspectral data, which are used in applications such as mineral exploration, agricultural monitoring, and environmental assessment.',
  },
  {
    id: 4,
    title: 'Other Data Types',
    description:
      'We also incorporate data from a variety of other satellites, such as Airbus Pleiades NEO and PlanetScope, which provide high-resolution optical imagery useful for detailed mapping and monitoring of urban development, infrastructure, and land use changes.',
  },
];
