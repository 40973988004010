import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

type SubPageLayoutProps = PropsWithChildren<{ image: ReactNode }>;

const SubPageLayout = ({ children, image }: SubPageLayoutProps) => {
  return (
    <StyledSubPage>
      <SubPageImageWrapper>{image}</SubPageImageWrapper>
      <SubPageContainer>{children}</SubPageContainer>
    </StyledSubPage>
  );
};

export default SubPageLayout;

const StyledSubPage = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: auto;
    grid-template-rows: 230px auto;
  }
`;

const SubPageImageWrapper = styled.div`
  z-index: -1;
  background: linear-gradient(0deg, #171615 0%, #171615 100%), lightgray 50% / cover no-repeat;
`;

const SubPageContainer = styled.div`
  padding-top: 193px;
  background-color: ${({ theme }) => theme.colors.mineShaft};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-top: 56px;
  }
`;
