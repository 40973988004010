import styled from 'styled-components';

import { LinkButton } from 'ui/atoms/button/Button';
import { Paragraph } from 'ui/atoms/paragraph';

export const StyledCaseStudy = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CaseStudyImageWrapper = styled.div<{ altColor: boolean }>`
  position: relative;
  margin-bottom: 30px;
  aspect-ratio: 305 / 216;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    aspect-ratio: 213 / 170;
    margin-bottom: 23px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    aspect-ratio: 295 / 170;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.9375rem;
    right: clamp(0.9375rem, -0.625rem + 2.27vw, 1.25rem);
    width: 100%;
    height: 66%;
    border: 2px solid ${({ theme, altColor }) => altColor ? theme.colors.rawSienna : theme.colors.alto};
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CaseStudyTitle = styled.h3`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.9375rem;
  left: clamp(0.9375rem, -6.875rem + 11.36vw, 2.5rem);
  margin: 0;
  padding-right: 40px;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 1.25rem;
  font-size: clamp(1.25rem, -0.625rem + 2.73vw, 1.625rem);
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-right: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 1.25rem;
    font-size: clamp(1.25rem, 0.126953125rem + 3.125vw, 1.625rem);
  }
`;

export const CaseStudyDescription = styled(Paragraph)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.459rem + 0.604vw, 0.875rem);
    line-height: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 0.75rem;
    font-size: clamp(0.75rem, 0.376rem + 1.0417vw, 0.875rem);
  }
`;

export const CaseStudyButton = styled(LinkButton)`
  width: 160px;
  margin: 30px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 22px 0 0;
  }
`;
