import { FormData } from 'types';
import { isValidCompany, isValidEmail, isValidMessage, isValidName, isValidPhone } from 'utils/validate';

export const isRequestAccessDataValid = (formData: FormData): boolean => {
  const phone = formData.phone;
  const company = formData.company;
  let isDataValid = false;

  isDataValid = isValidName(formData.name) && isValidEmail(formData.from) && isValidMessage(formData.message);

  if (phone) {
    isDataValid = isDataValid && isValidPhone(phone);
  }

  if (company) {
    isDataValid = isDataValid && isValidCompany(company);
  }

  return isDataValid;
};
