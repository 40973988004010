import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { LabeledCheckboxWrapper } from 'ui/molecules/labeledCheckbox';

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 108px;

  ${LabeledCheckboxWrapper} {
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      margin: 14px 0 0;
    }
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.rawSienna};
  text-decoration: underline;
`;

export const CheckboxInfo = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 35px;
  margin-top: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    align-items: flex-start;
    gap: 25px;
    margin-top: 42px;
  }
`;

export const StyledReCAPTCHA = styled(ReCAPTCHA)`
  max-width: 295px;
  width: 100%;
`;

export const StatusInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
`;
