import styled from 'styled-components';
import { Paragraph } from 'ui/atoms/paragraph';

export const EcosystemDescription = styled(Paragraph)`
  line-height: normal;
  max-width: 792px;
  margin: 50px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 20px;
    margin: 58px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    margin: 38px 0 0;
  }
`;

export const EcosystemPadding = styled.div`
  padding: 0 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 127px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 40px;
  }
`;

export const EcosystemList = styled.ul`
  padding: 0;
  margin: 218px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 316px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 140px;
    margin: 140px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 100px;
    margin: 112px 0 0;
  }
`;
