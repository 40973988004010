import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { Table } from 'components/Table';
import { dataSourceTableHeaders, dataSourceTableRows } from './dataSourceTable';

export const DataSource = () => {
  return (
    <Section>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Data Sources"
        description="TerraEye leverages a wide array of Earth Observation (EO) data sources to provide high-resolution spatial and spectral information. Our data processing uses the following EO data sources:"
        isBackgroundLight
      />
      <PaddingWrapper size="large" type="inline" isXS>
        <Container align="center" size="medium">
          <Table headers={dataSourceTableHeaders} rows={dataSourceTableRows} />
        </Container>
      </PaddingWrapper>
    </Section>
  );
};
