import styled from 'styled-components';

import { TERRAEYE_APP_SIGN_UP_URL } from 'common/constants';
import { BaseButton } from 'ui/atoms/button/Button';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { ListItem } from 'components/ListItem';
import { Subscriptions } from './Subscriptions';
import { FeaturesList } from './Subscriptions/SubscriptionItem/styled';
import { MONITORING_PROJECTS_LIST } from './configs';

export const MineralProspectingPricing = ({ id }: { id: string }) => {
  return (
    <Section id={id} large>
      <SectionHeader variant="SECONDARY_H2" title="Mineral Prospecting Solution" isBackgroundLight />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <PricingIntroductionWrapper>
            <Description>
              <HeroDescription>
                Want to test our mineral prospecting application? Try it by subscription and test the possibilities of
                TerraEye!
              </HeroDescription>
              With data available in minutes, subscription is the best economical option for general insights, and for
              initial understanding of the area of interest. It is easily accessible from inside the TerraEye app.{' '}
              <br />
              <br />
              Not ready to subscribe? No worries you can try our <strong>Free Demo</strong> first before deciding on
              subscription.
            </Description>
            <DemoInfoTile>
              <DemoLabelTile>Free Demo</DemoLabelTile>
              <DemoInfoListsWrapper>
                <DemoInfoList>
                  <DemoInfoListHeader>3 mineral exploration projects</DemoInfoListHeader>
                  <FeaturesList>
                    <ListItem url="https://terraeye.blogspot.com/2024/10/types-of-deposits-of-which-mineralogy.html">
                      Mineralization maps generated using SAM and MTMF algorithms
                    </ListItem>
                    <ListItem url="https://terraeye.blogspot.com/2024/10/geological-spectral-indices.html">
                      Geological Spectral Indices
                    </ListItem>
                    <ListItem>
                      Advanced analyses like{' '}
                      <a
                        href={'https://terraeye.blogspot.com/2024/09/what-is-ai-clusters-and-how-does-it-work.html'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AI Clusters
                      </a>
                      ,{' '}
                      <a
                        href={'https://terraeye.blogspot.com/2024/10/lineament-maps-what-they-are-and-how.html'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Lineaments
                      </a>{' '}
                      and{' '}
                      <a
                        href={'https://terraeye.blogspot.com/2024/10/the-role-of-false-color-composites-and.html'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        False Color Composites
                      </a>
                      .
                    </ListItem>
                  </FeaturesList>
                </DemoInfoList>
                <DemoInfoList>
                  <DemoInfoListHeader>2 mine monitoring projects</DemoInfoListHeader>
                  <FeaturesList>
                    {MONITORING_PROJECTS_LIST.map(({ id, label }) => (
                      <ListItem key={id}>{label}</ListItem>
                    ))}
                  </FeaturesList>
                </DemoInfoList>
              </DemoInfoListsWrapper>
              <ButtonWrapper>
                <ButtonContainer>
                  <BaseButton
                    as="a"
                    href={TERRAEYE_APP_SIGN_UP_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="SM"
                  >
                    sign up
                  </BaseButton>
                </ButtonContainer>
              </ButtonWrapper>
            </DemoInfoTile>
          </PricingIntroductionWrapper>
          <Subscriptions />
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const PricingIntroductionWrapper = styled.div`
  display: flex;
  gap: 105px;
  color: ${({ theme }) => theme.colors.tuatara};
  margin: 70px 0 90px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 40px;
    margin: 50px 0 70px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    gap: 50px;
    margin: 30px 0 50px;
  }
`;

const Description = styled.div`
  flex-basis: 50%;
  font-size: 20px;
  line-height: 1.2em;

  strong {
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
  }
`;

const HeroDescription = styled.div`
  font-size: 28px;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 20px;
  }
`;

const DemoInfoTile = styled.div`
  position: relative;
  background-color: #f0f0f0;
  padding: 90px 30px 30px;
  height: 100%;
  flex-basis: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 70px 20px 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 55px 20px 20px;
  }
`;

export const DemoLabelTile = styled.div`
  position: absolute;
  top: -20px;
  left: 30px;
  padding: 15px 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.copper};
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 25px;
    font-size: 38px;
    padding: 12px 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 30px;
  }
`;

const DemoInfoListsWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 0;
  }
`;

const DemoInfoList = styled.div`
  flex-basis: 50%;
`;

const DemoInfoListHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  width: 130px;
`;
