import styled from 'styled-components';

export const CaseStudiesList = styled.ul<{ digitalTwin?: boolean }>`
  list-style: none;
  padding: 0;
  margin: 108px 0 0;
  display: grid;
  grid-template-columns: ${({ digitalTwin }) => digitalTwin ? 'repeat(2, minmax(0, 465px))' : 'repeat(3, minmax(0, 305px))'};
  justify-content: space-between;
  gap: 120px 40px;
  color: ${({ theme, digitalTwin }) => digitalTwin ? theme.colors.tuatara : theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 70px 0 0;
    column-gap: 1.875rem;
    column-gap: clamp(1.875rem, -6.7623rem + 18.018vw, 5.625rem);
    row-gap: 70px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    margin: 72px 0 0;
    gap: 62px;
  }
`;
