import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';

export const RequestAccessSectionDescription = styled(Paragraph)`
  font-size: 22px;
  margin-top: 43px;
  max-width: 500px;
  line-height: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 100%;
    margin-top: 23px;
  }
`;

export const RequestAccessSectionTopContent = styled.div`
  padding: 0 80px 124px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 0 50px 124px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 126px 72px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 40px 82px 0;
  }
`;
