import styled from 'styled-components';

import backgroundImage from 'assets/images/about/about-image.png';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { Container } from 'ui/atoms/container';
import { BaseButton } from 'ui/atoms/button';

export const About = () => {
  return (
    <Section url={backgroundImage} dark>
      <SectionHeader
        variant="SECONDARY_H2"
        title={
          <TitleWrapper>
            <MainTitle>
              Unlock the power of satellite data, remote sensing processing, and artificial intelligence with TerraEye.
            </MainTitle>
            <AdditionalTitle>
              <div>
                Our state-of-the-art solutions are designed to provide you with comprehensive insights into your mineral
                exploration projects, helping you make informed decisions and optimize your operations.
              </div>
              <Container mt={20}>
                <Button
                  as="a"
                  href="https://terraeye.blogspot.com/2024/11/unlock-power-of-satellite-data-remote.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="SM"
                  width={160}
                >
                  read more
                </Button>
              </Container>
            </AdditionalTitle>
          </TitleWrapper>
        }
      />
    </Section>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  gap: 105px;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  line-height: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const MainTitle = styled.div`
  flex-basis: 52%;
  font-size: 44px;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-basis: 55%;
    font-size: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }
`;

const AdditionalTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  font-size: 32px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-basis: 45%;
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 18px;
  }
`;

const Button = styled(BaseButton)`
  font-family: 'Barlow', sans-serif;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 130px;
    font-size: 10px;
    line-height: 36px;
    letter-spacing: 0.1em;
  }
`;
