import styled from 'styled-components';

import { Logo } from 'components/SubPageLogo/styled';

export const StyledMineCamSection = styled.section`
  padding-bottom: 148px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 120px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-bottom: 88px;
  }
`;

export const MineCamLogo = styled(Logo)`
  margin-top: -6px;
  gap: 7px;

  img:first-child {
    width: 54px;
  }

  img:last-child {
    width: 42px;
    margin-left: 7px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    img:first-child {
      width: 49px;
    }

    img:last-child {
      width: 38px;
      margin-left: 6px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 0;

    img:first-child {
      width: 46px;
    }

    img:last-child {
      width: 36px;
      margin-left: 5px;
    }
  }
`;
