import styled, { keyframes } from 'styled-components';

interface LoadingSpinnerProps {
  size?: number;
}

export const LoadingSpinner = ({ size = 24 }: LoadingSpinnerProps) => {
  return (
    <StyledLoader size={size}>
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </StyledLoader>
  );
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
`;

const StyledLoader = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ size }) => `${size}px`};
    transform-origin: center;
    animation: ${rotate} 2s linear infinite;
  }

  circle {
    fill: none;
    stroke: ${({ theme }) => theme.colors.white};
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;
