import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import { StatusMessage } from 'components/StatusMessage';
import { useFormChecks } from 'pages/CaseStudyFormPage/CaseStudyFormSection/CaseStudyForm/useFormChecks';
import { FormInput } from 'ui/molecules/formInputs';
import { BaseButton } from 'ui/atoms/button';
import { Container } from 'ui/atoms/container';
import { LoadingSpinner } from 'ui/atoms/loadingSpinner/LoadingSpinner';
import { useNewsletter } from './useNewsletter';

export const NewsletterForm = () => {
  const { captchaRef, isCaptchaValid, validateCaptcha, inValidateCaptcha } = useFormChecks();

  const { email, isFocused, status, showError, emailValid, setEmail, onInputFocus, onInputBlur, onSubmit } =
    useNewsletter(isCaptchaValid);

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  if (!recaptchaKey) {
    console.error('Recaptcha key is not defined');
  }

  return (
    <Container variant="VERTICAL" align="flex-start" gap={26}>
      <StyledNewsletterForm onSubmit={onSubmit} noValidate>
        <NewsletterFormInfo>
          Signing up for our newsletter. Know about our future beta releases, and opportunities to contribute to our
          research.
        </NewsletterFormInfo>
        <NewsletterFormInput>
          <FormInput
            customPlaceholderName="Email"
            inputValue={email}
            onInputChange={({ target }) => setEmail(target.value)}
            isFocused={isFocused.email}
            onInputFocus={onInputFocus}
            onInputBlur={onInputBlur}
            type="email"
            errorMessage="Invalid email"
            name="email"
            showError={!emailValid && showError}
            isValid={emailValid}
            isRequired={false}
          />
          <InfoParagraph>
            By submitting your email you agree to our <Link to="privacy-policy">Privacy policy.</Link>
          </InfoParagraph>
        </NewsletterFormInput>
        {recaptchaKey && (
          <StyledReCAPTCHA
            ref={captchaRef}
            onChange={validateCaptcha}
            onExpired={inValidateCaptcha}
            sitekey={recaptchaKey}
            //@ts-ignore
            theme="dark"
          />
        )}
        <SubmitButton
          as="button"
          disabled={status === 'pending' || !isCaptchaValid || !emailValid}
          loading={status === 'pending' ? 'true' : 'false'}
        >
          {status === 'pending' ? <LoadingSpinner /> : 'sign up'}
        </SubmitButton>
      </StyledNewsletterForm>
      {status === 'success' && <StatusMessage type="success">Signed up successfully!</StatusMessage>}
      {status === 'error' && <StatusMessage type="error">Something went wrong, please try again.</StatusMessage>}
    </Container>
  );
};

const StyledNewsletterForm = styled.form`
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  gap: 26px;
  margin-top: 30px;

  input {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    grid-template-columns: repeat(2, minmax(0, 450px));
    gap: 34px 60px;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const NewsletterFormInfo = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  max-width: 305px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    max-width: 100%;
  }
`;

const InfoParagraph = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  a {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.rawSienna};
    text-decoration: underline;
  }
`;

const NewsletterFormInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: -4px;
  max-width: 450px;
`;

const StyledReCAPTCHA = styled(ReCAPTCHA)`
  width: 304px;
`;

const SubmitButton = styled(BaseButton)`
  width: 224px;
  color: ${({ theme }) => theme.colors.white};
`;
