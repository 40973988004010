import { FormData } from 'types';

export const requestAccessFormInitialState: FormData = {
  name: '',
  phone: '',
  from: '',
  company: '',
  message: '',
  job: '',
  country: '',
  casestudy: '',
  subject: 'TerraEye App - access request',
  to: 'app@terraeye.co',
};

export const digitalTwinContactUsFormInitialState: FormData = {
  ...requestAccessFormInitialState,
  subject: 'Digital Twin - contact request',
};

export const requestAccessFormInputFocusState = {
  name: false,
  from: false,
  phone: false,
  company: false,
};
