import { useTranslation } from 'react-i18next';

import { LanguageElement, StyledLanguageSelector } from './styled';
import { languages } from './languages';

const LanguageSelector = ({ isLightSectionsInView }: { isLightSectionsInView: boolean }) => {
  const { i18n } = useTranslation();

  return (
    <StyledLanguageSelector>
      {languages.map(({ code, label }) => (
        <LanguageElement
          key={code}
          $selected={i18n.language === code || i18n.language.includes(`${code}-`)}
          $isLightSectionsInView={isLightSectionsInView}
          onClick={() => i18n.changeLanguage(code)}
        >
          {label}
        </LanguageElement>
      ))}
    </StyledLanguageSelector>
  );
};

export default LanguageSelector;
