import axios from 'axios';

import { FormData } from 'types';

const URL = 'https://fourpoint.pipedrive.com/api/v1/';
const TOKEN = `?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN || ''}`;
const OWNER_ID = process.env.REACT_APP_PIPEDRIVE_OWNER_ID || '';
const ORGANIZATIONS = 'organizations';
const PERSONS = 'persons';
const DEALS = 'deals';
const DEAL_TITLE = 'Szansa sprzedaży';

export const postPipedriveData = async ({ company, name, from, phone }: FormData) => {
  const pipedriveOrganizationData = {
    name: company,
    owner_id: OWNER_ID,
  };

  const pipedriveOrganizationResponse = await axios.post(`${URL}${ORGANIZATIONS}${TOKEN}`, pipedriveOrganizationData);

  const pipedrivePersonData = {
    name,
    email: from,
    phone,
    org_id: pipedriveOrganizationResponse.data.data.id,
  };

  const pipedrivePersonResponse = await axios.post(`${URL}${PERSONS}${TOKEN}`, pipedrivePersonData);

  const pipedriveDealData = {
    title: `${DEAL_TITLE} ${company}`,
    user_id: OWNER_ID,
    person_id: pipedrivePersonResponse.data.data.id,
  };

  await axios.post(`${URL}${DEALS}${TOKEN}`, pipedriveDealData);
};
