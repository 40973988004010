import { theme } from 'theme';
import Video from './Video';
import { desktopVideosData } from './desktopVideosData';
import useMediaQuery from 'hooks/useMediaQuery';
import { StyledDesktopVideos } from './styled';

const DesktopVideos = () => {
  const matchesTabletLarge = useMediaQuery(`(max-width: ${theme.breakpoints.tabletLarge}px)`);

  return (
    <StyledDesktopVideos style={{ display: `${matchesTabletLarge ? 'none' : 'flex'}` }}>
      {desktopVideosData.map(({ id, src, type, titleLogoSrc, sideIconSrc, observerOptions }) => (
        <Video
          key={id}
          src={src}
          type={type}
          titleSrc={titleLogoSrc}
          sideIconSrc={sideIconSrc}
          observerOptions={observerOptions}
        />
      ))}
    </StyledDesktopVideos>
  );
};

export default DesktopVideos;
