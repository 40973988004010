import styled from 'styled-components';

export const StyledHype4ExplorTable = styled.table`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 87px;
  width: 100%;

  th,
  td {
    text-align: start;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.rawSienna};
  }

  tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      grid-template-columns: 43% 31% 26%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 66px;
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 11px;
    margin-top: 40px;
  }
`;

export const TableHead = styled.thead`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.colors.rawSienna};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 16px;
  }

  tr {
    margin-bottom: -3px;
  }

  th:not(:last-child) {
    margin-right: -1px;
  }
`;

export const TableBody = styled.tbody`
  tr {
    margin-bottom: -1px;
  }

  th {
    font-weight: 400;
    margin-right: -1px;
  }

  td:not(:last-child) {
    margin-right: -1px;
  }

  span {
    display: inline-block;
    max-width: 140px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      max-width: 126px;
    }
  }
`;

export const TableFoot = styled.tfoot`
  font-weight: 600;

  tr {
    margin-top: -2px;
  }

  th {
    margin-right: -1px;
  }

  td:not(:last-child) {
    margin-right: -1px;
  }
`;
