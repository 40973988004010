import { LIGHT_SECTIONS_ID } from 'common/constants';
import PageHelmetWrapper from 'components/PageHelmetWrapper';
import CallToAction from 'pages/HomePage/components/CallToAction';
import { ReportsPageHero } from './ReportsPageHero';
import { PricingOptionsSection } from './PricingOptionsSection/PricingOptionsSection';
import { MineralizationMapsSection } from './MineralizationMapsSection/MineralizationMapsSection';

const ReportsPage = () => {
  return (
    <PageHelmetWrapper page="reports">
      <main>
        <ReportsPageHero />
        <MineralizationMapsSection />
        <div id={LIGHT_SECTIONS_ID}>
          <PricingOptionsSection />
        </div>
        <CallToAction
          header="Contact Us<br /> for a Quote"
          description="Ready to elevate your mineral exploration projects with TerraEye's advanced remote sensing capabilities?<br /><br /> Contact us today to get a customized quote tailored to your specific needs."
          buttonLabel="contact us to get a quote"
          butonWidth={275}
          reports
        />
      </main>
    </PageHelmetWrapper>
  );
};

export default ReportsPage;
