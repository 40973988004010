import { useQuery } from '@tanstack/react-query';

import { SUBSCRIPTIONS_INFO_QUERY_KEY } from './constants';
import { getSubscriptionsInfo } from './getSubscriptionsInfo';
import { isSubscriptionsData } from './typeguards';

export const useGetSubscriptionsInfo = () => {
  const { data, status } = useQuery({
    queryKey: [SUBSCRIPTIONS_INFO_QUERY_KEY],
    queryFn: getSubscriptionsInfo,
    staleTime: 15 * 60 * 1000,
    refetchOnWindowFocus: 'always',
  });

  const subscriptionsData = data?.data && isSubscriptionsData(data.data) ? data.data : [];

  return { subscriptionsData, status };
};
