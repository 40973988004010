export const languages = [
  {
    code: 'en',
    label: 'eng',
  },
  {
    code: 'es',
    label: 'esp',
  },
];
