import styled from 'styled-components';

interface TableProps {
  headers: { id: number; header: string }[];
  rows: { id: number; cells: string[] }[];
}

export const Table = ({ headers, rows }: TableProps) => {
  return (
    <StyledTable>
      <tbody>
        <tr>
          {headers.map(({ id, header }) => (
            <th key={id}>{header}</th>
          ))}
        </tr>
        {rows.map(({ id, cells }) => (
          <tr key={id}>
            {cells.map((cell, index) => (
              <td key={index}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors.tuatara};
  margin-top: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 70px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 40px;
  }

  > tbody {
    > tr {
      vertical-align: top;

      th {
        width: 17%;
        font-family: 'Barlow', sans-serif;
        font-size: 14px;
        font-weight: 700;
      }

      td {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }

      th,
      td {
        line-height: 1.4;
        padding: 10px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          font-size: 10px;
          padding: 8px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          font-size: 9px;
          padding: 4px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
          padding: 2px;
        }
      }

      :first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.scorpion};
      }

      :not(:last-child) {
        th,
        td {
          border-bottom: 1px solid ${({ theme }) => theme.colors.rawSienna};
        }
      }

      th:first-child,
      td:first-child {
        padding-left: 12px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          padding-left: 6px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          padding-left: 0;
        }
      }

      th:last-child,
      td:last-child {
        padding-right: 12px;
        min-width: 150px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          padding-right: 6px;
          min-width: 85px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          padding-right: 0;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileTiny}px) {
          min-width: 0px;
        }
      }
    }
  }
`;
