import styled from 'styled-components';

import { useGeocodeSearch } from './useGeocodeSearch';
import { buildResultString } from './helpers';
import { Position } from 'types';
import { SearchInput } from 'ui/molecules/searchInput';

interface GeocodeSearchProps {
  setPosition: (position: Position) => void;
}

export const GeocodeSearch = ({ setPosition }: GeocodeSearchProps) => {
  const { query, isDropdownVisible, results, isError, onQueryChange, onResultSelect } = useGeocodeSearch(setPosition);

  return (
    <StyledGeocodeSearch>
      <SearchInput value={query} onChange={onQueryChange} />

      <DropdownList visible={isDropdownVisible}>
        {isError ? (
          <ErrorMessage>Something went wrong</ErrorMessage>
        ) : (
          results.map((result) => (
            <DropdownListItem key={result.id} onClick={() => onResultSelect(result)}>
              {buildResultString(result)}
            </DropdownListItem>
          ))
        )}
      </DropdownList>
    </StyledGeocodeSearch>
  );
};

const StyledGeocodeSearch = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownList = styled.div<{ visible: boolean }>`
  max-width: fit-content;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  flex-grow: 1;
  margin-top: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.masala};
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
`;

const DropdownListItem = styled.button`
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  background-color: transparent;
  color: inherit;
  text-align: start;

  &:hover {
    background-color: ${({ theme }) => theme.colors.focusGray};
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding: 10px;
  margin: 0;
  width: 200px;
  text-align: center;
`;
