import styled, { css } from 'styled-components';

import rejectMark from 'assets/svg/rejectmark.svg';
import statusCheckmark from 'assets/svg/status-checkmark.svg';

type StatusMessageProps = {
  type: 'success' | 'error';
};

export const StatusMessage = styled.p<StatusMessageProps>`
  display: flex;
  align-items: center;
  gap: 11px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
  }

  ${({ type }) =>
    type === 'success' &&
    css`
      &::before {
        background: no-repeat center url(${statusCheckmark});
      }
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      &::before {
        background: no-repeat center url(${rejectMark});
      }
    `}
`;
