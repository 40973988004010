import styled from 'styled-components';

import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import SubPageLayout from 'components/SubPage';
import { SubPageImage } from 'components/SubPageImage';
import privacyPolicyBackgroundDesktop from 'assets/images/privacyPolicy/privacy-policy-desktop.jpg';
import privacyPolicyBackgroundTablet from 'assets/images/privacyPolicy/privacy-policy-tablet.jpg';
import digitalTwinBackgroundDesktop from 'assets/images/digitalTwin/digital-twin-desktop.jpg';
import digitalTwinBackgroundTablet from 'assets/images/digitalTwin/digital-twin-tablet.jpg';

interface LegalPageLayoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
  digitalTwin?: boolean;
}

const LegalPageLayout = ({ title, children, digitalTwin }: LegalPageLayoutProps) => {
  return (
    <SubPageLayout
      image={
        <SubPageImage fixed desktopUrl={digitalTwin ? digitalTwinBackgroundDesktop : privacyPolicyBackgroundDesktop} tabletUrl={digitalTwin ? digitalTwinBackgroundTablet : privacyPolicyBackgroundTablet} />
      }
    >
      <StyledLegalContentWrapper>
        <SectionHeader title={title} />
        <Wrapper>
          <PaddingWrapper size="large" type="right">
            <Container align="start" size="small">
              <PaddingWrapper size="large" type="left">
                {children}
              </PaddingWrapper>
            </Container>
          </PaddingWrapper>
        </Wrapper>
      </StyledLegalContentWrapper>
    </SubPageLayout>
  );
};

export default LegalPageLayout;

const StyledLegalContentWrapper = styled.section`
  padding-bottom: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 128px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-bottom: 68px;
  }
`;

const Wrapper = styled.div`
  margin-top: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 68px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 30px;
  }
`;
