import { Trans } from 'react-i18next';

import { ABOUT_SECTION_ID } from 'common/constants';
import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { NavigationLogos, NavigationLogo, NavigationLogoText } from 'components/Navigation/styled';
import { Section } from 'components/Section';
import { LogosWrapper, DigitalTwinPlatformSection, DigitalTwinPlatformSectionHeader, DigitalTwinPlatformSectionDescription } from './styled';

interface DigitalTwinPlatformProps {
  header: string;
  description: string;
}

const DigitalTwinPlatform = ({ header, description }: DigitalTwinPlatformProps) => {
  return (
    <Section id={ABOUT_SECTION_ID} dark>
      <SectionHeader
        variant="SECONDARY_H2"
        title={
        <LogosWrapper>
          <NavigationLogos>
            <NavigationLogo />
            <NavigationLogoText />
          </NavigationLogos>
        </LogosWrapper>
        }
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <DigitalTwinPlatformSection >
            <DigitalTwinPlatformSectionHeader>
              <Trans i18nKey={header} />
            </DigitalTwinPlatformSectionHeader>
            <DigitalTwinPlatformSectionDescription>
              <Trans i18nKey={description} />
            </DigitalTwinPlatformSectionDescription>
          </DigitalTwinPlatformSection>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

export default DigitalTwinPlatform;
