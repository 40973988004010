import { ChangeEvent, useEffect, useState } from 'react';

import { Position } from 'types';
import { geocodeSearchClient } from './geocodeSearchClient';
import { buildResultString, extractTextFromQuery } from './helpers';
import { GeocodeSearchResult } from './types';

export const useGeocodeSearch = (setPosition: (position: Position) => void) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<GeocodeSearchResult[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<GeocodeSearchResult | null>(null);

  useEffect(() => {
    selectedResult && selectedResult?.position && setPosition(selectedResult.position);

    selectedResult && console.log('Geocode selected result', selectedResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResult]);

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);

    if (!e.target.value.length) {
      setIsDropdownVisible(false);
    }
  };

  const onResultSelect = (result: GeocodeSearchResult) => {
    setSelectedResult(result);
    setIsDropdownVisible(false);
    setQuery(buildResultString(result));
  };

  const getGeocodeData = async () => {
    const sanitizedQuery = extractTextFromQuery(query);
    setIsError(false);

    try {
      const geocodeResults = await geocodeSearchClient(sanitizedQuery);
      setResults(geocodeResults);
    } catch (error) {
      setIsError(true);
    }
    if (!selectedResult || query !== buildResultString(selectedResult)) {
      setIsDropdownVisible(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onQuerySubmit = (e: any) => {
    e.preventDefault();

    getGeocodeData();
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      query && getGeocodeData();
    }, 1_000);

    return () => clearTimeout(delayInputTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    query,
    results,
    isDropdownVisible,
    isError,
    onQueryChange,
    onQuerySubmit,
    onResultSelect,
  };
};
