import styled from "styled-components";

export const SubpageHero = styled.section<{ url: string }>`
  height: 896px;
  display: flex;
  align-items: center;
  background: ${({ url }) => `center / cover no-repeat url(${url})`};
  background-attachment: fixed;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 280px;
  }

  > header {
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      padding-top: 48px;
    }
  }
`;
