import { useEffect } from 'react';
import L, { LatLngExpression, PolylineOptions } from 'leaflet';
import { useMap } from 'react-leaflet';

interface props {
  latLngs: number[][];
  color: string;
  opacity: string;
  fillOpacity: string;
  pane: string;
}

export const Polygon = ({ latLngs, color, opacity, fillOpacity, pane }: props) => {
  const polygonOptions: PolylineOptions = {
    color: color,
    opacity: +opacity,
    fillOpacity: +fillOpacity,
    pane: pane,
    weight: 1,
  };

  const map = useMap();
  const polygon = L.polygon(latLngs as unknown as LatLngExpression[], polygonOptions as unknown as PolylineOptions);

  useEffect(() => {
    polygon.addTo(map);

    return () => {
      polygon.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, polygon]);

  return null;
};
