export const partnershipData = [
  {
    id: 1,
    image: 'partnership/airbus-logo.svg',
    alt: 'Airbus',
  },
  {
    id: 2,
    image: 'partnership/skywatch-logo.svg',
    alt: 'Skywatch',
  },
  {
    id: 3,
    image: 'partnership/acitoflux-logo.svg',
    alt: 'Acitoflux',
  },
  {
    id: 4,
    image: 'partnership/satellogic-logo.svg',
    alt: 'Satellogic',
  },
  {
    id: 5,
    image: 'partnership/sentinelhub-logo.svg',
    alt: 'Sentinelhub',
  },
  {
    id: 6,
    image: 'partnership/esa-logo.svg',
    alt: 'Esa',
  },
  {
    id: 7,
    image: 'partnership/esric-logo.png',
    alt: 'Esric',
  },
  {
    id: 8,
    image: 'partnership/microsoft-logo.svg',
    alt: 'Microsoft',
  },
  {
    id: 9,
    image: 'partnership/pixxel-logo.png',
    alt: 'Pixxel',
  },
  {
    id: 10,
    image: 'partnership/weme-logo.svg',
    alt: 'Weme',
  },
  {
    id: 11,
    image: 'partnership/planet-logo.svg',
    alt: 'Planet',
  },
  {
    id: 12,
    image: 'global/ncbr.svg',
    alt: 'National Centre for Research and Development',
  },
  {
    id: 13,
    image: 'partnership/wroclaw-university-logo.svg',
    alt: 'Wroclaw University of Science and Technology',
  },
  {
    id: 14,
    image: 'partnership/university-of-warsaw-logo.svg',
    alt: 'University of Warsaw',
  },
  {
    id: 15,
    image: 'partnership/iceye-logo.svg',
    alt: 'Iceye',
  },
];
