import { hyper4ExplorTableData } from './hyper4ExplorTableData';

export const getTotalExpenses = (expenseType: 'grant' | 'eligibleExpenses') => {
  return hyper4ExplorTableData.reduce((total, item) => {
    return total + Number(item[expenseType]);
  }, 0);
};

export const formatExpenses = (expense: number) => {
  return expense.toLocaleString(undefined, {
    useGrouping: true,
    minimumFractionDigits: 0,
  });
};
