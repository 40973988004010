import { Trans } from 'react-i18next';

import { ROUTES } from 'common/routes';
import AppLink from 'components/AppLink';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { LinkButton } from 'ui/atoms/button/Button';
import {
  CallToActionArticle,
  CallToActionButtons,
  CallToActionContent,
  CallToActionDescription,
  CallToActionHeading,
  CallToActionImage,
  StyledCallToAction,
} from './styled';

interface CallToActionProps {
  header?: string;
  description?: string;
  buttonLabel?: string;
  butonWidth?: number;
  digitalTwin?: boolean;
  reports?: boolean;
}

const CallToAction = ({
  header = 'Try the possibilities of TerraEye application',
  description = 'If you see the potential of applying our solutions in your institution, please contact us! <strong>Save time and money on frequent field trips and lengthy analyses. Our data processing algorithms will provide you with information through TerraEye!</strong>',
  buttonLabel = 'contact us',
  butonWidth,
  digitalTwin,
  reports,
}: CallToActionProps) => {
  return (
    <StyledCallToAction>
      <PaddingWrapper size="small" type="inline">
        <Container size="wide" align="center">
          <CallToActionContent>
            <CallToActionImage reports={reports} />
            <CallToActionArticle>
              <CallToActionHeading>
                <Trans i18nKey={header} />
              </CallToActionHeading>
              <CallToActionDescription reports={reports}>
                <Trans i18nKey={description} />
              </CallToActionDescription>
              <CallToActionButtons>
                {!digitalTwin && !reports && <AppLink variant="LIGHT" />}
                <LinkButton to={digitalTwin ? ROUTES.digitalTwinContactUs : ROUTES.contactUs} variant={digitalTwin ? "LIGHT" : "SECONDARY"} width={butonWidth}>
                  {buttonLabel}
                </LinkButton>
              </CallToActionButtons>
            </CallToActionArticle>
          </CallToActionContent>
        </Container>
      </PaddingWrapper>
    </StyledCallToAction>
  );
};

export default CallToAction;
