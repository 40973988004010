import SectionHeader from 'components/SectionHeader';
import { Container } from 'components/Container';
import RequestAccessForm from './RequestAccessForm';
import { PaddingWrapper } from 'components/PaddingWrapper';
import RequestAccessFooter from './RequestAccessFooter';
import { RequestAccessSectionTopContent, RequestAccessSectionDescription } from './styled';

interface RequestAccessSectionProps {
  title: string;
  description: string;
  digitalTwin: boolean;
}

const RequestAccessSection = ({ title, description, digitalTwin }: RequestAccessSectionProps) => {
  return (
    <section>
      <SectionHeader title={title} />
      <RequestAccessSectionTopContent>
        <Container align="start" size="small">
          <PaddingWrapper size="large" type="left">
            <RequestAccessSectionDescription fontWeight="500" fontFamily="Barlow Condensed">
              {description}
            </RequestAccessSectionDescription>

            <RequestAccessForm digitalTwin={digitalTwin} /> 
          </PaddingWrapper>
        </Container>
      </RequestAccessSectionTopContent>
      {!digitalTwin && <RequestAccessFooter />}
    </section>
  );
};

export default RequestAccessSection;
