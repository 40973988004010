import { PUBLIC_CONTAINER_URL } from 'common/constants';
import { getVideoFormat } from 'utils/getVideoFormat';
import { DesktopVideoData } from './types';

const baseUrl = `${PUBLIC_CONTAINER_URL}/videos/main/data-processing`;

export const desktopVideosData: DesktopVideoData[] = [
  {
    id: 1,
    src: `${baseUrl}/source_desktop.${getVideoFormat()}`,
    type: 'animation',
    titleLogoSrc: null,
    sideIconSrc: null,
    observerOptions: {
      threshold: 0.8,
      rootMargin: '0px',
    },
  },
  {
    id: 2,
    src: `${baseUrl}/factory_desktop.${getVideoFormat()}`,
    type: 'loop',
    titleLogoSrc: 'images/dataProcessing/data-factory-text.svg',
    sideIconSrc: 'images/dataProcessing/innovation-lab.svg',
    observerOptions: {
      threshold: 1,
      rootMargin: '0px',
    },
  },
  {
    id: 3,
    src: `${baseUrl}/line_desktop.${getVideoFormat()}`,
    type: 'animation',
    titleLogoSrc: null,
    sideIconSrc: null,
    observerOptions: {
      threshold: 1,
      rootMargin: '0px',
    },
  },
  {
    id: 4,
    src: `${baseUrl}/catalog_desktop.${getVideoFormat()}`,
    type: 'loop',
    titleLogoSrc: 'images/dataProcessing/data-catalog-text.svg',
    sideIconSrc: 'images/dataProcessing/fusion-lab.svg',
    observerOptions: {
      threshold: 1,
      rootMargin: '0px',
    },
  },
  {
    id: 5,
    src: `${baseUrl}/access_desktop.${getVideoFormat()}`,
    type: 'animation',
    titleLogoSrc: null,
    sideIconSrc: null,
    observerOptions: {
      threshold: 0.6,
      rootMargin: '0px',
    },
  },
  {
    id: 6,
    src: `${baseUrl}/app_desktop.${getVideoFormat()}`,
    type: 'loop',
    titleLogoSrc: 'images/dataProcessing/application-text.svg',
    sideIconSrc: null,
    observerOptions: {
      threshold: 1,
      rootMargin: '0px',
    },
  },
];
