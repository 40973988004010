import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface ZoomControlPluginProps {
  zoom?: number;
}

export const ZoomControlPlugin = ({ zoom }: ZoomControlPluginProps) => {
  const map = useMap();

  useEffect(() => {
    zoom && map.setZoom(zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  return null;
};
