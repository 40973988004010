import { ROUTES } from 'common/routes';

export const footerNavigationListData = [
  {
    id: 0,
    path: ROUTES.digitalTwinContactUs,
  },
  {
    id: 1,
    path: ROUTES.digitalTwinPrivacyPolicy,
  },
]
