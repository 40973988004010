import { t } from 'i18next';

import FormBottomContent from 'components/Form/FormBottomContent';
import FormRequiredMessage from 'components/Form/FormRequiredMessage';
import { useForm } from 'hooks/useForm';
import { useInputFocus } from 'hooks/useInputFocus';
import { useFormChecks } from 'pages/CaseStudyFormPage/CaseStudyFormSection/CaseStudyForm/useFormChecks';
import { RequestAccessAreaSelector } from 'pages/RequestAccesPage/RequestAccessSection/RequestAccessAreaSelector';
import { FormInput, FormTextarea } from 'ui/molecules/formInputs';
import { requestAccessFormInitialState, digitalTwinContactUsFormInitialState, requestAccessFormInputFocusState } from './formInitialState';
import { Fieldset, GridWWrapper, StyledRequestAccessForm } from './styled';
import { isRequestAccessDataValid } from './utils';

const RequestAccessForm = ({ digitalTwin }: { digitalTwin: boolean}) => {
  const {
    captchaRef,
    isCaptchaValid,
    validateCaptcha,
    inValidateCaptcha,
    checkboxData,
    setCheckboxData,
    clearCheckboxes,
  } = useFormChecks();

  const { isFocused, onInputBlur, onInputFocus, setIsFocusedToDefault, focusInput } = useInputFocus(
    requestAccessFormInputFocusState,
  );

  const formInitialState = digitalTwin ? digitalTwinContactUsFormInitialState : requestAccessFormInitialState;
  const userAcceptedRequirements = checkboxData.hasAcceptedPrivacyPolicy && isCaptchaValid;

  const {
    formData,
    formValidity,
    showValidationErrors,
    status,
    formRef,
    onInputChange,
    setPolygonAttachment,
    onFormSubmit,
  } = useForm(
    setIsFocusedToDefault,
    formInitialState,
    isRequestAccessDataValid,
    userAcceptedRequirements,
    clearCheckboxes,
    focusInput,
  );

  const { name, phone, from, company, message } = formValidity;

  const showNameError = showValidationErrors && !name;
  const showPhoneError = showValidationErrors && !phone && !!formData.phone;
  const showEmailError = showValidationErrors && !from;
  const showCompanyError = showValidationErrors && !company && !!formData.company;
  const showMessageError = showValidationErrors && !formData.message;

  return (
    <StyledRequestAccessForm ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
      <Fieldset>
        <GridWWrapper>
          <FormInput
            customPlaceholderName={digitalTwin ? t("contact.form.name") : "Name"}
            type="text"
            name="name"
            errorMessage={digitalTwin ? t("contact.form.requiredError") : "Required"}
            isRequired={true}
            isFocused={isFocused.name}
            isValid={name}
            showError={showNameError}
            inputValue={formData.name}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            onInputFocus={onInputFocus}
            digitalTwin={digitalTwin}
          />
          <FormInput
            customPlaceholderName={digitalTwin ? t("contact.form.phone") : "Phone"}
            type="tel"
            name="phone"
            errorMessage={digitalTwin ? t("contact.form.formatError") : "Wrong format"}
            isRequired={false}
            isFocused={isFocused.phone}
            isValid={phone}
            showError={showPhoneError}
            inputValue={formData.phone}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            onInputFocus={onInputFocus}
            digitalTwin={digitalTwin}
          />
          <FormInput
            customPlaceholderName={digitalTwin ? t("contact.form.from") : "E-mail"}
            type="email"
            name="from"
            errorMessage={digitalTwin ? t("contact.form.formatError") : "Wrong format"}
            isRequired={true}
            isFocused={isFocused.from}
            isValid={from}
            showError={showEmailError}
            inputValue={formData.from}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            onInputFocus={onInputFocus}
            digitalTwin={digitalTwin}
          />
          <FormInput
            customPlaceholderName={digitalTwin ? t("contact.form.company") : "Company"}
            type="text"
            name="company"
            errorMessage={digitalTwin ? t("contact.form.formatError") : "Wrong format"}
            isRequired={false}
            isFocused={isFocused.company}
            isValid={company}
            showError={showCompanyError}
            inputValue={formData.company}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            onInputFocus={onInputFocus}
            digitalTwin={digitalTwin}
          />
        </GridWWrapper>
        <FormTextarea
          name="message"
          isRequired={true}
          isValid={message}
          showError={showMessageError}
          inputValue={formData.message}
          onInputChange={onInputChange}
          placeholder={digitalTwin ? t("contact.form.message") : "Type your request here"}
          digitalTwin={digitalTwin}
        />
      </Fieldset>

      <FormRequiredMessage message={digitalTwin ? t("contact.form.requiredFields") : undefined} />

      {!digitalTwin && <RequestAccessAreaSelector setPolygonAttachment={setPolygonAttachment} status={status} />}

      <FormBottomContent
        checkboxData={checkboxData}
        setCheckboxData={setCheckboxData}
        status={status}
        userAcceptedRequirements={userAcceptedRequirements}
        captchaRef={captchaRef}
        onCaptchaValidate={validateCaptcha}
        onCaptchaExpiry={inValidateCaptcha}
        onFormSubmit={onFormSubmit}
        digitalTwin={digitalTwin}
        privacyPolicyLabelFirst={digitalTwin ? t("contact.privacyPolicyLabel.firstPart") : undefined}
        privacyPolicyLabelLinked={digitalTwin ? t("contact.privacyPolicyLabel.linkedPart") : undefined}
        privacyPolicyLabelSecond={digitalTwin ? t("contact.privacyPolicyLabel.secondPart") : undefined}
        sendButtonLabel={digitalTwin ? t("contact.sendButtonLabel") : undefined}
        sendingSuccessMessage={digitalTwin ? t("contact.sendingSuccessMessage") : undefined}
        sendingErrorMessage={digitalTwin ? t("contact.sendingErrorMessage") : undefined}
      />
    </StyledRequestAccessForm>
  );
};

export default RequestAccessForm;
