import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { DefaultView } from '../geocodeSearch/types';
import { LatLngExpression } from 'leaflet';

interface ViewUpdaterProps {
  defaultView?: DefaultView;
  animate?: boolean;
  cleanupAction?: () => void;
}

export const ViewUpdater = ({ defaultView, animate = false, cleanupAction }: ViewUpdaterProps) => {
  const duration = 3;
  const linearity = 0.75;
  const map = useMap();
  const center = defaultView && ([defaultView.lat, defaultView.lon] as LatLngExpression);
  const zoom = defaultView?.zoom;

  useEffect(() => {
    if (center && zoom) {
      map.flyTo(center, zoom, {
        animate: animate,
        duration: duration,
        easeLinearity: linearity,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultView]);

  useEffect(() => {
    map.on('zoomend', () => cleanupAction && cleanupAction());
  }, [cleanupAction, map]);

  return null;
};
