import styled, { css } from 'styled-components';

import previousIcon from './previous-icon.svg';
import nextIcon from './next-icon.svg';

export const CarouselContainer = styled.div`
  padding-left: 148px;
  margin: 120px 80px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.persistentSection}px) {
    padding-left: 160px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding-left: 126px;
    margin: 90px 50px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-left: 40px;
    margin: 55px 20px 0 0;
  }
`;

export const CarouselWrapper = styled.div`
  margin: 0 auto;
  max-width: 1190px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.persistentSection}px) {
    margin: 0;
  }
`;

export const CrossLine = styled.div`
  position: absolute;
  z-index: 9;
  display: block;
  top: 189px;
  height: 1px;
  width: 95%;
  background-color: ${({ theme }) => theme.colors.rawSienna};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    top: 174px;
  }
`;

type GradientProps = {
  hide: boolean;
};

const Gradient = styled.div<GradientProps>`
  position: absolute;
  top: 189px;
  z-index: 9;
  width: 62px;
  height: 1px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    top: 174px;
  }
`;

export const LeftGradient = styled(Gradient)`
  left: -60px;
  background: linear-gradient(90deg, rgba(186, 243, 255, 0) 0%, rgba(202, 133, 66, 1) 100%);
  transition: left 400ms ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    left: -18px;
    width: 24px;
  }

  ${({ hide }) =>
    hide &&
    css`
      left: 0;

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        left: 0;
      }
    `}
`;

export const RightGradient = styled(Gradient)`
  right: 0;
  opacity: 1;
  background: linear-gradient(90deg, rgba(202, 133, 66, 1) 0%, rgba(224, 224, 224, 1) 100%);
  transition: opacity 450ms ease-in-out, right 400ms ease-in-out;

  ${({ hide }) =>
    hide &&
    css`
      right: 59px;
      opacity: 0;
    `}
`;

export const HideOverflowWrapper = styled.div`
  overflow: hidden;
`;

export const StyledCarousel = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

const CarouselButton = styled.button`
  top: 160px;
  position: absolute;
  width: 20px;
  height: 17px;
  background-color: transparent;
  cursor: pointer;
  opacity: 100%;
  visibility: visible;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    top: 154px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    top: 151px;
  }
`;

type ButtonProps = {
  hide: boolean;
};

export const PreviousButton = styled(CarouselButton)<ButtonProps>`
  background: no-repeat center / cover url(${previousIcon});
  left: -72px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    left: -16px;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;

export const NextButton = styled(CarouselButton)<ButtonProps>`
  background: no-repeat center / cover url(${nextIcon});
  right: -10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    right: 0;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;
