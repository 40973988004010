import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { BENEFITS_SECTION_ID, FEATURES_SECTION_ID } from "common/constants";

export const resources = {
  en: {
    translation: {
      navigation: {
        productName: 'Digital Twin Platform',
        data: [
          {
            id: 1,
            title: 'features',
            to: FEATURES_SECTION_ID,
          },
          {
            id: 2,
            title: 'benefits',
            to: BENEFITS_SECTION_ID,
          },
        ],
        contactLabel: 'contact',
      },
      hero: {
        header: "Transforming Lithium<br/> Brine Extraction",
        description: "Revolutionize your mining operations with the cutting-edge Digital Twin Platform for Salars.",
        buttonLabel: "learn more",
        madeBy: "by",
      },
      partnership: {
        header: "our partners & collaborators",
      },
      about: {
        header: "Digital Twin<br/>Platform",
        description: "<strong>The TerraEye: Digital Twin Platform</strong> is a web-based application that allows you to access, manage and share information about your extraction assets from one place. Our platform is custom built especially for the salar use-case making it accurate and reliable. Using satellite imagery and other sensors our platform recreates the selected area of interest in the digital space making information about it easily accessible.",
      },
      features: {
        title: "Key Features",
        description: "Supporting your decisions with data that you need.",
        data: [
          {
            id: 1,
            title: 'Real-Time Monitoring',
            subTitle: 'Monitor environmental and operational parameters in real-time for optimal decision-making.',
            description: '',
            videoName: 'monitoring',
          },
          {
            id: 2,
            title: 'Advanced Analytics',
            subTitle: 'Leverage AI and ML for predictive analysis and actionable insights.',
            description: '',
            videoName: 'analytics',
          },
          {
            id: 3,
            title: 'Comprehensive Data Integration',
            subTitle: 'Integrate various data sources seamlessly for a complete overview.',
            description: '<1><2>Multispectral satelites (0,5m - 10m)</2><2>Radar satelites (0,5-40m)</2><2>Thermal satelites (5m-30m)</2><2>Hyperspectral satelites (3m-60m)</2></1>',
            videoName: 'comprehensive',
          },
          {
            id: 4,
            title: 'ESG and Compliance',
            subTitle: 'Ensure compliance with environmental, social, and governance standards. Fully customizable Dashboards for Community and Government engagement.',
            description: '',
            videoName: 'esg',
          }
        ]
      },
      benefits: {
        title: "Benefits",
        description: "Improve efficiency while providing transparency.",
        data: [
          {
            id: 1,
            title: 'Enhanced Efficiency',
            subTitle: '',
            description: 'Streamline operations and reduce costs with precise monitoring and analytics.',
            imageName: 'gear',
          },
          {
            id: 2,
            title: 'Improved Sustainability',
            subTitle: '',
            description: 'Minimize environmental impact and promote sustainable practices.',
            imageName: 'earth',
          },
          {
            id: 3,
            title: 'Risk Mitigation',
            subTitle: '',
            description: 'Identify and mitigate risks proactively to ensure safe operations.',
            imageName: 'indicator',
          },
          {
            id: 4,
            title: 'Community Engagement',
            subTitle: '',
            description: 'Foster transparency and build trust with local communities through open data access.',
            imageName: 'people',
          }
        ]
      },
      callToAction: {
        header: "Interested to Learn More About our Solution?",
        description: "If you see the potential of applying our solutions in your institution, please contact us! <strong>Save time and money on frequent field trips and lengthy analyses. Our data processing algorithms will provide you with information through our Digital Twin Platform inside TerraEye!</strong>",
        buttonLabel: "contact us"
      },
      footer: {
        addressPL: `
          <strong>Remote Sensing Business Solutions Sp. Z O. O.</strong><br/>
          Długosza 60A, 51-162 Wrocław, Poland<br/>
          support@terraeye.co
        `,
        addressNL: `
          <strong>Four Point Space SARL</strong><br/>
          Technoport, 9 Av. des Hauts-Fourneaux<br/>
          L-4362 Esch-sur-Alzette, Luxembourg
        `,
        linkedInLabel: 'Find us on LinkedIn',
        navigation: [
          {
            id: 0,
            content: 'Contact',
          },
          {
            id: 1,
            content: 'Privacy Policy',
          },
        ]
      },
      contact: {
        title: "Contact",
        description: "Feel free to reach out to us with questions regarding collaboration or specific areas of interest for your exploration needs.",
        form: {
          name: "Name",
          phone: "Phone",
          from: "E-mail",
          company: "Company",
          message: "Type your request here",
          requiredError: "Required",
          formatError: "Wrong format",
          requiredFields: "Indicates required fields"
        },
        privacyPolicyLabel: {
          firstPart: "I declare that I have read the ",
          secondPart: " and accept their provisions.",
          linkedPart: "Privacy Policy",
        },
        sendButtonLabel: "send",
        sendingSuccessMessage: "your message has been sent!",
        sendingErrorMessage: "your message could not be sent!",
      },
      privacyPolicy: {
        title: "Privacy Policy",
        list: [
          {
            id: 1,
            title: "Privacy Policy for RSBS P.S.A.",
            description:
            `
              <1>
                <3>
                  At Remote Sensing Business Solutions, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Remote Sensing Business Solutions and how we use it.
                </3>
                <3>
                  If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                </3>
                <3>
                  This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Remote Sensing Business Solutions. This policy is not applicable to any information collected offline or via channels other than this website.
                </3>
              </1>
            `,
          },
          {
            id: 2,
            title: "Information we collect",
            description:
            `
              <1>
                <3>
                  When you contact us directly, we will collect additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                </3>
                <3>
                  When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                </3>
              </1>
            `,
          },
          {
            id: 3,
            title: "How we use your information",
            description:
            `
              <1>
                <3>
                  We use the information we collect in various ways, including to:
                  <2>
                    <3>Provide, operate, and maintain our website</3>
                    <3>Improve, personalize, and expand our website</3>
                    <3>Understand and analyze how you use our website</3>
                    <3>Develop new products, services, features, and functionality</3>
                    <3>
                      Communicate with you, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes (only if you gave your consent to process your personal data for marketing purposes)
                    </3>
                    <3>Find and prevent fraud</3>
                  </2>
                </3>
              </1>
            `,
          },
          {
            id: 4,
            title: "Information clause on the processing of personal data",
            description:
            `
              <1>
                <3>
                  Your personal Data Controller is Remote Sensing Business Solutions with its registered office in Wrocław at the following address: ul. Jana Długosza 60a, 51-162 Wrocław.
                </3>
                <3>
                  Your personal data will be used for the purpose of processing your request for access to our products.
                </3>
                <3>
                  Providing your personal data is voluntary, however, failure to provide the indicated data will prevent the Data Controller from conducting activities. Your consent may be withdrawn at any time, but the withdrawal of consent does not affect the lawfulness of the processing of data which was made on the basis of consent prior to its withdrawal.
                </3>
                <3>
                  Your data will be processed on the basis of your consent, i.e. pursuant to Article 6 (1) (a) of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation, “GDPR”).
                </3>
                <3>
                  Your personal data will be processed until you withdraw your consent to the processing of personal data.
                </3>
                <3>Your personal data will not be used for automated individual decision-making, including profiling.</3>
                <3>
                  Your personal data are transferred to entities providing IT support to Remote Sensing Business Solutions or to authorized employees of Remote Sensing Business Solutions; the transfer of data is performed
                  pursuant to the concluded personal data processing agreements, and the processing entities process them exclusively at the request of Remote Sensing Business Solutions, i.e. they are not authorized to process such data for their own purposes.
                </3>
                <3>
                  You have the following rights:
                  <2>
                    <3>the right to access - you have the right to request copies of your personal data.</3>
                    <3>
                      the right to rectification - you have the right to request that we correct any information you believe is inaccurate. you also have the right to request that we complete the information you believe is incomplete.
                    </3>
                    <3>
                      the right to erasure - you have the right to request that we erase your personal data, under certain conditions.
                    </3>
                    <3>
                      the right to restrict processing - you have the right to request that we restrict the processing of your personal data, under certain conditions.
                    </3>
                    <3>
                      the right to object to processing - you have the right to object to our processing of your personal data, under certain conditions.
                    </3>
                    <3>
                      the right to data portability - you have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </3>
                  </2>
                </3>
              </1>
            `,
          },
          {
            id: 5,
            title: 'Log files',
            description:
              "Remote Sensing Business Solutions follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.",
          },
          {
            id: 6,
            title: 'Cookies and web beacons',
            description:
              "Like any other website, Remote Sensing Business Solutions uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.For more general information on cookies, please read \"What Are Cookies\".",
          },
          {
            id: 7,
            title: 'Advertising partners privacy policies',
            description:
              "You may consult this list to find the Privacy Policy for each of the advertising partners of Remote Sensing Business Solutions.Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Remote Sensing Business Solutions, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.Note that Remote Sensing Business Solutions has no access to or control over these cookies that are used by third-party advertisers.",
          },
          {
            id: 8,
            title: 'Third party privacy policies',
            description:
              "Remote Sensing Business Solutions's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.",
          },
        ]
      }
    }
  },
  es: {
    translation: {
      navigation: {
        productName: 'Plataforma Gemela Digital',
        data: [
          {
            id: 1,
            title: 'características',
            to: FEATURES_SECTION_ID,
          },
          {
            id: 2,
            title: 'beneficios',
            to: BENEFITS_SECTION_ID,
          },
        ],
        contactLabel: 'contacto',
      },
      hero: {
        header: "Transformación<br/> de la Extracción<br/> de Salmuera de Litio",
        description: "Revolucione sus operaciones mineras con la vanguardista Plataforma Gemela Digital para Salares.",
        buttonLabel: "aprende más",
        madeBy: "por",
      },
      partnership: {
        header: "nuestros socios y colaboradores",
      },
      about: {
        header: "Plataforma<br/>Gemela Digital",
        description: "<strong>TerraEye: Plataforma Gemela Digital</strong> es una aplicación basada en web que le permite acceder, administrar y compartir información sobre sus activos de extracción desde un solo lugar. Nuestra plataforma está diseñada especialmente para el caso de uso de salar, lo que la hace precisa y confiable. Utilizando imágenes satelitales y otros sensores, nuestra plataforma recrea el área de interés seleccionada en el espacio digital haciendo que la información sobre ella sea fácilmente accesible.",
      },
      features: {
        title: "Características",
        description: "Respaldando tus decisiones con los datos que necesitas.",
        data: [
          {
            id: 1,
            title: 'Monitoreo en Tiempo Real',
            subTitle: 'Monitoree los parámetros ambientales y operativos en tiempo real para una óptima toma de decisiones.',
            description: '',
            videoName: 'monitoring',
          },
          {
            id: 2,
            title: 'Análisis Avanzado',
            subTitle: 'Aproveche la IA y el ML para realizar análisis predictivos y obtener información práctica.',
            description: '',
            videoName: 'analytics',
          },
          {
            id: 3,
            title: 'Integración Integral de Datos',
            subTitle: 'Integre varias fuentes de datos sin problemas para obtener una descripción general completa.',
            description: '<1><2>Satélites multiespectrales (0,5m - 10m)</2><2>Satélites radar (0,5-40m)</2><2>Satélites térmicos (5m-30m)</2><2>Satélites hiperespectrales (3m-60m)</2></1>',
            videoName: 'comprehensive',
          },
          {
            id: 4,
            title: 'ESG y Cumplimiento',
            subTitle: 'Garantizar el cumplimiento de los estándares ambientales, sociales y de gobernanza. Paneles de control totalmente personalizables para la participación de la comunidad y el gobierno.',
            description: '',
            videoName: 'esg',
          }
        ]
      },
      benefits: {
        title: "Beneficios",
        description: "Mejore la eficiencia al tiempo que proporciona transparencia.",
        data: [
          {
            id: 1,
            title: 'Eficiencia Mejorada',
            description: 'Optimice las operaciones y reduzca los costos con monitoreo y análisis precisos.',
            imageName: 'gear',
          },
          {
            id: 2,
            title: 'Sostenibilidad Mejorada',
            description: 'Minimizar el impacto ambiental y promover prácticas sustentables.',
            imageName: 'earth',
          },
          {
            id: 3,
            title: 'Mitigación de Riesgos',
            description: 'Identifique y mitigue los riesgos de forma proactiva para garantizar operaciones seguras.',
            imageName: 'indicator',
          },
          {
            id: 4,
            title: 'Participación de la Comunidad',
            description: 'Fomentar la transparencia y generar confianza con las comunidades locales a través del acceso a datos abiertos.',
            imageName: 'people',
          }
        ]
      },
      callToAction: {
        header: "¿Está Interesado en Obtener Más Información Sobre Nuestra Solución?",
        description: "Si ve el potencial de aplicar nuestras soluciones en su institución, ¡contáctenos! <strong>Ahorre tiempo y dinero en frecuentes excursiones y análisis prolongados. ¡Nuestros algoritmos de procesamiento de datos le proporcionarán información a través de nuestra Plataforma Gemela Digital dentro de TerraEye!</strong>",
        buttonLabel: "contacto"
      },
      footer: {
        addressPL: `
          <strong>Remote Sensing Business Solutions Sp. Z O. O.</strong><br/>
          Długosza 60A, 51-162 Wrocław, Polonia<br/>
          support@terraeye.co
        `,
        addressNL: `
          <strong>Four Point Space SARL</strong><br/>
          Technoport, 9 Av. des Hauts-Fourneaux<br/>
          L-4362 Esch-sur-Alzette, Luxemburgo
        `,
        linkedInLabel: 'Encuéntranos en LinkedIn',
        navigation: [
          {
            id: 0,
            content: 'Contacto',
          },
          {
            id: 1,
            content: 'Política de Privacidad',
          },
        ]
      },
      contact: {
        title: "Contacto",
        description: "No dude en comunicarse con nosotros si tiene preguntas sobre colaboración o áreas específicas de interés para sus necesidades de exploración.",
        form: {
          name: "Nombre",
          phone: "Teléfono",
          from: "E-mail",
          company: "Empresa",
          message: "Escriba su solicitud aquí",
          requiredError: "Campo requerido",
          formatError: "Formato erróneo",
          requiredFields: "Indica campos obligatorios"
        },
        privacyPolicyLabel: {
          firstPart: "Declaro que he leído la ",
          linkedPart: "Política de Privacidad",
          secondPart: " y acepto sus disposiciones.",
        },
        sendButtonLabel: "enviar",
        sendingSuccessMessage: "¡tu mensaje ha sido enviado!",
        sendingErrorMessage: "¡su mensaje no pudo ser enviado!",
      },
      privacyPolicy: {
        title: "Política de Privacidad",
        list: [
          {
            id: 1,
            title: "Política de Privacidad para RSBS P.S.A.",
            description:
            `
              <1>
                <3>
                  En Remote Sensing Business Solutions, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de privacidad contiene tipos de información que Remote Sensing Business Solutions recopila y registra y cómo la usamos.
                </3>
                <3>
                  Si tiene preguntas adicionales o requiere más información sobre nuestra Política de Privacidad, no dude en contactarnos.
                </3>
                <3>
                  Esta Política de Privacidad se aplica únicamente a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y/o recopilaron en Remote Sensing Business Solutions. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales distintos de este sitio web.
                </3>
              </1>
            `,
          },
          {
            id: 2,
            title: "Información que recopilamos",
            description:
            `
              <1>
                <3>
                  Cuando se comunique con nosotros directamente, recopilaremos información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/o los archivos adjuntos que pueda enviarnos, y cualquier otra información que elija proporcionar.
                </3>
                <3>
                  Cuando se registra para obtener una Cuenta, podemos solicitarle su información de contacto, incluidos elementos como nombre, nombre de la empresa, dirección, dirección de correo electrónico y número de teléfono.
                </3>
              </1>
            `,
          },
          {
            id: 3,
            title: "Cómo utilizamos su información",
            description:
            `
              <1>
                <3>
                  Usamos la información que recopilamos de varias maneras, incluyendo para:
                  <2>
                    <3>Proporcionar, operar y mantener nuestro sitio web.</3>
                    <3>Mejorar, personalizar y ampliar nuestro sitio web</3>
                    <3>Comprender y analizar cómo utiliza nuestro sitio web</3>
                    <3>Desarrollar nuevos productos, servicios, características y funcionalidades.</3>
                    <3>
                      Comunicarnos con usted, incluso para servicio de atención al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y con fines promocionales y de marketing (solo si dio su consentimiento para procesar sus datos personales con fines de marketing).
                    </3>
                    <3>Encuentre y prevenga el fraude</3>
                  </2>
                </3>
              </1>
            `,
          },
          {
            id: 4,
            title: "Cláusula informativa sobre el tratamiento de datos personales",
            description:
            `
              <1>
                <3>
                  Su responsable del tratamiento de datos personales es Remote Sensing Business Solutions con domicilio social en Wrocław en la siguiente dirección: ul. Jana Długosza 60a, 51-162 Wrocław.
                </3>
                <3>
                  Sus datos personales se utilizarán con el fin de procesar su solicitud de acceso a nuestros productos.
                </3>
                <3>
                  Proporcionar sus datos personales es voluntario, sin embargo, el hecho de no proporcionar los datos indicados impedirá que el Responsable del tratamiento pueda realizar actividades. Su consentimiento puede ser retirado en cualquier momento, pero la retirada del consentimiento no afecta la licitud del procesamiento de datos que se realizó sobre la base del consentimiento antes de su retirada.
                </3>
                <3>
                  Sus datos serán tratados sobre la base de su consentimiento, es decir, de conformidad con el artículo 6 (1) (a) del Reglamento (EU) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas con en lo que respecta al procesamiento de datos personales y a la libre circulación de dichos datos, y por la que se deroga la Directiva 95/46/CE (Reglamento General de Protección de Datos, “GDPR”).
                </3>
                <3>
                  Sus datos personales serán tratados hasta que usted retire su consentimiento al tratamiento de datos personales.
                </3>
                <3>Sus datos personales no se utilizarán para la toma de decisiones individuales automatizadas, incluida la elaboración de perfiles.</3>
                <3>
                  Sus datos personales se transfieren a entidades que brindan soporte de TI a Remote Sensing Business Solutions o a empleados autorizados de Remote Sensing Business Solutions; la transferencia de datos se realiza de conformidad con los acuerdos de procesamiento de datos personales celebrados, y las entidades procesadoras los procesan exclusivamente a solicitud de Remote Sensing Business Solutions, es decir, no están autorizadas a procesar dichos datos para sus propios fines.
                </3>
                <3>
                  Tienes los siguientes derechos:
                  <2>
                    <3>el derecho de acceso: tiene derecho a solicitar copias de sus datos personales.</3>
                    <3>
                      el derecho a la rectificación: tiene derecho a solicitar que corrijamos cualquier información que considere inexacta. También tiene derecho a solicitar que completemos la información que crea que está incompleta.
                    </3>
                    <3>
                      el derecho de eliminación: tiene derecho a solicitar que eliminemos sus datos personales, bajo ciertas condiciones.
                    </3>
                    <3>
                      el derecho a restringir el procesamiento: tiene derecho a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones.
                    </3>
                    <3>
                      el derecho a oponerse al procesamiento: usted tiene derecho a oponerse a nuestro procesamiento de sus datos personales, bajo ciertas condiciones.
                    </3>
                    <3>
                      el derecho a la portabilidad de los datos: tiene derecho a solicitar que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones.
                    </3>
                  </2>
                </3>
              </1>
            `,
          },
          {
            id: 5,
            title: 'Archivos de registro',
            description:
              "Remote Sensing Business Solutions sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan sitios web. Todas las empresas de hosting hacen esto y forman parte del análisis de los servicios de hosting. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), marca de fecha y hora, páginas de referencia/salida y posiblemente la cantidad de clics. Estos no están vinculados a ninguna información que sea de identificación personal. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica.",
          },
          {
            id: 6,
            title: 'Cookies y balizas web',
            description:
              "Como cualquier otro sitio web, Remote Sensing Business Solutions utiliza 'cookies'. Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes y las páginas del sitio web a las que el visitante accedió o visitó. La información se utiliza para optimizar la experiencia de los usuarios personalizando el contenido de nuestra página web según el tipo de navegador de los visitantes y/u otra información. Para obtener más información general sobre las cookies, lea \"¿Qué son las cookies?\".",
          },
          {
            id: 7,
            title: 'Políticas de privacidad de los socios publicitarios',
            description:
              "Puede consultar esta lista para encontrar la Política de Privacidad de cada uno de los socios publicitarios de Remote Sensing Business Solutions. Los servidores o redes publicitarias de terceros utilizan tecnologías como cookies, JavaScript o Web Beacons que se utilizan en sus respectivos anuncios y enlaces que aparecen en Remote Sensing Business Solutions, que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando esto ocurre. Estas tecnologías se utilizan para medir la efectividad de sus campañas publicitarias y/o para personalizar el contenido publicitario que ve en los sitios web que visita. Tenga en cuenta que Remote Sensing Business Solutions no tiene acceso ni control sobre estas cookies que utilizan terceros. anunciantes del partido.",
          },
          {
            id: 8,
            title: 'Políticas de privacidad de terceros',
            description:
              "La Política de Privacidad de Remote Sensing Business Solutions no se aplica a otros anunciantes o sitios web. Por lo tanto, le recomendamos que consulte las respectivas Políticas de Privacidad de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo darse de baja de ciertas opciones. Puede optar por desactivar las cookies a través de las opciones individuales de su navegador. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, puede encontrarla en los sitios web respectivos de los navegadores.",
          },
        ]
      }
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
