export const articlesData = [
  {
    id: 1,
    title: 'Data Sources',
    description:
      'Data Sources - Our solution unifies <strong>diverse data streams from a multitude of satellite constellations including Optical (Multispectral and Hyperspectral) and Synthetic Aperture Radar (SAR).</strong> To ensure you have a comprehensive understanding of your site, <strong>we also process and incorporate data from Unmanned Aerial Vehicles (UAVs)</strong>, ground sensors, and even integrate with your existing company data.',
  },
  {
    id: 2,
    title: 'Creating Meaningful Data',
    description:
      'The raw data collected from various sources is <strong>processed in our Data Factory through our proprietary Machine Learning algorithms.</strong> Our Innovation Lab continually refines these algorithms, ensuring the highest level of accuracy and relevance. Our team of data scientists and engineers collaborate to extract meaningful insights, turning complex patterns into actionable intelligence.',
  },
  {
    id: 3,
    title: 'Fusion Enhanced Catalog',
    description:
      "Leveraging our wealth of available research and datasets, <strong>we've constructed a comprehensive data catalog. Our Fusion Lab utilizes this catalog to detect anomalies, threats, and unexpected events, providing you with timely alerts and actionable insights.</strong> This unique approach to data fusion enables the cross-correlation of data from various sources, enhancing the accuracy of threat detection, risk assessment, and decision-making processes.",
  },
  {
    id: 4,
    title: 'Easy Access to Information You Need',
    description:
      "<strong>Powered by sophisticated Large Language Models, our technology provides context-specific results, delivering exactly the information you need via API integration or by generating ready-to-present reports.</strong> Whether it's identifying the risk of ground movement, assessing the health of vegetation, or providing persistent monitoring, you'll gain a comprehensive understanding of your on-the-ground situation.",
  },
  {
    id: 5,
    title: 'A Powerful Investigation Tool',
    description:
      "Harness the <strong>power of geospatial intelligence with our TerraEye app.</strong> View real-time data streams, analyze historical patterns, and predict future scenarios all within our  intuitive, comprehensive application. Whether you're monitoring environmental impacts or planning future investments, <strong>TerraEye app empowers you with the critical data and insights you need.</strong>",
  },
];
