import styled, { css } from 'styled-components';

type SubPageImageProps = {
  fixed?: boolean;
  desktopUrl: string;
  tabletUrl: string;
};

export const SubPageImage = styled.div<SubPageImageProps>`
  ${(props) =>
    props &&
    css`
      height: 100%;
      background: center / cover no-repeat url(${props.desktopUrl});

      ${() =>
        props.fixed &&
        css`
          background: url(${props.desktopUrl});
          background-attachment: fixed;
          background-size: auto;
          background-position: left;
        `}

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        background: center / cover no-repeat url(${props.tabletUrl});
        position: fixed;
        height: 230px;
        inset: 0;
      }
    `}
`;
