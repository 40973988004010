import { useEffect, useState } from 'react';

import { ReactComponent as SquareIcon } from 'assets/svg/square-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash-icon.svg';
import type { PointArray, Polygon, Position, ScaleHeight, ScaleLabel, Status } from 'types';
import { BaseMap } from 'ui/atoms/baseMap';
import { MAP_SAMPLE_CENTER, MAP_SAMPLE_ZOOM } from 'ui/atoms/baseMap/constants';
import { Wrapper } from 'ui/atoms/wrapper';
import { CustomMapScaleService } from 'ui/molecules/customMapScale/customMapScaleService';
import { MapControlButton } from 'ui/molecules/mapControlButton';
import { MapToolbox } from 'ui/molecules/mapToolbox';
import { SelectionBox } from 'ui/organisms/areaSelector/SelectionBox';
import { GeocodeSearch } from '../geocodeSearch';
import { createPolygonFromPoints } from './helpers';
import { ViewUpdater } from './viewUpdater';

interface AreaSelectorCoreProps {
  setPolygonAttachment: React.Dispatch<React.SetStateAction<Polygon | null>>;
  status: Status;
}

// eslint-disable-next-line no-empty-pattern
export const AreaSelector = ({ setPolygonAttachment, status }: AreaSelectorCoreProps) => {
  const [zoom, setZoom] = useState<number>(MAP_SAMPLE_ZOOM);
  const [scaleHeight, setScaleHeight] = useState<ScaleHeight | undefined>();
  const [scaleLabel, setScaleLabel] = useState<ScaleLabel | undefined>();
  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [selectionPoints, setSelectionPoints] = useState<PointArray[]>([]);
  const [position, setPosition] = useState<Position | undefined>();

  const toggleSelectionMode = () => setSelectionMode((selectionMode) => !selectionMode);

  useEffect(() => {
    if (!selectionMode) {
      setSelectionPoints([]);
    }
  }, [selectionMode]);

  useEffect(() => {
    const polygon = selectionPoints.length === 2 ? createPolygonFromPoints(selectionPoints) : null;

    setPolygonAttachment(polygon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionPoints]);

  return (
    <>
      <BaseMap
        windowed
        height={350}
        variant="DARK"
        center={MAP_SAMPLE_CENTER}
        zoom={zoom}
        selectionMode={selectionMode}
      >
        <CustomMapScaleService setScaleHeight={setScaleHeight} setScaleLabel={setScaleLabel} />
        <SelectionBox
          status={status}
          enabled={selectionMode}
          selectionPoints={selectionPoints}
          setSelectionPoints={setSelectionPoints}
        />
        {position && (
          <ViewUpdater
            defaultView={{ lat: position.lat, lon: position.lon, zoom: 10 }}
            animate={true}
            cleanupAction={() => setPosition(undefined)}
          />
        )}
      </BaseMap>
      <Wrapper variant="RELATIVE" top={-340} left={-16}>
        <Wrapper variant="ABSOLUTE" left={25} zIndex={1002}>
          <GeocodeSearch setPosition={setPosition} />
        </Wrapper>
        <MapToolbox
          toolboxData={{
            scaleHeight: scaleHeight,
            scaleLabel: scaleLabel,
            isReportCreatorActive: false,
            zoom: zoom,
            setZoom: setZoom,
            alignMap: () => {},
          }}
        >
          <MapControlButton icon={<SquareIcon />} iconWidth={12} active={selectionMode} onClick={toggleSelectionMode} />
          <MapControlButton
            icon={<TrashIcon />}
            iconWidth={12}
            disabled={selectionPoints.length < 2}
            onClick={() => setSelectionPoints([])}
          />
        </MapToolbox>
      </Wrapper>
    </>
  );
};
