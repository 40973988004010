import styled from 'styled-components';

export const StyledPartnership = styled.section`
  background-color: ${({ theme }) => theme.colors.codGray};
  padding: 88px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 64px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 50px 0;
  }
`;

export const DigitalTwinPartnershipSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 30px;
  }
`;

export const DigitalTwinPartnershipList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 78px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    gap: 45px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 15px;
  }
`;

export const ImageWrapper = styled.li`
  img {
    height: auto;
    min-width: 60px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      width: 85%;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      width: 65%;
    }
  }
`;
