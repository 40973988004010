import styled from 'styled-components';

import { SubPageImage } from 'components/SubPageImage';

export const Hype4ExplorImage = styled(SubPageImage)`
  background-position: 75% 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-position: 50% 58%;
  }
`;
