import styled from 'styled-components';

import { ReactComponent as CaseStudyImage } from 'assets/images/caseStudies/case-studies-icon.svg';

export const CaseStudyIcon = styled(CaseStudyImage)`
  display: block;
  color: ${({ theme }) => theme.colors.copper};
  flex-shrink: 0;
  object-fit: cover;
  transition: color 450ms ease-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 30px;
    height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 42px;
    height: 55px;
  }
`;

export const StyledCaseStudyItem = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.copper};
  padding: 19px;
  max-width: 305px;
  min-height: 122px;
  height: 100%;
  transition: background-color 350ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.copper};

    ${CaseStudyIcon} {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 213px;
    padding: 12px;
    min-height: 90px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    max-width: 100%;
    padding: 14px;
    min-height: 122px;
  }
`;

export const CaseStudyItemContent = styled.article`
  display: flex;
  gap: 20px;
`;

export const CaseStudyItemHeading = styled.h2`
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    font-size: 13px;
  }

  span {
    display: block;
  }
`;

export const CaseStudyItemFileInfo = styled.div`
  margin: 12px 0 0;
  font-size: 12px;
  line-height: normal;
  display: flex;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 10px 0 0;
    font-size: 9px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    margin: 12px 0 0;
    font-size: 12px;
  }
`;
