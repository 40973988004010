import styled from 'styled-components';

import { ROUTES } from 'common/routes';
import { CASE_STUDIES_SECTION_ID } from 'common/constants';
import { caseStudiesData } from 'utils/caseStudies';
import { BaseButton } from 'ui/atoms/button';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { StyledHashLink } from 'components/Navigation/NavigationList/styled';
import CaseStudy from './CaseStudy';
import { CaseStudiesList } from './styled';

const CaseStudies = ({ shortSection }: { shortSection?: boolean }) => {
  const data = caseStudiesData.slice(0, shortSection ? 3 : caseStudiesData.length);

  return (
    <Section dark={!shortSection} id={CASE_STUDIES_SECTION_ID}>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Case Studies & Other Examples"
        description="See how TerraEye can give you an advantage within your industry."
        isBackgroundLight={!!shortSection}
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <CaseStudiesList>
            {data.map(
              ({
                id,
                link,
                homepage: { title, description, image },
                subpage: {
                  file: { fileType },
                },
              }) => (
                <li key={id}>
                  <CaseStudy
                    homepageTitle={title}
                    homepageDescription={description}
                    image={image}
                    fileType={fileType}
                    id={id}
                    link={link}
                    altColor={!!shortSection}
                  />
                </li>
              ),
            )}
          </CaseStudiesList>
          {shortSection && (
            <ButtonWrapper>
              <BaseButton as={undefined} target="_blank" rel="noopener noreferrer" variant="SECONDARY_COLOR">
                <StyledHashLink to={`${ROUTES.data}#${CASE_STUDIES_SECTION_ID}`}>see more case studies</StyledHashLink>
              </BaseButton>
            </ButtonWrapper>
          )}
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const ButtonWrapper = styled.div`
  width: 270px;
  margin-top: 70px;

  > div > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.rawSienna};

    &:hover {
      color: ${({ theme }) => theme.colors.rawSienna};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 100%;

    > div > a {
      font-size: 12px;
    }
  }
`;

export default CaseStudies;
