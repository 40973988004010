import { Status } from 'types';

export const getStatusMessagesHelper = (status: Status) => {
  const messagesRecord: Record<Status, string> = {
    idle: '',
    pending: 'Processing...',
    success: 'You have successfully unsubscribed from TerraEye newsletter emails.',
    error: 'Something went wrong. Check your internet connection and try again.',
  };

  return messagesRecord[status];
};
