import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import {
  RequestAccessFooterDetails,
  RequestAccessFooterInfo,
  RequestAccessFooterLogo,
  RequestAccessFooterMessages,
  StyledRequestAccessFooter,
} from './styled';
import rsbsLogo from 'assets/images/global/rsbs-logo.svg';
import rsbsLogoText from 'assets/images/global/rsbs-logo-text.svg';
import { requestAccessCompanyData } from './requestAccessCompanyData';

const RequestAccessFooter = () => {
  const { address, details, messages } = requestAccessCompanyData;

  return (
    <StyledRequestAccessFooter>
      <PaddingWrapper size="large" type="right">
        <Container align="start" size="small">
          <PaddingWrapper size="large" type="left">
            <RequestAccessFooterLogo>
              <img src={rsbsLogo} alt="" />
              <img src={rsbsLogoText} alt="Remote Sensing Business Solutions" />
            </RequestAccessFooterLogo>
            <RequestAccessFooterInfo>
              <RequestAccessFooterDetails>
                <address>
                  <ul>
                    {address.map(({ id, content }) => (
                      <li key={id}>{content}</li>
                    ))}
                  </ul>
                </address>
                <article>
                  <h2>Company details</h2>
                  <ul>
                    {details.map(({ id, content }) => (
                      <li key={id}>{content}</li>
                    ))}
                  </ul>
                </article>
              </RequestAccessFooterDetails>
              <RequestAccessFooterMessages>
                <ul>
                  {messages.map(({ id, content }) => (
                    <li key={id}>
                      <p>{content}</p>
                    </li>
                  ))}
                </ul>
              </RequestAccessFooterMessages>
            </RequestAccessFooterInfo>
          </PaddingWrapper>
        </Container>
      </PaddingWrapper>
    </StyledRequestAccessFooter>
  );
};

export default RequestAccessFooter;
