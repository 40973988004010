import { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { getScaleDistance, getScaleLabel } from 'utils/index';
import { APP_SCALE_MAX_HEIGHT, EXTERNAL_SCALE_MAX_HEIGHT } from 'common/constants';
import { type ScaleHeight, type ScaleLabel } from 'types';

interface CustomMapScaleServiceProps {
  setScaleLabel: (scaleLabel: ScaleLabel) => void;
  setScaleHeight: (scaleHeight: ScaleHeight) => void;
}

export const CustomMapScaleService = ({ setScaleLabel, setScaleHeight }: CustomMapScaleServiceProps) => {
  const map = useMap();

  const getMapDistance = useCallback(
    (scaleMaxHeight: number) =>
      map
        .containerPointToLatLng([0, map.getSize().y / 2])
        .distanceTo(map.containerPointToLatLng([0, map.getSize().y / 2 + scaleMaxHeight])),
    [map],
  );

  useEffect(() => {
    map.on('moveend', () => {
      const mapDistance = getMapDistance(APP_SCALE_MAX_HEIGHT);
      const mapDistanceExternalScale = getMapDistance(EXTERNAL_SCALE_MAX_HEIGHT);

      const appScaleDistance = getScaleDistance(mapDistance);
      const externalScaleDistance = getScaleDistance(mapDistanceExternalScale);

      const appScaleLabel = getScaleLabel(appScaleDistance);
      const externalScaleLabel = getScaleLabel(externalScaleDistance);

      const appScaleHeight = Math.round((appScaleDistance * APP_SCALE_MAX_HEIGHT) / mapDistance);
      const externalScaleHeight = Math.round(
        (externalScaleDistance * EXTERNAL_SCALE_MAX_HEIGHT) / mapDistanceExternalScale,
      );

      setScaleLabel({ app: appScaleLabel, external: externalScaleLabel });
      setScaleHeight({ app: appScaleHeight, external: externalScaleHeight });
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
