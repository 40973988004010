import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LIGHT_SECTIONS_ID } from 'common/constants';

export const useLightSectionsInView = () => {
  const [isLightSectionsInView, setIsLightSectionsInView] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsLightSectionsInView(false);

      const lightSectionsList = document.querySelectorAll<HTMLDivElement>(`#${LIGHT_SECTIONS_ID}`);

      lightSectionsList.forEach((lightSections) => {
        if (lightSections) {
          const rect = lightSections.getBoundingClientRect();

          if ((rect.top <= 20 && rect.bottom >= 50)) {
            setIsLightSectionsInView(true);
          }
        }
      })
    };

    handleScroll();

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [isLightSectionsInView, setIsLightSectionsInView]);

  useEffect(() => {
    setIsLightSectionsInView(false);
  }, [pathname, setIsLightSectionsInView]);

  return { isLightSectionsInView };
};
