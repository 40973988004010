import {
  CarouselItemImage,
  StyledCarouselItem,
  CarouselItemTitle,
  CarouselItemDescription,
  CarouselItemHeading,
  CarouselItemLine,
  CarouselItemImageContainer,
} from './styled';

type CarouselItemProps = {
  image: string;
  title: string;
  heading: string;
  description: string;
};

const CarouselItem = ({ image, title, heading, description }: CarouselItemProps) => {
  return (
    <StyledCarouselItem>
      <CarouselItemImageContainer>
        <CarouselItemImage src={require(`assets/images/${image}`)} alt="" />
      </CarouselItemImageContainer>
      <CarouselItemTitle>{title}</CarouselItemTitle>
      <CarouselItemLine />
      <CarouselItemHeading>{heading}</CarouselItemHeading>
      <CarouselItemDescription>{description}</CarouselItemDescription>
    </StyledCarouselItem>
  );
};

export default CarouselItem;
