import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Table } from 'components/Table';
import { pricingOptionsTableHeaders, pricingOptionsTableRows } from './pricingOptionsTable';

export const PricingOptionsSection = () => {
  return (
    <Section light>
      <SectionHeader variant="SECONDARY_H2" title="Report Pricing Options" isBackgroundLight />
      <PaddingWrapper size="large" type="inline" isXS>
        <Container align="center" size="medium">
          <Table headers={pricingOptionsTableHeaders} rows={pricingOptionsTableRows} />
        </Container>
      </PaddingWrapper>
    </Section>
  );
};
