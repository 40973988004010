import styled from 'styled-components';

export const VideoWrapper = styled.div`
  position: relative;
`;

export const StyledVideo = styled.video`
  display: block;
  max-width: 548px;
`;

export const VideoTitle = styled.img`
  display: block;
  position: absolute;
  left: 0;
  top: -24px;
`;

export const VideoSideIcon = styled.img`
  display: block;
  position: absolute;
  right: -42px;
  top: 50%;
  transform: translateY(-50%);
`;
