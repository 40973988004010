export const digitalTwinPartnershipData = [
  {
    id: 1,
    image: 'digitalTwinPartnership/monumental-energy.svg',
    alt: 'Monumental Energy',
  },
  {
    id: 2,
    image: 'digitalTwinPartnership/feasib.svg',
    alt: 'Feasib',
  },
  {
    id: 3,
    image: 'digitalTwinPartnership/trigteq.svg',
    alt: 'Trigteq',
  },
];
