import { ROUTES } from 'common/routes';
import { ArticlesList } from 'components/Article/styled';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import LegalPageLayout from 'components/LegalContentWrapper';
import { ArticleOrderedList, ArticleOrderedSublist } from 'components/Article/styled';
import { TextLink } from 'ui/atoms/textLink';
import { StyledTextLink } from 'ui/atoms/textLink/TextLink';
import PageHelmetWrapper from 'components/PageHelmetWrapper';

const TermsAndConditionsGDP = () => {
  return (
    <PageHelmetWrapper page="termsAndConditionsDgp">
      <LegalPageLayout
        title={
          <span>
            Terms & Conditions of
            <br />
            Digital Goods Provision
          </span>
        }
      >
        <ArticlesList isNarrow>
          <ArticleWrapper title="§ 1. General provisions">
            <ArticleOrderedList>
              <li>
                These Terms and Conditions (hereinafter: "Terms and Conditions"), set out the terms and conditions for
                the provision of Digital Goods through the application "TerraEYE" operating at the Internet address
                terraeye.co (hereinafter: "App").
              </li>
              <li>
                The Terms and Conditions constitute the terms and conditions referred to in Article 8 of the Act of 18
                July 2002 on Providing Services by Electronic Means (hereinafter: "Electronic Services Act").
              </li>
              <li>
                The Provider of Digital Goods is Remote Sensing Business Solutions Sp. Z O. O., a simple joint stock company
                incorporated and registered in Poland whose registered office is located at Jana Dlugosza str. 60A,
                51-162 Wroclaw, Poland, registered in the Polish Court Registry under no. 0000970039, NIP: 8952241235,
                (the “Customer”), with a share capital of 723 403 zł paid in full (hereinafter: “Provider”).
              </li>
              <li>
                The Provider may be contacted by:
                <ArticleOrderedSublist>
                  <li>e-mail at the following address: office@fourpoint.space;</li>
                  <li>postal service - at the address: Jana Długosza 60a, 51-162, Wrocław, Poland.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                During its activities, the Provider:
                <ArticleOrderedSublist>
                  <li>provides Digital Goods to Recipients;</li>
                  <li>provides Newsletter to Subscribers.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Information on the Digital Goods available in the App, in particular its descriptions, technical and
                usage parameters, constitutes an invitation to conclude a contract within the meaning of Article 71 of
                the Act of 23 April 1964 Civil Code (hereinafter: "Civil Code").
              </li>
              <li>
                Before using the App, the Customer is obliged to read the Terms and Conditions and Privacy Policy.
              </li>
              <li>
                To matters not regulated by the present Terms and Conditions, the provisions of the Terms and Conditions
                of the App, available at:{' '}
                <TextLink to={ROUTES.termsAndConditions}>https://terraeye.co{ROUTES.termsAndConditions}</TextLink>{' '}
                (hereinafter: "App Terms and Conditions").
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 2. Definitions">
            Capitalised words used in these Terms and Conditions shall have the following meanings:
            <ArticleOrderedList>
              <li>Agreement - the Agreement on Digital Goods Provision or the Newsletter Subscription Agreement;</li>
              <li>
                Agreement on Digital Goods Provision - an agreement on the provision of digital Goods within the meaning
                of the Consumer Rights Act under which the Provider undertakes to deliver Digital Goods to the
                Subscriber, and the Subscriber undertakes to conclude a Newsletter Subscription Agreement with the
                Provider or pay the price;
              </li>
              <li>App Terms and Conditions - term defined in § 1 Section 8 hereof;</li>
              <li>
                Business Day - a day that is not a Saturday, Sunday or any other public holiday within the meaning of
                the Act on Public Holidays of 18 January 1951;
              </li>
              <li>Civil Code - the term defined in § 1 Section 6 hereof;</li>
              <li>
                Consumer - a natural person making a legal transaction with the Provider which is not directly related
                to his/her economic or professional activity;
              </li>
              <li>Consumer Rights Act - the Act of 30 May 2014 on consumer rights;</li>
              <li>Customer - the Recipient or the Subscriber;</li>
              <li>Digital Goods - Digital Goods or Newsletter;</li>
              <li>
                Digital Goods - digital content within the meaning of the Consumer Rights Act that can be delivered to
                the Recipient, in particular an electronic book (e-book), audio recording, audiovisual recording,
                graphics;
              </li>
              <li>Electronic Services Act - the term defined in § 1(2) hereof.</li>
              <li>
                Entrepreneur - a natural person, a legal person or an organisational unit without legal personality to
                which specific provisions grant legal capacity, conducting business or professional activity in its own
                name;
              </li>
              <li>
                Entrepreneur with Consumer Rights - a natural person conducting a business or professional activity in
                his/her own name who has entered into an Agreement with the Provider directly related to his/her
                business activity, but which is not of a professional nature for this person, arising in particular from
                the subject of his/her business activity;
              </li>
              <li>Licence - term defined in § 7 Section 1 hereof;</li>
              <li>
                Newsletter - digital content within the meaning of the provisions of the Consumer Rights Act, including
                commercial information regarding the current activities of the Provider (including information on new
                products and promotions available in the App);
              </li>
              <li>
                Newsletter Subscription Agreement - an agreement for the supply of digital content within the meaning of
                the Consumer Rights Act under which the Provider undertakes to provide the Subscriber with a Newsletter
                free of charge for an indefinite period of time, and the Subscriber undertakes to provide the Provider
                with personal data;
              </li>
              <li>
                Non-Compliance - shall be understood as non-compliance of the Digital Goods with the Agreement on its
                delivery (the criteria for assessing the compliance of the Digital Goods with the Agreement on its
                delivery are specified in Article 43k (1-2) of the Consumer Rights Act);
              </li>
              <li>
                Privacy Policy - a document containing information about the processing of Customers' personal data by
                the Provider;
              </li>
              <li>Provider - the term defined in § 1 Section 3 hereof;</li>
              <li>
                Recipient - a person who is a Consumer, Entrepreneur or Entrepreneur with Consumer Rights, who has
                concluded an Agreement on Digital Goods Provision with the Provider or has taken steps to conclude such
                an Agreement;
              </li>
              <li>
                Subscriber - a person who is a Consumer, Entrepreneur or Entrepreneur with Consumer Rights who has
                entered into a Newsletter Subscription Agreement with the Provider or has taken steps to enter into such
                an Agreement;
              </li>
              <li>Terms and Conditions - the term defined in § 1 Section 1 hereof;</li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 3. Technical requirements">
            <ArticleOrderedList>
              <li>
                For proper use of the App by Customers, all the following requirements must be satisfied jointly:
                <ArticleOrderedSublist>
                  <li>Internet connection;</li>
                  <li>a device(s) enabling the use of Internet resources;</li>
                  <li>
                    a web browser capable of displaying hypertext documents on the device's screen, linked to the
                    Internet via the World Wide Web service and supporting the JavaScript programming language, which
                    moreover accepts cookies;
                  </li>
                  <li>an active e-mail account.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Within the App, it is prohibited for Customers to use viruses, bots, worms or other computer codes,
                files or programmes (in particular process automation scripts and applications or other codes, files or
                tools).
              </li>
              <li>
                The Provider informs that it uses cryptographic protection of electronic transfer and digital content
                using appropriate logical, organisational and technical measures, in particular to prevent third-party
                access to data, including by SSL encryption, use of passwords and anti-virus or anti-malware programmes.
              </li>
              <li>
                The Provider advises that despite the application of safeguards referred to in Section 3 above, the use
                of the Internet and services provided electronically may be at risk of malicious software entering the
                Customer's computer system and device or of third parties gaining access to the data on that device. In
                order to minimise the aforementioned risk, the Provider recommends the use of anti-virus programmes or
                means of protecting identification on the Internet.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 4. App terms of use">
            <ArticleOrderedList>
              <li>
                The Customer is obliged to use the App in a manner consistent with generally applicable laws, provisions
                of the Terms and Conditions, as well as with good morals.
              </li>
              <li>The provision of unlawful content by the Customer is prohibited.</li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 5. Newsletter Subscription Agreement">
            <ArticleOrderedList>
              <li>
                In order to conclude the Newsletter Subscription Agreement, the Subscriber shall provide the Provider
                with his/her email address and submit a statement that he/she consents to receive the Newsletter, that
                he/she has read the Terms and Conditions and Privacy Policy and accepts their provisions.
              </li>
              <li>
                The actions indicated in Section 1 above may be performed in any way, in particular the Subscriber may
                fill in the electronic form available in the App.
              </li>
              <li>The Newsletter Subscription Agreement shall be concluded for an indefinite period of time.</li>
              <li>
                The Provider informs and the Subscriber acknowledges that:
                <ArticleOrderedSublist>
                  <li>the delivered Newsletter is not subject to subsequent update;</li>
                  <li>
                    the Newsletter delivery frequency and dates are not predetermined and depend on the current
                    situation of the Provider.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>The Newsletter shall be delivered by email to the email address provided by the Subscriber.</li>
              <li>
                The Subscriber may terminate the Newsletter Subscription Agreement with immediate effect at any time and
                without stating any reason. In addition, pursuant to Article 27 et seq. of the Consumer Rights Act, the
                Subscriber who is a Consumer or Entrepreneur with Consumer Rights may terminate the Newsletter
                Subscription Agreement without stating reasons within 14 (fourteen) days from its conclusion.
              </li>
              <li>
                Rescission of the Newsletter Subscription Agreement or its termination, regardless of the grounds for
                such action, shall require a relevant statement to be submitted by the Subscriber to the Provider. The
                statement referred to in the preceding sentence may be made by:
                <ArticleOrderedSublist>
                  <li>
                    clicking by the Subscriber on the link enabling cancellation of the Newsletter subscription service,
                    which is sent together with each Newsletter;
                  </li>
                  <li>
                    the Subscriber sending to the Provider a statement on rescission of the Newsletter Subscription
                    Agreement or its termination by email. The statement referred to in this point 2 may also be
                    submitted using the form constituting Appendix No. 2 to the Act on Consumer Rights.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Provider shall cease the delivery of the Newsletter to the Subscriber immediately after the
                Subscriber has performed one of the actions indicated in Section 7 above.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 6. Agreement on Digital Goods Provision">
            <ArticleOrderedList>
              <li>
                The Recipient may receive Digital Goods available in the App:
                <ArticleOrderedSublist>
                  <li>free of charge - subject to the conclusion of a Newsletter Subscription Agreement;</li>
                  <li>for a fee - if Newsletter Subscription Agreement is not concluded.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                If the Recipient wishes to receive the Digital Goods free of charge, they should perform the following
                steps:
                <ArticleOrderedSublist>
                  <li>go to the App's website;</li>
                  <li>go to the tab of the Digital Goods selected;</li>
                  <li>enter their name and e-mail address in the form that appears;</li>
                  <li>
                    obligatorily tick the checkbox next to the statement confirming their consent to receive the
                    Newsletter, the fact of having read the Terms and Conditions and Privacy Policy and acceptance of
                    the provisions thereof;
                  </li>
                  <li>confirm the consent to receive the Newsletter.</li>
                </ArticleOrderedSublist>
              </li>
              <li>Confirmation of consent to receive the Newsletter is equivalent to the Recipient entering into:</li>
              <li>Agreement on Digital Goods Provision, and</li>
              <li>Newsletter Subscription Agreement.</li>
              <li>
                The provisions of § 5 hereof shall apply to the Newsletter Subscription Agreement concluded pursuant to
                the provisions of this § 6.
              </li>
              <li>
                The termination of the Newsletter Subscription Agreement or rescission thereof after the delivery of the
                Digital Goods shall not affect the validity and effectiveness of the Agreement on Digital Goods
                concluded in accordance with the provisions of this § 6.
              </li>
              <li>
                If the Recipient wishes to receive the Digital Goods for a fee, the they should contact the Provider by
                e-mail at the address indicated in § 1, Section 4, Point 1 of the Terms and Conditions in order to
                individually agree on the terms and conditions of the Agreement on Digital Goods Provision.
              </li>
              <li>
                The Digital Goods shall be delivered to the Recipient immediately after the conclusion of the Agreement
                on Digital Goods Provision.
              </li>
              <li>
                The Provider shall deliver the Digital Goods to the Recipient by e-mail to the e-mail address provided
                by the Recipient.
              </li>
              <li>
                The Provider advises and the Recipient acknowledges that the Digital Goods are not subject to update.
              </li>
              <li>
                Should the Digital Goods not be delivered within the period indicated in Section 7 above, the Recipient
                shall call upon the Provider to deliver the Digital Goods. The call referred to in the preceding
                sentence may be sent by e-mail to the address indicated in § 1 Section 4 Point 1 hereof. Should the
                Provider fail to deliver the Digital Goods immediately upon receipt of the request or within an
                additional period of time expressly agreed upon with the Recipient, the Recipient may rescind the
                Agreement on Digital Goods Provision.
              </li>
              <li>
                Rescission of the Agreement on Digital Goods Provision shall require a statement of rescission to be
                submitted by the Recipient to the Provider. The statement referred to in the preceding sentence may be
                sent by e-mail to the address indicated in § 1 Section 4 Point 1 hereof.
              </li>
              <li>
                Where the rescission relates to an Agreement on Digital Goods Provision for a fee, the Provider shall
                refund the price of the Digital Goods paid by the Recipient immediately, but no later than within 14
                (fourteen) days of receipt of the Recipient's statement on rescission of the Agreement on Digital Goods
                Provision. The price shall be reimbursed using the same method of payment as used by the Recipient,
                unless the Recipient has expressly agreed to a different method of reimbursement that does not entail
                any costs for them.
              </li>
              <li>
                The provisions of Sections 10-12 above shall apply only to Recipients who are Consumers or Entrepreneurs
                with Consumer Rights.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 7. Licence">
            <ArticleOrderedList>
              <li>
                Upon delivery of the Digital Goods to the Recipient, the Provider shall grant the Recipient a
                non-exclusive licence to use such Digital Goods on the terms and conditions set out herein (hereinafter:
                the "Licence").
              </li>
              <li>The licence is granted for an indefinite period of time.</li>
              <li>
                The licence entitles the Recipient to use the Digital Goods exclusively in the following fields of
                exploitation:
                <ArticleOrderedSublist>
                  <li>storage in the memory of electronic devices owned by the Recipient;</li>
                  <li>playing and viewing the Digital Goods;</li>
                  <li>
                    reproduction of the Digital Goods, but only to the extent justified by the Recipient's personal
                    needs.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Recipient may use the knowledge and instructions contained in the Digital Goods in their private and
                professional life and for the purposes of their business activities. However, the licence does not
                authorise the Recipient to share the Digital Goods with any other persons, with the exception of cases
                of sharing the Digital Goods on the basis of the provisions of Title 3 of the Act of 4 February 1994 on
                copyright and related rights on the allowed use of protected works. Making the Digital Goods available
                to any other persons except in cases of permitted use of protected works requires a prior consent of the
                Provider.
              </li>
              <li>The Licence does not entitle the Recipient to grant further licences.</li>
              <li>
                The Recipient's use of the Digital Goods in violation of the terms of the Licence constitutes an
                infringement of the Provider's copyrights, entitling the Provider (depending on the nature of the
                infringement) to file claims against the Recipient under court proceedings.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 8. Complaints regarding Digital Goods">
            <ArticleOrderedList>
              <li>
                The provisions of this § 8 apply exclusively to:
                <ArticleOrderedSublist>
                  <li>Customers who are Consumers or Entrepreneurs with Consumer rights;</li>
                  <li>
                    Non-Compliance of the Digital Goods with the Agreement on Digital Goods Provision and Non-
                    Compliance of the Newsletter with the Newsletter Subscription Agreement.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Digital Goods supplied by the Provider to the Customer must comply with the Agreement for the supply
                thereof:
                <ArticleOrderedSublist>
                  <li>at the time of delivery - where the Digital Goods is delivered once or in parts;</li>
                  <li>
                    throughout the period of delivery of the Digital Goods in question - where the Digital Goods is
                    delivered on a continuous basis.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Provider shall be liable for Non-Compliance:
                <ArticleOrderedSublist>
                  <li>
                    existing at the time of delivery of the Digital Goods and disclosed within 2 (two) years from that
                    time - where the Digital Goods is delivered once or in parts;
                  </li>
                  <li>
                    disclosed during the period of delivery of the Digital Goods - where the Digital Goods is delivered
                    on a continuous basis.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                In the event of disclosure of Non-Compliance, the Customer may submit a complaint with a demand to bring
                the Digital Goods into compliance with the Agreement for the provision thereof.
              </li>
              <li>
                The complaint shall be submitted by e-mail to the address indicated in § 1 Section 4 Point 1 hereof.
              </li>
              <li>
                The complaint should include:
                <ArticleOrderedSublist>
                  <li>the name and surname of the Customer;</li>
                  <li>e-mail address;</li>
                  <li>a description of the Non-Compliance disclosed;</li>
                  <li>
                    a request to bring the Digital Goods into conformity with the Agreement for the provision thereof.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Provider may refuse to bring the Digital Goods into compliance with the Agreement for its provision
                if this is impossible or would require the Provider to incur excessive costs.
              </li>
              <li>
                After considering the complaint, the Provider shall provide the Customer with a response to the
                complaint in which the Provider:
                <ArticleOrderedSublist>
                  <li>
                    acknowledges the complaint and indicates the planned date for bringing the Digital Goods into
                    compliance with the Agreement for the provision thereof;
                  </li>
                  <li>
                    refuses to bring the Digital Goods into compliance with the Agreement for the provision thereof for
                    the reasons indicated in Section 7 above;
                  </li>
                  <li>rejects the complaint on the grounds that it is unsubstantiated.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Provider shall respond to the complaint by email within 14 (fourteen) days of receiving the
                complaint.
              </li>
              <li>
                If the complaint is accepted, the Provider shall, at its own expense, bring the Digital Goods into
                conformity with the Agreement for the provision thereof within a reasonable time from the date of
                receiving the complaint and without undue inconvenience to the Customer, taking into account the nature
                of the Digital Goods and the purpose for which it is used. The planned deadline for bringing the Digital
                Goods into compliance with the Agreement for the provision thereof shall be indicated by the Provider in
                its response to the complaint.
              </li>
              <li>
                If a Non-Compliance is disclosed, subject to Section 14 below, the Customer may submit to the Provider a
                statement of price reduction or rescission of the Agreement when:
                <ArticleOrderedSublist>
                  <li>
                    it is either impossible to bring the Digital Goods into conformity with the Agreement for the
                    provision thereof or excessive costs would have to be incurred;
                  </li>
                  <li>
                    the Provider has failed to bring the Digital Goods into conformity with the Agreement for the
                    provision thereof in accordance with Section 10 above;
                  </li>
                  <li>
                    the Non-Compliance continues even though the Provider has attempted to bring the Digital Goods into
                    compliance with the Agreement for the provision thereof;
                  </li>
                  <li>
                    the Non-Compliance is so significant as to justify rescission of the Agreement on the Provision of
                    the Digital Goods in question without first requiring the Provider to bring the Digital Goods into
                    compliance with the Agreement for the Provision thereof;
                  </li>
                  <li>
                    it is clear from the Provider's statement or circumstances that the Provider will not bring the
                    Digital Goods into conformity with the Agreement for the Provision thereof within a reasonable time
                    or without undue inconvenience to the Customer.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The statement of price reduction or rescission of the Agreement may be made by e-mail to the address
                indicated in § 1 Section 4 Point 1 hereof.
              </li>
              <li>
                The statement of price reduction or rescission of the Agreement should include:
                <ArticleOrderedSublist>
                  <li>the Customer's name and surname;</li>
                  <li>e-mail address;</li>
                  <li>date of delivery of the Digital Goods;</li>
                  <li>description of the Non-Compliance disclosed;</li>
                  <li>
                    indication of the reason for the statement, selected from the reasons indicated in Section 11 above;
                  </li>
                  <li>
                    a statement of price reduction, with an indication of the reduced price, or a statement of
                    rescission of the Agreement.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Customer shall not be entitled to submit a price reduction statement to the Provider in the event of
                Non-Compliance of the Newsletter or Digital Goods supplied free of charge.
              </li>
              <li>
                The Provider shall not be entitled to claim payment for the time during which the Digital Goods was not
                in conformity with the Agreement on Provision thereof, even if the Customer used the Digital Goods
                before rescinding the Agreement on Provision thereof.
              </li>
              <li>
                The reduced price must be in such proportion to the price under the Agreement on Digital Goods Provision
                as the value of the Digital Goods not complying with the Agreement for the provision thereof remains to
                the value of the Digital Goods complying with the Agreement for the provision thereof.
              </li>
              <li>
                The Provider shall be obliged to refund the price of the Digital Goods only for the part corresponding
                to the part of the Digital Goods which does not comply with the Agreement for its provision and the part
                of the Digital Goods in respect of which the obligation to provide has ceased due to the rescission of
                the Agreement for its provision.
              </li>
              <li>
                The Provider shall return the amounts due to the Customer as a result of exercising the right to reduce
                the price immediately or the right to rescind the Agreement, no later than within 14 (fourteen) days of
                receiving the price reduction statement. The reimbursement of the amounts referred to in the preceding
                sentence shall be made using the same method of payment as used by the Customer, unless the Customer has
                expressly agreed to a different method of reimbursement that does not entail any costs for them.
              </li>
              <li>
                The Customer is not entitled to rescind the Agreement on Digital Goods Provision if the NonCompliance is
                immaterial.
              </li>
              <li>
                In the event that the Subscriber rescinds the Newsletter Subscription Agreement, the Provider shall
                cease to provide the Newsletter immediately upon receipt of the statement on rescission of the
                Newsletter Subscription Agreement.
              </li>
              <li>
                Pursuant to Article 34 Section 1a of the Consumer Rights Act, in the event that the Customer rescinds
                the Agreement Digital Goods Provision, the Customer shall be obliged to cease using such Digital Goods
                and making it available to third parties.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 9. Intellectual property of the Provider">
            <ArticleOrderedList>
              <li>
                All components of the App, in particular:
                <ArticleOrderedSublist>
                  <li>the name of the App;</li>
                  <li>the logo of the App;</li>
                  <li>photographs and descriptions of Digital Goods;</li>
                  <li>
                    operation principles of the App's website, all its graphic elements, interface, software, source
                    code and databases
                    <br />
                    <br />- are subject to legal protection under the provisions of the Act of 4 February 1994 on
                    Copyright and Related Rights, the Act of 30 June 2000 - Industrial Property Law, the Act of 16 April
                    1993 on Combating Unfair Competition and other generally applicable laws, including European Union
                    law.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Any use of the Provider's intellectual property without authorisation under these Terms and Conditions
                or prior express permission of the Provider is prohibited.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 10. Personal data processing">
            For information about the Provider's processing of personal data, please refer to the Privacy Policy
            available at: <TextLink to={ROUTES.privacyPolicy}>https://terraeye.co{ROUTES.privacyPolicy}</TextLink>.
          </ArticleWrapper>

          <ArticleWrapper title="§ 11. Out-of-court dispute resolution">
            <ArticleOrderedList>
              <li>The provisions of this § 11 apply only to Customers who are Consumers.</li>
              <li>The Customer may use the out-of-court ways of dealing with complaints and pursuing claims.</li>
              <li>
                Detailed information on the possibility of using out-of-court complaint and redress procedures by the
                Customer, as well as rules of access to these procedures, are available at the offices and websites of:
                <ArticleOrderedSublist>
                  <li>
                    poviat (municipal) consumer ombudsmen, social organisations whose statutory tasks include consumer
                    protection;
                  </li>
                  <li>Voivodship Inspectorates of Trade Inspection;</li>
                  <li>Office for Competition and Consumer Protection.</li>
                </ArticleOrderedSublist>
              </li>
              <li>
                The Customer may also use the platform for online dispute resolution (ODR) available at:{' '}
                <StyledTextLink as="a" target="_blank" href="http://ec.europa.eu/consumers/odr/">
                  http://ec.europa.eu/consumers/odr/
                </StyledTextLink>
                .
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 12. Amendments to the Terms and Conditions">
            <ArticleOrderedList>
              <li>
                The Provider may amend the Terms and Conditions in the event:
                <ArticleOrderedSublist>
                  <li>of a change in the Provider's data;</li>
                  <li>of a change of the objects of the Provider's business;</li>
                  <li>
                    the Provider starts to provide new services, modifies the services previously provided or ceases to
                    provide them;
                  </li>
                  <li>
                    of technical modifications of the App requiring an adjustment of the provisions of the Terms and
                    Conditions to reflect them;
                  </li>
                  <li>
                    of a legal obligation to make changes, including the obligation to adapt the Terms and Conditions to
                    the current state of the law.
                  </li>
                </ArticleOrderedSublist>
              </li>
              <li>
                Customers will be informed of the amendments to the Terms and Conditions by publication of the amended
                version on the App's website. At the same time, the amended version of the Terms and Conditions will be
                sent to Subscribers by e-mail.
              </li>
              <li>
                To Agreements on Digital Goods Provision concluded before the date of publication of the new Terms and
                Conditions on the App's website, the provisions of the then-current Terms and Conditions shall apply.
              </li>
              <li>
                The Subscriber who does not agree to the amendment to the Terms and Conditions may terminate the
                Newsletter Subscription Agreement with immediate effect within 7 (seven) days of receiving the amended
                version of the Terms and Conditions by e-mail. Failure to terminate shall be deemed acceptance of the
                amended Terms and Conditions.
              </li>
              <li>
                Termination of the Newsletter Subscription Agreement shall be effected by submitting to the Provider a
                statement on termination of the Newsletter Subscription Agreement by the Subscriber. The statement
                referred to in the preceding sentence may be sent by email to the address indicated in § 1 Section 4
                Point 1 of the Terms and Conditions.
              </li>
              <li>
                Immediately upon receipt of the statement referred to in Section 5 above, the Provider shall cease
                Newsletter delivery.
              </li>
            </ArticleOrderedList>
          </ArticleWrapper>

          <ArticleWrapper title="§ 13. Final provisions">
            <ArticleOrderedList>
              <li>
                These Terms and Conditions, the Newsletter Subscription Agreement and the Agreement on Digital Goods
                Provision shall be governed by Polish law. However, the choice of Polish law made in the preceding
                sentence shall not deprive the Consumer of the protection stemming from the provisions of foreign law
                which cannot be excluded by agreement and which would be applicable in the absence of the choice of
                Polish law made in the preceding sentence.
              </li>
              <li>The current version of the Terms and Conditions is effective as of 17.02.2024r.</li>
            </ArticleOrderedList>
          </ArticleWrapper>
        </ArticlesList>
      </LegalPageLayout>
    </PageHelmetWrapper>
  );
};

export default TermsAndConditionsGDP;
