import styled from 'styled-components';

export const StyledFooterBottomContent = styled.section`
  display: flex;
  justify-content: space-between;

  p {
    margin: 44px 0 0;
    font-size: 10px;
    font-family: 'Barlow', sans-serif;
    line-height: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      margin: 20px 0 0;
      font-size: 8px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      margin: 14px 0 0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
      margin: 12px 0 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column-reverse;
  }
`;

export const StyledFooterLogotypes = styled.div`
  display: flex;
  align-items: center;
  gap: 84px;
  margin-top: 34px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 37px;
    margin-top: 22px;

    img:nth-child(1) {
      width: 48px;
    }

    img:nth-child(2) {
      width: 68px;
    }

    img:nth-child(3) {
      width: 60px;
    }

    img:nth-child(4) {
      width: 75px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    justify-content: space-between;
    margin-top: 15px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    gap: 15px;

    img:nth-child(1) {
      width: 42px;
    }

    img:nth-child(2) {
      width: 58px;
    }

    img:nth-child(3) {
      width: 52px;
    }

    img:nth-child(4) {
      width: 65px;
    }
  }
`;
