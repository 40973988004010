import styled, { css } from 'styled-components';
import { Paragraph } from 'ui/atoms/paragraph';

export const HeadingWrapper = styled.div`
  overflow: hidden;
`;

export const SectionHeaderHeadingWrapper = styled.div<{ withDecorators: boolean }>`
  position: relative;

  ${({ withDecorators }) =>
    withDecorators &&
    css`
      &::before,
      ::after {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        width: 200%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.copper};

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          top: 15px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          display: none;
        }
      }

      &::before {
        right: calc(100% + 78px);
      }

      &::after {
        left: calc(100% + 78px);
      }
    `}

  span {
    display: block;
  }
`;

export const SectionHeaderDescriptionWrapper = styled.div`
  display: flex;
  gap: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    gap: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    flex-direction: column;
  }
`;

export const SectionHeaderDescription = styled(Paragraph)`
  max-width: 800px;
  margin: 15px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 20px;
    line-height: normal;
    margin: 12px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    br {
      display: none;
    }
  }
`;
