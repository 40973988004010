import { AltCheckmark, Checkmark, InputWrapper, TextArea } from '../styled';

interface FormTextareaProps {
  inputValue: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  showError: boolean;
  isValid: boolean;
  $maxWidth?: number;
  digitalTwin?: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const FormTextarea = (props: FormTextareaProps) => {
  return (
    <InputWrapper maxWidth={props.$maxWidth}>
      <TextArea
        as="textarea"
        value={props.inputValue}
        onChange={(event) => props.onInputChange(event)}
        invalid={props.showError}
        name={props.name}
        required={props.isRequired}
        placeholder={props.isRequired ? `${props.placeholder}*` : props.placeholder}
        autoComplete="off"
      />
      {props.isValid && (props.digitalTwin ? <AltCheckmark type="textarea" /> : <Checkmark type="textarea" />)}
    </InputWrapper>
  );
};
