import styled, { css } from 'styled-components';

interface PageBackgroundProps {
  children: React.ReactNode;
  background?: string;
  color?: string;
  width?: number;
  height?: number;
  bordered?: boolean;
  px?: number;
}

export const PageBackground = styled.div<PageBackgroundProps>`
  background: ${(props) => props.background || 'rgba(28, 26, 25, 0.5)'};
  color: ${(props) => props.color || '#fff'};
  width: ${(props) => props.width || 300}px;
  height: ${(props) => props.height || 300}px;
  padding-inline: ${({ px }) => (px ? `${px}px` : 'auto')};
  display: grid;
  place-items: center;
  border: none;

  ${({ bordered }) =>
    bordered &&
    css`
      background-color: lightgray;
      border: 1px solid #000;
    `}
`;

export const ComponentBorder = styled.div`
  border: 1px solid red;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  min-width: 800px;
`;

export const match = <T extends string | number | symbol, V>(value: T, handlers: { [key in T]: () => V }): V => {
  const handler = handlers[value];

  return handler();
};
