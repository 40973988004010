import { SubscriptionColorsConfig, Subscription } from '../types';

export const colorConfig: Record<Subscription['name'], SubscriptionColorsConfig> = {
  'Initial Exploration': {
    bgColor: null,
    color: 'tuatara',
    themeBgColor: 'silverChalice',
    areaQuotaColor: 'silverChalice',
  },
  'Standard Exploration': {
    bgColor: null,
    color: 'tuatara',
    themeBgColor: 'lightBrown',
    areaQuotaColor: 'lightBrown',
  },
  'Extended Exploration': {
    bgColor: null,
    color: 'tuatara',
    themeBgColor: 'copper',
    areaQuotaColor: 'copper',
  },
  'Enterprise Package': {
    bgColor: 'chicago',
    color: 'white',
    themeBgColor: 'copper',
    areaQuotaColor: 'white',
  },
};
