import { PUBLIC_CONTAINER_URL } from 'common/constants';
import { getVideoFormat } from 'utils/getVideoFormat';

const baseUrl = `${PUBLIC_CONTAINER_URL}/videos/main/data-processing`;

export const mobileVideosData = [
  {
    id: 1,
    type: 'image',
    src: 'images/dataProcessing/data-sources.svg',
    sideIconSrc: null,
  },
  {
    id: 2,
    type: 'video',
    src: `${baseUrl}/factory_mobile.${getVideoFormat()}`,
    sideIconSrc: 'images/dataProcessing/innovation-lab.svg',
  },
  {
    id: 3,
    type: 'video',
    src: `${baseUrl}/catalog_mobile.${getVideoFormat()}`,
    sideIconSrc: 'images/dataProcessing/fusion-lab.svg',
  },
  {
    id: 4,
    type: 'image',
    src: 'images/dataProcessing/api-integration.svg',
    sideIconSrc: null,
  },
  {
    id: 5,
    type: 'video',
    src: `${baseUrl}/app_mobile.${getVideoFormat()}`,
    sideIconSrc: null,
  },
];
