import styled, { css } from 'styled-components';

type PaddingWrapperProps = {
  size: 'small' | 'large';
  type: 'left' | 'right' | 'inline';
  isXS?: boolean;
};

export const PaddingWrapper = styled.div<PaddingWrapperProps>`
  width: 100%;

  ${({ size, type, isXS }) =>
    size === 'small' &&
    css`
      padding-${type}: 80px;

      @media(max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
        padding-${type}: 50px;
      }

      @media(max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        padding-${type}: 40px;
      }

      @media(max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
        ${isXS && `padding-${type}: 10px;`}
      }
    `}

  ${({ size, type, isXS }) =>
    size === 'large' &&
    css`
      padding-${type}: 160px;

      @media(max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
        padding-${type}: 126px;
      }

      @media(max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        padding-${type}: 40px;
      }

      @media(max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
        ${isXS && `padding-${type}: 10px;`}
      }
    `}
`;
