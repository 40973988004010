export const pricingOptionsTableHeaders = [
  {
    id: 1,
    header: 'Features',
  },
  {
    id: 2,
    header: 'Basic Plan: Starting from $2,000 per 150 km²',
  },
  {
    id: 3,
    header: 'Standard Plan: Starting from $8,000 per 150 km²',
  },
  {
    id: 4,
    header: 'Premium Plan: Starting from $20,000 per 150 km²',
  },
  {
    id: 5,
    header: 'Expert Plan: Starting from $30,000 per 150 km²',
  },
];

export const pricingOptionsTableRows = [
  {
    id: 1,
    cells: [
      'Satellite Data Acquisition',
      'Sentinel-2, ASTER',
      'Sentinel-2, EMIT, ASTER',
      'Sentinel-2, Landsat 8, ASTER, WorldView-3, EnMap, Prisma, Hyperion',
      'Sentinel-2, Landsat 8, ASTER, WorldView-3, EnMap, Prisma, Hyperion',
    ],
  },
  {
    id: 2,
    cells: [
      'Data Processing',
      'Basic',
      'Enhanced with additional satellite sources',
      'Advanced AI-driven analysis',
      'Advanced AI-driven analysis',
    ],
  },
  {
    id: 3,
    cells: [
      'Interpretation and Analysis',
      'Basic spectral indices',
      'In-depth spectral indices and classification',
      'Detailed spectral indices, classification, and AI algorithms',
      'Detailed spectral indices, classification, and AI algorithms',
    ],
  },
  {
    id: 4,
    cells: [
      'Predictive models for commodity targeting',
      '—',
      '—',
      'Preparation of mathematical models, unsupervised clustering with ground truth and domain knowledge validation',
      'Preparation of mathematical models, unsupervised clustering with ground truth and domain knowledge validation, semi-supervised and supervised classification model',
    ],
  },
  {
    id: 5,
    cells: ['AI-driven Mineral Prospectivity Mapping', '—', '—', '—', 'Included'],
  },
  {
    id: 6,
    cells: [
      'Bare Earth Composite (BEC)',
      'Included (Sentinel-2 Only)',
      'Included (Fused Sentinel-2 and Aster)',
      'Included (Fused Sentinel-2 and Aster)',
      'Included (Fused Sentinel-2 and Aster)',
    ],
  },
  {
    id: 7,
    cells: ['Report Formats', '.pdf, .tiff', '.pdf, .tiff', '.pdf, .tiff', '.pdf, .tiff'],
  },
  {
    id: 8,
    cells: [
      'Data Visualization',
      '—',
      'Interactive visualization via TerraEye app',
      'Interactive visualization via TerraEye app',
      'Interactive visualization via TerraEye app',
    ],
  },
  {
    id: 9,
    cells: ['Report Customization', 'Standard', 'Enhanced', 'Fully customizable', 'Fully customizable'],
  },
  {
    id: 10,
    cells: ['Interpretation', 'None', 'Basic', 'Basic', 'Advanced'],
  },
  {
    id: 11,
    cells: [
      'Access to TerraEye Platform',
      'Basic access',
      'Standard access',
      'Priority real-time access',
      'Priority real-time access',
    ],
  },
  {
    id: 12,
    cells: ['Customer Support', 'Standard', 'Priority', '24/7 Dedicated support', '24/7 Dedicated support'],
  },
  {
    id: 13,
    cells: ['Integration and Delivery Time', 'Standard', 'Faster delivery', 'Faster delivery', 'Faster delivery'],
  },
  {
    id: 14,
    cells: [
      'Analytics Methods',
      'SAM, MTMF, Geological Spectral Indices',
      'SAM, MTMF, Geological Spectral Indencies, SFF, LSU',
      'SAM, MTMF, Geological Spectral Indices, SFF, LSU, AI and ML techniques',
      'SAM, MTMF, Geological Spectral Indices, SFF, LSU, AI and ML techniques',
    ],
  },
];
