import styled from 'styled-components';

import { SlideDesktop, SlideMobile, SlideTablet } from './animations';

export const StyledPartnership = styled.section`
  background-color: ${({ theme }) => theme.colors.codGray};
  padding: 62px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 45px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 35px 0;
  }
`;

export const PartnershipHeading = styled.h2`
  display: inline-block;
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -5px;
    background-color: ${({ theme }) => theme.colors.copper};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 20px;

    &::after {
      bottom: -4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;

    &::after {
      bottom: -4px;
    }
  }
`;

export const PartnershipContent = styled.div`
  padding: 0 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 0 63px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 20px;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  margin-top: 40px;
  padding: 0 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 0 63px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 20px;
  }

  &::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 160px;
    height: 100%;
    z-index: 2;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
      width: 120px;
    }
  }

  &::before {
    left: 0;
    background: rgb(29, 27, 27);
    background: linear-gradient(
      90deg,
      rgba(29, 27, 27, 1) 0%,
      rgba(29, 27, 27, 0.5046393557422969) 50%,
      rgba(29, 27, 27, 0) 100%
    );
  }

  &::after {
    right: 0;
    background: rgb(29, 27, 27);
    background: linear-gradient(
      90deg,
      rgba(29, 27, 27, 0) 0%,
      rgba(29, 27, 27, 0.5046393557422969) 50%,
      rgba(29, 27, 27, 1) 100%
    );
  }
`;

export const PartnershipSliderList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 76px;
  animation: 34s ${SlideDesktop} infinite linear;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 62px;
    animation: 34s ${SlideTablet} infinite linear;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 50px;
    animation: 34s ${SlideMobile} infinite linear;
  }
`;

export const PartnershipSlider = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 76px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 62px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 50px;
  }

  &:hover ${PartnershipSliderList} {
    animation-play-state: paused;
  }
`;

export const ImageWrapper = styled.li`
  img {
    height: 26px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      height: 22px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      height: 17px;
    }
  }

  &:nth-child(1) {
    img {
      height: 90%;
    }
  }
`;
