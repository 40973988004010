import backgroundImage from 'assets/images/data/data-image.png';
import SectionHeader from 'components/SectionHeader';
import { SubpageHero } from 'components/SubpageHero';

export const DataPageHero = () => {
  return (
    <SubpageHero url={backgroundImage}>
      <SectionHeader
        variant="SECONDARY_H1"
        title="Data"
        description={
          <>
            See how TerraEye processes data and how<br /> we used it in our case studies.
          </>
        }
      />
    </SubpageHero>
  );
};
