import styled, { css } from 'styled-components';

import { PaddingWrapper } from 'components/PaddingWrapper';

type StyledCookiesBarProps = {
  open: boolean;
};

export const StyledCookiesBar = styled.div<StyledCookiesBarProps>`
  min-height: 100px;
  position: fixed;
  bottom: -105px;
  z-index: 1200;
  width: 100%;
  padding: 15px 0;
  opacity: 0;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.copper};
  transition: bottom 150ms ease-in-out, opacity 150ms ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 76px;
  }

  ${({ open }) =>
    open &&
    css`
      bottom: 0px;
      opacity: 1;
    `}
`;

export const CookiesBarContentWrapper = styled(PaddingWrapper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-inline: 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-inline: 40px;
  }
`;

export const CookiesBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  font-size: 18px;
  line-height: 24px;

  p {
    margin: 0;
  }

  button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1rem;
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.copper};
    background-color: ${({ theme }) => theme.colors.white};
    width: 120px;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
    line-height: 16px;

    button {
      font-size: 12px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    line-height: 14px;
    gap: 12px;

    button {
      font-size: 10px;
      width: 62px;
    }
  }
`;
