import { useState } from 'react';

export const useInputFocus = <T extends Record<string, boolean>>(initialIsFocusedState: T) => {
  const [isFocused, setIsFocused] = useState(initialIsFocusedState);

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;

    setIsFocused((isFocused) => ({ ...isFocused, [name]: true }));
  };

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value === '') {
      setIsFocused((isFocused) => ({ ...isFocused, [name]: false }));
    }
  };

  const focusInput = (name: string) => {
    setIsFocused((isFocused) => ({ ...isFocused, [name]: true }));
  };

  const setIsFocusedToDefault = () => setIsFocused(initialIsFocusedState);

  return {
    isFocused,
    onInputFocus,
    onInputBlur,
    setIsFocusedToDefault,
    focusInput,
  };
};
