import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { CheckboxData } from 'types';
import { checkboxDataInitialState } from 'utils/checkboxDataInitialState';

export const useFormChecks = () => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [checkboxData, setCheckboxData] = useState<CheckboxData>(checkboxDataInitialState);

  const validateCaptcha = () => {
    const token = captchaRef.current?.getValue();
    if (token) {
      setIsCaptchaValid(true);
    }
  };

  const inValidateCaptcha = () => setIsCaptchaValid(false);

  const clearCheckboxes = () => setCheckboxData(checkboxDataInitialState);

  return {
    captchaRef,
    isCaptchaValid,
    validateCaptcha,
    inValidateCaptcha,
    checkboxData,
    setCheckboxData,
    clearCheckboxes,
  };
};
