import styled from 'styled-components';

export const FooterTopContent = styled.section`
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-bottom: 40px;
  }
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr;
  grid-template-areas:
    'logo address1 address2 nav';
  grid-gap: 25px;


  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    'logo address1 nav'
    'logo address2 nav'
    ;
    grid-gap: 15px;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    'logo nav'
    'address1 address2'
    ;
    grid-gap: 40px 25px;
  }
`;

export const FooterAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: 12px;
  line-height: 18px;

  address {
    font-style: normal;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 10px;
    line-height: 14px;
    gap: 15px;
  }
`;

export const FooterLinkedin = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: underline;
`;

export const FooterNav = styled.nav`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;

  a {
    display: block;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 11px;
  }
`;

export const FooterNavList = styled.ul`
  a {
    transition: color 250ms ease-out;

    &:hover {
      color: ${({ theme }) => theme.colors.rawSienna};
    }
  }
`;
