import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';

export const RequestAccessAreaSelectorWrapper = styled.div`
  margin-top: 80px;
`;

export const RequestAccessAreaSelectorHeader = styled.h2`
  color: ${({ theme }) => theme.colors.copper};
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 22px;
  font-weight: 500;
`;

export const RequestAccessAreaSelectorDescription = styled(Paragraph)`
  margin-top: 4px;
`;
