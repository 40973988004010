import styled from 'styled-components';

import { PRICING_SECTION_ID } from 'common/constants';
import { BaseButton } from 'ui/atoms/button';
import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { StyledHashLink } from 'components/Navigation/NavigationList/styled';
import Carousel from './Carousel';

export const PersistentMonitoring = () => {
  return (
    <Section dark>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Sign up to our SaaS solution"
        description="Fast, reliable data in 3 simple steps"
      />
      <Carousel />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <ButtonWrapper>
            <BaseButton as={undefined} target="_blank" rel="noopener noreferrer" variant="SECONDARY_COLOR">
              <StyledHashLink to={`/#${PRICING_SECTION_ID}`} smooth>
                more about our pricing options
              </StyledHashLink>
            </BaseButton>
          </ButtonWrapper>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const ButtonWrapper = styled.div`
  width: 340px;
  margin-top: 90px;

  > div > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.rawSienna};

    &:hover {
      color: ${({ theme }) => theme.colors.rawSienna};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 100%;

    > div > a {
      font-size: 12px;
    }
  }
`;

export default PersistentMonitoring;
