import styled from 'styled-components';

import { ROUTES } from 'common/routes';
import { LinkButton } from 'ui/atoms/button';
import { ListItem } from 'components/ListItem';
import { Subscription } from '../types';
import { colorConfig } from './colorsConfig';
import {
  Currency,
  FeaturesList,
  PointsInfo,
  PreviousPriceLabel,
  PreviousPriceWrapper,
  Price,
  PriceTile,
  Period,
  ButtonWrapper,
  ButtonLabel,
  StartExploringButton,
  StyledSubscriptionItem,
  SubscriptionBottomContent,
  SubscriptionTitle,
  PriceRemark,
} from './styled';
import { ReactComponent as TeamIcon } from './team-icon.svg';

interface SubscriptionItemProps {
  subscription: Subscription;
}

export const SubscriptionItem = ({ subscription }: SubscriptionItemProps) => {
  const {
    label,
    price,
    area_quota,
    name,
    priceRemark: priceRemrk,
    features_main_list,
    features_additional_list,
  } = subscription;

  return (
    <StyledSubscriptionItem $bgColor={colorConfig[name].bgColor} color={colorConfig[name].color}>
      <PriceTile $bgColor={colorConfig[name].themeBgColor}>
        {priceRemrk && <PriceRemark>{priceRemrk}</PriceRemark>}
        {name === 'Enterprise Package' ? (
          <TeamIcon />
        ) : (
          <>
            {price.previous_amount && (
              <PreviousPriceWrapper>
                <PreviousPriceLabel>
                  promo
                  <br />
                  price!
                </PreviousPriceLabel>
                <span>
                  <Price oldPrice>
                    {price.symbol}
                    {price.previous_amount}
                  </Price>
                  <Currency transparent>{price.currency}</Currency>
                </span>
              </PreviousPriceWrapper>
            )}
            <p>
              <Price>
                {price.symbol}
                {price.current_amount}
              </Price>
              <Currency>{price.currency}</Currency>
              <Period>monthly</Period>
            </p>
          </>
        )}
      </PriceTile>
      <SubscriptionTitle>{label}</SubscriptionTitle>
      <FeaturesWrapper>
        <FeaturesList>
          {features_main_list.map(({ id, label, url, bold }) => (
            <ListItem key={id} {...{ url, bold }}>
              {label}
            </ListItem>
          ))}
        </FeaturesList>
        {!!features_additional_list.length && (
          <>
            <FeaturesListHeader>What you can order additionally:</FeaturesListHeader>
            <FeaturesList>
              {features_additional_list.map(({ id, label, url, bold }) => (
                <ListItem key={id} {...{ url, bold }}>
                  {label}
                </ListItem>
              ))}
            </FeaturesList>
          </>
        )}
      </FeaturesWrapper>
      <SubscriptionBottomContent>
        <PointsInfo color={colorConfig[name].areaQuotaColor}>
          {area_quota ? (
            <>
              {`${area_quota} calculation points`}
              <br />
              {`= up to ${area_quota}km`}
              <sup>2</sup>
              {` per month`}
            </>
          ) : (
            'custom calculation points'
          )}
        </PointsInfo>
        {area_quota ? (
          <StartExploringButton size="SM" $bgColor={colorConfig[name].themeBgColor}>
            start exploring
          </StartExploringButton>
        ) : (
          <ButtonWrapper>
            <LinkButton to={ROUTES.contactUs} size="SM">
              <ButtonLabel>contact us</ButtonLabel>
            </LinkButton>
          </ButtonWrapper>
        )}
      </SubscriptionBottomContent>
    </StyledSubscriptionItem>
  );
};

const FeaturesWrapper = styled.div`
  margin-top: 25px;
`;

const FeaturesListHeader = styled.span`
  font-family: 'Barlow';
  font-size: 14px;
  line-height: 20px;
`;
