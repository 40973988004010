const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const getVideoFormat = () => {
  if (isIOS()) {
    return 'mp4';
  }

  return 'webm';
};
