import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { useInputFocus } from 'hooks/useInputFocus';
import { postNewsletterData } from 'service/postNewsletterData';
import { isValidEmail } from 'utils/validate';

export const useNewsletter = (isCaptchaValid: boolean) => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const { mutate: postNewsletterDataMutation, status } = useMutation({
    mutationFn: postNewsletterData,
  });

  useEffect(() => {
    if (isValidEmail(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }

    if (email === '') setShowError(false);
  }, [email]);

  const { isFocused, onInputBlur, onInputFocus, setIsFocusedToDefault } = useInputFocus<{ email: boolean }>({
    email: false,
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isCaptchaValid || !emailValid) {
      setShowError(true);
      return;
    }

    postNewsletterDataMutation(
      { email },
      {
        onSuccess: () => {
          setIsFocusedToDefault();
          setShowError(false);
          setEmail('');
        },
      },
    );
  };

  return {
    email,
    isFocused,
    status,
    showError,
    emailValid,
    setEmail,
    onInputFocus,
    onInputBlur,
    onSubmit,
  };
};
