import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { Status } from 'types';
import { Layer } from 'ui/organisms/areaSelector/Layer';
import { Polygon } from 'ui/organisms/areaSelector/Polygon';
import { POLYGON_DEFAULT_C0LOR, POLYGON_FILL_OPACITY, POLYGON_OPACITY } from 'ui/organisms/areaSelector/constants';

interface SelectionBoxProps {
  status: Status;
  enabled?: boolean;
  selectionPoints: [number, number][];
  setSelectionPoints: React.Dispatch<React.SetStateAction<[number, number][]>>;
}

export const SelectionBox = ({ status, enabled, selectionPoints, setSelectionPoints }: SelectionBoxProps) => {
  const map = useMap();

  const [latLng, setLatLng] = useState<number[]>([0, 0]);

  useEffect(() => {
    enabled && setSelectionPoints([]);

    map.off('click');
    map.off('mousemove');

    if (enabled) {
      map.on('click', async (event: { originalEvent: MouseEvent }) => {
        const latlng = await map.mouseEventToLatLng(event.originalEvent);

        setSelectionPoints((selectionPoints) =>
          selectionPoints.length < 2 ? [...selectionPoints, [latlng.lat, latlng.lng]] : [[latlng.lat, latlng.lng]],
        );
      });
    }

    if (enabled) {
      map.on('mousemove', async (event: { originalEvent: MouseEvent }) => {
        const latlng = await map.mouseEventToLatLng(event.originalEvent);

        setLatLng([latlng.lat, latlng.lng]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  useEffect(() => {
    if (status === 'success') {
      setSelectionPoints([]);
    }
  }, [status, setSelectionPoints]);

  if (enabled && selectionPoints.length === 1 && latLng) {
    return (
      <Layer visible={true} name="selectionBox" zIndex={403}>
        <Polygon
          color={POLYGON_DEFAULT_C0LOR}
          opacity={POLYGON_OPACITY}
          fillOpacity={POLYGON_FILL_OPACITY}
          latLngs={[
            [selectionPoints[0][0], selectionPoints[0][1]],
            [selectionPoints[0][0], latLng[1]],
            [latLng[0], latLng[1]],
            [latLng[0], selectionPoints[0][1]],
          ]}
          pane="selectionBox"
        />
      </Layer>
    );
  }

  if (enabled && selectionPoints.length === 2) {
    return (
      <Layer visible={true} name="selectionBox" zIndex={403}>
        <Polygon
          color={POLYGON_DEFAULT_C0LOR}
          opacity={POLYGON_OPACITY}
          fillOpacity={POLYGON_FILL_OPACITY}
          latLngs={[
            [selectionPoints[0][0], selectionPoints[0][1]],
            [selectionPoints[0][0], selectionPoints[1][1]],
            [selectionPoints[1][0], selectionPoints[1][1]],
            [selectionPoints[1][0], selectionPoints[0][1]],
          ]}
          pane="selectionBox"
        />
      </Layer>
    );
  }

  return null;
};
