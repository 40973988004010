import styled from 'styled-components';

export const SubPageLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 54px;
  margin: 70px 0 105px;

  p {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    max-width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 56px 0 83px;
    gap: 28px;

    p {
      font-size: 20px;
      max-width: 280px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 38px 0 40px;
    gap: 26px;

    p {
      font-size: 16px;
      max-width: 230px;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
