import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { postFormData } from 'service/postFormData';
import { postPipedriveData } from 'service/postPipedriveData';
import { FormData, FormInputName, FormValidity, OnFormSubmitProps, Polygon } from 'types';
import {
  isValidCompany,
  isValidCountry,
  isValidEmail,
  isValidJob,
  isValidMessage,
  isValidName,
  isValidPhone,
} from 'utils/validate';

export const useForm = (
  setIsFocusedToDefault: () => void,
  formDataInitialState: FormData,
  isDataValid: (formData: FormData) => boolean,
  userAcceptedRequirements: boolean,
  clearCheckboxes: () => void,
  focusInput: (name: string) => void,
) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<FormData>(formDataInitialState);
  const [polygonAttachment, setPolygonAttachment] = useState<Polygon | null>(null);

  const formValidityInitialState: FormValidity = {
    name: false,
    phone: false,
    from: false,
    company: false,
    message: false,
    job: false,
    country: false,
  };
  const [formValidity, setFormValidity] = useState<FormValidity>(formValidityInitialState);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const { mutate: postFormDataMutation, status } = useMutation({
    mutationFn: postFormData,
  });

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.target.name as FormInputName;
    const value = event.target.value;

    focusInput(name);

    if (name === 'phone') {
      let formattedValue = value.replace(/[^0-9+]/g, '');

      setFormData((formData) => ({ ...formData, phone: formattedValue }));
    } else {
      setFormData((formData) => ({ ...formData, [name]: value }));
    }

    const trimmedValue = value.trim();

    let isValid = false;

    switch (name) {
      case 'name':
        isValid = isValidName(trimmedValue);
        break;
      case 'phone':
        isValid = isValidPhone(trimmedValue);
        break;
      case 'from':
        isValid = isValidEmail(trimmedValue);
        break;
      case 'company':
        isValid = isValidCompany(trimmedValue);
        break;
      case 'message':
        isValid = isValidMessage(trimmedValue);
        break;
      case 'job':
        isValid = isValidJob(trimmedValue);
        break;
      case 'country':
        isValid = isValidCountry(trimmedValue);
        break;
      default:
        break;
    }

    setFormValidity((validity) => ({ ...validity, [name]: isValid }));
  };

  const onSubmitSuccess = () => {
    setFormData(formDataInitialState);
    setPolygonAttachment(null);
    setFormValidity(formValidityInitialState);
    setShowValidationErrors(false);
    setIsFocusedToDefault();
    clearCheckboxes();
  };

  const onFormSubmit = async ({ event, casestudy, pipedriveFlag, callback }: OnFormSubmitProps) => {
    event.preventDefault();

    if (!userAcceptedRequirements) return;

    if (!isDataValid(formData)) {
      const formElement = formRef.current;

      if (formElement) {
        const y = formElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 80;

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        });
      }

      setShowValidationErrors(true);
      return;
    }

    try {
      pipedriveFlag && (await postPipedriveData(formData));
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Pipedrive: ${error.message}`);
      }
    }

    const formDataWithPolygonAttachment = polygonAttachment ? { ...formData, attachment: polygonAttachment } : formData;

    postFormDataMutation(
      {
        ...formDataWithPolygonAttachment,
        casestudy: casestudy ?? '',
      },
      {
        onSuccess: () => {
          onSubmitSuccess();
          !!callback && callback();
        },
      },
    );
  };

  return {
    formData,
    userAcceptedRequirements,
    formValidity,
    showValidationErrors,
    status,
    formRef,
    setFormData,
    onInputChange,
    setPolygonAttachment,
    onFormSubmit,
  };
};
