import styled from 'styled-components';

export const Section = styled.section<{ url?: string, dark?: boolean, light?: boolean, large?: boolean }>`
  background: ${({ url }) => url ? `center / cover no-repeat url(${url})` : 'none'};
  background-color: ${({ theme, dark, light }) => dark ? theme.colors.mineShaft : (light ? theme.colors.gallery : theme.colors.alto)};
  padding: 160px 0;
  padding-bottom: ${({ large }) => large && '200px'};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 150px 0;
    padding-bottom: ${({ large }) => large && '190px'};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 100px 0;
    padding-bottom: ${({ large }) => large && '125px'};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 60px 0;
    padding-bottom: ${({ large }) => large && '75px'};
  }
`;
