export const ecosystemData = {
  description:
    'We seamlessly <strong>integrate various data sources to create an immersive digital twin of your site</strong>, enabling you to visualize, analyse, and understand the intricate details of your operations and environment. <strong>We work directly with you, to identify key areas relevant to your operation</strong> and tailor our solution to focus an array of pertinent data sources.',
  list: [
    {
      id: 1,
      title: 'Hyperspectral Data Processing',
      subTitle:
        'Leverage high-resolution hyperspectral data to identify and map a wide range of minerals with precision.',
      description:
        '<1><2>Highly accurate analysis of diverse materials and components with <strong>Hyperspectral Imaging (HSI)</strong>.</2><2><strong>Precise results that increase the number of utilized spectral bands by two orders of magnitude compared to Multispectral Imaging (MSI).</strong></2><2>A leverage for tasks like mineral mapping for mineral prospecting and exploration, detection of soil, vegetation, and water contamination by heavy metals, sulphur and hydrocarbons, and identification of gas leakages, such as methane.</2></1>',
      videoName: 'hyperspectral',
      externalUrl: '',
    },
    {
      id: 2,
      title: 'Bare Earth Composite (BEC)',
      subTitle:
        'Improve your exploration accuracy by removing vegetation and other cover to reveal the true geological surface.',
      description:
        '<1><2><strong>Bare Earth Composite</strong> approach represents bare soil, based on a series of historical data.</2><2>By combining multiple bands from multiple data sources to compose the BEC spectral curve the provided data is rich in geological information.</2><2>Less susceptible to atmospheric or instrumental defects because of the geometric median use.</2></1>',
      videoName: 'BEC',
      externalUrl: 'https://terraeye.blogspot.com/2024/10/bare-earth-composite-bec.html',
    },
    {
      id: 3,
      title: 'Mineral Mapping',
      subTitle:
        'Utilize advanced spectral indices and AI-driven algorithms to detect and analyze mineral distributions.',
      description:
        "<1><2>Referencing spectral signatures from <strong>USGS spectral libraries</strong> and proprietary reference spectra.</2><2>Classification algorithms such as <strong>SAM (spectral angle mapper)</strong> and proprietary machine-learning algorithms to match pixels' spectrum with mineral reference spectrum and produce mineral maps.</2><2>Spectral unmixing algorithms, such as <strong>MTMF (mixture-tuned matched filtering)</strong> to map relative abundances of minerals in mixed pixels.</2><2><strong>Geological spectral indices</strong> to highlight hot spots of feature occurrence (substances, minerals, mineral groups, or lithologies)</2></1>",
      videoName: 'mineral-mapping',
      externalUrl: 'https://terraeye.blogspot.com/2024/10/mineralization-maps-as-tool-for.html',
    },
    {
      id: 4,
      title: 'Prospectivity Mapping',
      subTitle: "Revealing the Area's Hidden Potential",
      description:
        '<1><2><strong>Developing machine learning algorithms to highlight locations of the most prospecting targets for regional scale targeting.</strong></2><2><strong>Prospectivity score</strong> is generated based on the interpretation of multiple mineral mapping results, as well as public datasets available for the area subjected to mineral prospecting (geophysical, structural geology).</2><2><strong>A tailored solution</strong>: for porphyry copper we interpret the presence of indicator minerals associated with hydrothermal alterations and gossans. For LCT pegmatites - we highlight pegmatite outcrops and distinguish lithium-bearing ones.</2></1>',
      videoName: 'prospectivity-mapping',
      externalUrl: '',
    },
    {
      id: 5,
      title: 'Mining Area Segmentation',
      subTitle: 'Track Activity and Progress',
      description:
        '<1><2>Models are designed to <strong>segment key components of mining areas such as excavations, stockpiles, dumping grounds, and Tailings Storage Facilities (TSFs).</strong></2><2>Monitor long-term mining progress, detect illegal mining activities, and assess the environmental impacts in terms of land use.</2><2><strong>Near real-time satellite data, enabling informed, sustainable decisions across all phases of the mining cycle, from exploration to reclamation.</strong></2></1>',
      videoName: 'mine-segmentation',
      externalUrl: '',
    },
    {
      id: 6,
      title: 'Detecting Ground Deformation with InSAR',
      subTitle: 'Eyes on the Ground',
      description:
        '<1><2><strong>Tracking of ground deformations with remarkable precision with InSAR.</strong></2><2><strong>Delivering crucial insights for the supervision of critical infrastructure like pipelines, tailing storage facilities, and dams.</strong></2><2>Whether assessing landslide risks or monitoring subsidence in mining areas, our solution notifies you of increasing displacement velocities at specific points, enabling prompt action.</2></1>',
      videoName: 'displacement',
      externalUrl: '',
    },
  ],
};
