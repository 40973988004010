export const persistentMonitoringData = [
  {
    id: 1,
    title: 'step 1',
    heading: 'Provide Your Area of Interest (AOI)',
    description:
      'Upload your area of interest through our platform. Application will acquire high-quality satellite data tailored to your specific needs.',
    image: 'persistentMonitoring/step-1-logo.svg',
  },
  {
    id: 2,
    title: 'step 2',
    heading: 'Receive Processed Data',
    description:
      'We process the data using advanced algorithms and deliver results, including spectral analysis, mineral mapping, and more. Receive results even  in a couple of minutes.',
    image: 'persistentMonitoring/step-2-logo.svg',
  },
  {
    id: 3,
    title: 'step 3',
    heading: 'Integrate and Analyze',
    description:
      'Access your data through our TerraEye platform, visualize results, and make data-driven decisions to enhance your exploration efforts.',
    image: 'persistentMonitoring/step-3-logo.svg',
  },
];
