import ReCAPTCHA from 'react-google-recaptcha';

import { ROUTES } from 'common/routes';
import { StatusMessage } from 'components/StatusMessage';
import { CheckboxData, OnFormSubmitProps, Status } from 'types';
import { BaseButton } from 'ui/atoms/button';
import { LabeledCheckbox } from 'ui/molecules/labeledCheckbox';
import { LoadingSpinner } from 'ui/atoms/loadingSpinner/LoadingSpinner';
import { CheckboxGroup, CheckboxInfo, StatusInfoWrapper, StyledLink, StyledReCAPTCHA, Wrapper } from './styled';

type FormBottomContentProps = {
  checkboxData: CheckboxData;
  userAcceptedRequirements: boolean;
  status: Status;
  setCheckboxData: React.Dispatch<React.SetStateAction<CheckboxData>>;
  isCaseStudyForm?: boolean;
  captchaRef: React.MutableRefObject<ReCAPTCHA | null>;
  onCaptchaValidate: () => void;
  onCaptchaExpiry: () => void;
  onFormSubmit: ({ event, casestudy, pipedriveFlag, callback }: OnFormSubmitProps) => Promise<void>;
  digitalTwin?: boolean;
  privacyPolicyLabelFirst?: string;
  privacyPolicyLabelLinked?: string;
  privacyPolicyLabelSecond?: string;
  sendButtonLabel?: string;
  sendingSuccessMessage?: string;
  sendingErrorMessage?: string;
};

const FormBottomContent = ({
  checkboxData,
  userAcceptedRequirements,
  status,
  isCaseStudyForm,
  setCheckboxData,
  captchaRef,
  onCaptchaValidate,
  onCaptchaExpiry,
  onFormSubmit,
  digitalTwin,
  privacyPolicyLabelFirst = "I declare that I have read the ",
  privacyPolicyLabelLinked = "Privacy Policy",
  privacyPolicyLabelSecond = " and accept their provisions.",
  sendButtonLabel = 'send',
  sendingSuccessMessage = 'your message has been sent!',
  sendingErrorMessage = 'your message could not be sent!',
}: FormBottomContentProps) => {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  if (!recaptchaKey) {
    console.error('Recaptcha key is not defined');
  }

  const { hasAcceptedPrivacyPolicy, hasAcceptedSubscription } = checkboxData;
  const showStatusMessage = status === 'success' || status === 'error';

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof CheckboxData;

    setCheckboxData((checkboxData) => ({
      ...checkboxData,
      [name]: !checkboxData[`${name}`],
    }));
  };

  return (
    <>
      <CheckboxGroup>
        {!isCaseStudyForm && (
          <LabeledCheckbox
            id="privacyPolicy"
            name="hasAcceptedPrivacyPolicy"
            checked={hasAcceptedPrivacyPolicy}
            onChange={(event) => onInputChange(event)}
            altCheck={digitalTwin}
          >
            {privacyPolicyLabelFirst}<StyledLink to={digitalTwin ? ROUTES.digitalTwinPrivacyPolicy : ROUTES.privacyPolicy}>{privacyPolicyLabelLinked}</StyledLink>{privacyPolicyLabelSecond}
          </LabeledCheckbox>
        )}
        {isCaseStudyForm && (
          <CheckboxInfo>
            Remote Sensing Business Solutions is committed to protecting and respecting your privacy, and we’ll only use
            your personal information to administer your account and to provide the products and services you requested
            from us. From time to time, we would like to contact you about our products and services, as well as other
            content that may be of interest to you, (i.e like newsletter). If you consent to us contacting you for this
            purpose, please tick below to say how you would like us to contact you:
          </CheckboxInfo>
        )}
        {!digitalTwin && (
          <LabeledCheckbox
            id="subscription"
            name="hasAcceptedSubscription"
            checked={hasAcceptedSubscription}
            onChange={(event) => onInputChange(event)}
          >
            I agree to receive commercial information about new products and promotions in the TerraEye app, sent by
            Remote Sensing Business Solutions Sp. Z O. O. to the e-mail address provided by me. In addition, I declare that I
            have read the{' '}
            <StyledLink to={isCaseStudyForm ? ROUTES.termsAndConditionsDgp : ROUTES.newsletterRegulations}>
              {isCaseStudyForm ? 'Terms and Conditions of Digital Goods Provision' : 'Newsletter Regulations'}
            </StyledLink>{' '}
            and <StyledLink to={ROUTES.privacyPolicy}>Privacy Policy</StyledLink> and accept their provisions.
          </LabeledCheckbox>
        )}
        {isCaseStudyForm && (
          <>
            <CheckboxInfo>
              If you want to receive our Case study without subscribing to the Newsletter, please contact us by e-mail
              at{' '}
              <StyledLink as="a" href="mailto:office@fourpoint.pl">
                office@fourpoint.pl
              </StyledLink>
            </CheckboxInfo>
            <CheckboxInfo>
              You can unsubscribe from these communications at any time. By clicking submit below, you consent to allow
              Remote Sensing Business Solutions to store and process the personal information submitted above to provide
              you the content requested.
            </CheckboxInfo>
          </>
        )}
      </CheckboxGroup>
      <Wrapper>
        {recaptchaKey && (
          <StyledReCAPTCHA
            ref={captchaRef}
            onChange={onCaptchaValidate}
            onExpired={onCaptchaExpiry}
            sitekey={recaptchaKey}
            //@ts-ignore
            theme="dark"
          />
        )}
        <StatusInfoWrapper>
          <BaseButton
            as="button"
            width={224}
            onClick={(event) => onFormSubmit({ event, pipedriveFlag: digitalTwin ? false : true })}
            disabled={!userAcceptedRequirements}
            loading={status === 'pending' ? 'true' : 'false'}
          >
            {status === 'pending' ? <LoadingSpinner /> : sendButtonLabel}
          </BaseButton>
          {showStatusMessage && status === 'success' && (
            <StatusMessage type="success">{sendingSuccessMessage}</StatusMessage>
          )}
          {showStatusMessage && status === 'error' && (
            <StatusMessage type="error">{sendingErrorMessage}</StatusMessage>
          )}
        </StatusInfoWrapper>
      </Wrapper>
    </>
  );
};

export default FormBottomContent;
