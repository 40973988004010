import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface TextLinkProps extends LinkProps {}

export const TextLink = (props: TextLinkProps) => {
  return <StyledTextLink target="_blank" {...props} />;
};

export const StyledTextLink = styled(Link)`
  color: ${({ theme }) => theme.colors.rawSienna};
`;
