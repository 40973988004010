import styled, { css } from 'styled-components';

type StyledDesktopViewProps = {
  hide: boolean;
};

export const StyledDesktopView = styled.div<StyledDesktopViewProps>`
  padding: 0 120px 0 160px;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const DesktopViewContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 468px) 612px;
  justify-content: space-between;
  max-width: 1159px;
  margin: 0 auto;
`;

export const DesktopViewArticlesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 370px;
  list-style: none;
  padding: 0;
  margin: 0;

  li:nth-child(4) h3 {
    max-width: 400px;
  }
`;
