import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import styled from 'styled-components';

import terraEyeImage from 'assets/images/terraEye/terraeye-info.jpg';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import SubPageLayout from 'components/SubPage';
import { SubPageImage } from 'components/SubPageImage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cancelNewsletter } from 'service/cancelNewsletter';
import { LinkButton } from 'ui/atoms/button/Button';
import { getStatusMessagesHelper } from './getStatusMessagesHelper';
import { Paragraph } from 'ui/atoms/paragraph';

export const NewsletterCancelPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const uidQuery = searchParams.get('uid');

  const { mutate: cancelNewsletterMutation, status } = useMutation({
    mutationFn: cancelNewsletter,
    onSettled: () => {
      searchParams.delete('uid');
      setSearchParams(searchParams);
    },
  });

  useEffect(() => {
    if (!uidQuery) {
      navigate('/');
      return;
    }

    cancelNewsletterMutation({ id: uidQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubPageLayout image={<NewsletterCancelPageImage desktopUrl={terraEyeImage} tabletUrl={terraEyeImage} />}>
      <section>
        <NewsletterCancelContent>
          <SectionHeader title="Unsubscribed" />

          <PaddingWrapper size="large" type="right">
            <Container size="small" align="start">
              <PaddingWrapper size="large" type="left">
                <>
                  <NewsletterCancelDescription size="LG" fontFamily="Barlow Condensed">
                    {getStatusMessagesHelper(status)}
                  </NewsletterCancelDescription>
                  <LinkButton to="/" height={52} width={213} variant="SECONDARY">
                    back to homepage
                  </LinkButton>
                </>
              </PaddingWrapper>
            </Container>
          </PaddingWrapper>
        </NewsletterCancelContent>
      </section>
    </SubPageLayout>
  );
};

const NewsletterCancelPageImage = styled(SubPageImage)`
  background-position: 50% 90%;
`;

const NewsletterCancelContent = styled.div`
  padding-bottom: 86px;

  a {
    font-size: 12px;
    max-width: 213px;
    height: 52px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    padding-bottom: 100px;

    a {
      max-width: 100%;
      height: 50px;
    }
  }
`;

const NewsletterCancelDescription = styled(Paragraph)`
  margin: 25px 0 56px;
`;
