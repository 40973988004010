import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

export type WrapperVariant = 'STATIC' | 'RELATIVE' | 'ABSOLUTE';

interface WrapperProps {
  zIndex?: number;
  children: ReactNode;
  variant?: WrapperVariant;
  top?: CSSProperties['marginTop'] | CSSProperties['top'];
  bottom?: CSSProperties['marginBottom'] | CSSProperties['bottom'];
  left?: CSSProperties['marginLeft'] | CSSProperties['left'];
  right?: CSSProperties['marginRight'] | CSSProperties['right'];
  vertical?:
    | CSSProperties['marginTop']
    | CSSProperties['top']
    | CSSProperties['marginBottom']
    | CSSProperties['bottom'];
  horizontal?:
    | CSSProperties['marginLeft']
    | CSSProperties['left']
    | CSSProperties['marginRight']
    | CSSProperties['right'];
  margin?:
    | CSSProperties['margin']
    | CSSProperties['top']
    | CSSProperties['bottom']
    | CSSProperties['left']
    | CSSProperties['right'];
}

const staticWrapperStyles = css<WrapperProps>`
  margin-top: ${({ top, vertical, margin }) => top || vertical || margin || 0}px;
  margin-bottom: ${({ bottom, vertical, margin }) => bottom || vertical || margin || 0}px;
  margin-left: ${({ left, horizontal, margin }) => left || horizontal || margin || 0}px;
  margin-right: ${({ right, horizontal, margin }) => right || horizontal || margin || 0}px;
`;

const absoluteWrapperStyles = css<WrapperProps>`
  top: ${({ top, vertical, margin }) => top || vertical || margin || 0}px;
  bottom: ${({ bottom, vertical, margin }) => bottom || vertical || margin || 0}px;
  left: ${({ left, horizontal, margin }) => left || horizontal || margin || 0}px;
  right: ${({ right, horizontal, margin }) => right || horizontal || margin || 0}px;
`;

export const Wrapper = styled.div<WrapperProps>`
  position: ${({ variant }) => variant?.toLowerCase()};
  ${({ variant }) => variant === 'STATIC' && staticWrapperStyles};
  ${({ variant }) => variant && ['ABSOLUTE', 'RELATIVE'].includes(variant) && absoluteWrapperStyles};
  z-index: ${({ zIndex }) => zIndex || 'auto'};
`;

Wrapper.defaultProps = {
  variant: 'STATIC' as WrapperVariant,
};

Wrapper.displayName = 'Wrapper';
