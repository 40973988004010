import { Subscription, SubscriptionFeature } from './types';

const isSubscriptionFeature = (feature: any): feature is SubscriptionFeature => {
  return (
    typeof feature.id === 'number' &&
    typeof feature.label === 'string' &&
    typeof feature.url === 'string' &&
    (feature.url.includes('terraeye') || feature.url.length === 0) &&
    typeof feature.bold === 'boolean'
  );
};

const isSubscription = (data: any): data is Subscription => {
  return (
    data &&
    typeof data === 'object' &&
    (typeof data.area_quota === 'number' || data.area_quota === null) &&
    typeof data.info === 'string' &&
    typeof data.label === 'string' &&
    typeof data.name === 'string' &&
    typeof data.price === 'object' &&
    (typeof data.price.current_amount === 'number' || data.price.current_amount === null) &&
    (typeof data.price.previous_amount === 'number' || data.price.previous_amount === null) &&
    typeof data.price.currency === 'string' &&
    typeof data.price.symbol === 'string' &&
    Array.isArray(data.features_main_list) &&
    data.features_main_list.every((feature: any) => isSubscriptionFeature(feature)) &&
    Array.isArray(data.features_additional_list) &&
    data.features_additional_list.every((feature: any) => isSubscriptionFeature(feature))
  );
};

export const isSubscriptionsData = (data: any): data is Subscription[] => {
  return data !== undefined && Array.isArray(data) && data.every((item) => isSubscription(item));
};
