import styled from 'styled-components';

export const StyledFormRequiredMessage = styled.p`
  color: ${({ theme }) => theme.colors.rawSienna};
  font-size: 14px;
  line-height: 30px;
  margin: 4px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 4px 0 26px;
    font-size: 12px;
  }
`;
