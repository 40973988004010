import styled, { css } from 'styled-components';

import { ContainerAlign, ContainerSize } from 'types';

type ContainerProps = {
  size: ContainerSize;
  align: ContainerAlign;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;

  ${({ size }) =>
    size === 'small' &&
    css`
      max-width: 874px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      max-width: 1120px;
    `}

  ${({ size }) =>
    size === 'wide' &&
    css`
      max-width: 1280px;
    `}

  ${({ size }) =>
    size === 'full' &&
    css`
      max-width: 100%;
    `}

  ${({ align }) =>
    align === 'start' &&
    css`
      margin: 0;
    `}

    ${({ align }) =>
    align === 'center' &&
    css`
      margin: 0 auto;
    `}
`;
