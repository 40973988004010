import styled from 'styled-components';

export const StyledHype4ExplorSection = styled.section`
  padding-bottom: 160px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 140px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-bottom: 90px;
  }
`;

export const Hype4ExplorSectionContent = styled.div`
  margin-top: 79px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 69px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 56px;
  }
`;
