import styled, { css } from 'styled-components';

import { ReactComponent as CheckmarkIcon } from './checkmark.svg';
import { ReactComponent as AltCheckmarkIcon } from './altCheckmark.svg';

export const InputWrapper = styled.div<{ maxWidth?: number }>`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${({ theme }) => theme.colors.rawSienna};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const Input = styled.input<{ invalid: boolean }>`
  width: 100%;
  padding: 0;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.rawSienna};
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.rawSienna};
  caret-color: ${({ theme }) => theme.colors.rawSienna};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.rawSienna};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red};

      &::placeholder {
        color: ${({ theme }) => theme.colors.red};
      }
    `}
`;

export const TextArea = styled(Input)`
  display: block;
  resize: none;
  padding: 10px;
  min-height: 224px;
  border: 1px solid ${({ theme }) => theme.colors.rawSienna};

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
    `}
`;

const checkmarkStyle = css<{ type: 'textarea' | 'input' }>`
  position: absolute;

  ${({ type }) =>
    type === 'input' &&
    css`
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    `}

  ${({ type }) =>
    type === 'textarea' &&
    css`
      right: 12px;
      bottom: 16px;
    `}
`;

export const Checkmark = styled(CheckmarkIcon)<{ type: 'textarea' | 'input' }>`
  ${checkmarkStyle};
`;

export const AltCheckmark = styled(AltCheckmarkIcon)<{ type: 'textarea' | 'input' }>`
  ${checkmarkStyle};
`;
