import { t } from 'i18next';

import PageHelmetWrapper from 'components/PageHelmetWrapper';
import PrivacyPolicySection from './PrivacyPolicySection';
import DigitalTwinPrivacyPolicySection from './DigitalTwinPrivacyPolicySection';

const PrivacyPolicyPage = ({ digitalTwin = false }) => {
  return (
    <PageHelmetWrapper page={digitalTwin ? 'digitalTwinPrivacyPolicy' : 'privacyPolicy'}>
      {digitalTwin ? <DigitalTwinPrivacyPolicySection title={t("privacyPolicy.title")} list={t("privacyPolicy.list", { returnObjects: true })}/> : <PrivacyPolicySection />}
    </PageHelmetWrapper>
  );
};

export default PrivacyPolicyPage;
