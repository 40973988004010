import { Trans } from 'react-i18next';

import { PUBLIC_CONTAINER_URL } from 'common/constants';
import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import EcosystemItem from './EcosystemItem';
import { EcosystemDescription, EcosystemList, EcosystemPadding } from './styled';

interface EcosystemProps {
  id: string;
  title: string;
  description: React.ReactNode;
  subDescription?: string;
  data: {
    id: number;
    title: string;
    subTitle: string;
    description: string;
    videoName: string;
    externalUrl: string;
  }[];
  digitalTwin?: boolean;
}

const Ecosystem = ({ id, title, description, subDescription, data, digitalTwin }: EcosystemProps) => {
  const videoUrl = `${PUBLIC_CONTAINER_URL}/videos/${digitalTwin ? 'digital-twin/features' : 'main/ecosystem'}`;

  return (
    <Section id={id} dark={!digitalTwin} large>
      <SectionHeader variant="SECONDARY_H2" title={title} description={description} isBackgroundLight={!!digitalTwin} />
      {subDescription && (
        <PaddingWrapper size="large" type="inline">
          <Container align="center" size="medium">
            <EcosystemDescription size="MD">
              <Trans i18nKey={subDescription} />
            </EcosystemDescription>
          </Container>
        </PaddingWrapper>
      )}

      <EcosystemPadding>
        <Container align="center" size="wide">
          <EcosystemList>
            {data.map(({ id, title, subTitle, description, videoName, externalUrl }) => (
              <EcosystemItem
                key={id}
                title={title}
                subTitle={subTitle}
                description={description}
                videoUrl={`${videoUrl}/${videoName}`}
                digitalTwin={digitalTwin}
                externalUrl={externalUrl}
              />
            ))}
          </EcosystemList>
        </Container>
      </EcosystemPadding>
    </Section>
  );
};

export default Ecosystem;
