import rsbsLogo from 'assets/images/global/rsbs-logo.svg';
import rsbsLogoShort from 'assets/images/mineCam/rsbs-logo-short.svg';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import { ArticlesList } from 'components/Article/styled';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { SubPageLogo } from 'components/SubPageLogo/styled';
import { MineCamLogo, StyledMineCamSection } from './styled';

const MineCamSection = () => {
  return (
    <StyledMineCamSection>
      <SectionHeader title="MineCam" />
      <PaddingWrapper size="large" type="inline">
        <Container size="small" align="start">
          <SubPageLogo>
            <MineCamLogo>
              <img src={rsbsLogo} alt="" />
              <img src={rsbsLogoShort} alt="Remote Sensing Business Solutions" />
            </MineCamLogo>
            <p>Remote Sensing Business Solutions is implementing the MineCam project subsidized by European Funds</p>
          </SubPageLogo>
        </Container>
      </PaddingWrapper>

      <PaddingWrapper size="large" type="right">
        <Container size="small" align="start">
          <PaddingWrapper size="large" type="left">
            <ArticlesList>
              <ArticleWrapper title="Project objective">
                The main research objective of the project is to develop the architecture of neural networks and train
                on their basis, using multispectral satellite imagery with high resolution (10m in visible bands), a set
                of models for recognition and labeling of elements of the mining area. Their purpose is to locate,
                geo-spatially match, and provide data for the geostatistical calculations for the various components of
                the area where mining activities are carried out: dumps, pits, raw material outcrops, preparation work
                areas, mining infrastructure, and others.
              </ArticleWrapper>
              <ArticleWrapper title="Planned effects">
                The final result of the project will be the introduction of a significantly improved service to create a
                modern application for monitoring the activities of open-pit mines, investments carried out in large
                areas, or reducing the area of exploration for rare earth minerals.
              </ArticleWrapper>
              <ArticleWrapper title="Project value" paragraphProps={{ size: 'LG', fontFamily: 'Barlow Condensed' }}>
                1 100 000 PLN
              </ArticleWrapper>
              <ArticleWrapper
                title="European fund contribution"
                paragraphProps={{ size: 'LG', fontFamily: 'Barlow Condensed' }}
              >
                880 000 PLN
              </ArticleWrapper>
            </ArticlesList>
          </PaddingWrapper>
        </Container>
      </PaddingWrapper>
    </StyledMineCamSection>
  );
};

export default MineCamSection;
