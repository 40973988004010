import rsesLogoShort from 'assets/images/ecoSat/rses-logo-short.svg';
import rsesLogo from 'assets/images/ecoSat/rses-logo.svg';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import { ArticlesList } from 'components/Article/styled';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { SubPageLogo } from 'components/SubPageLogo/styled';
import { EcoSatLogo, StyledEcoSatSection } from './styled';

const EcoSatSection = () => {
  return (
    <StyledEcoSatSection>
      <SectionHeader title="EcoSat" />
      <PaddingWrapper size="large" type="inline">
        <Container size="small" align="start">
          <SubPageLogo>
            <EcoSatLogo>
              <img src={rsesLogo} alt="" />
              <img src={rsesLogoShort} alt="Remote Sensing Environmental Solutions" />
            </EcoSatLogo>
            <p>Remote Sensing Environmental Solutions is implementing a project subsidized by European Funds EcoSat</p>
          </SubPageLogo>
        </Container>
      </PaddingWrapper>

      <PaddingWrapper size="large" type="right">
        <Container size="small" align="start">
          <PaddingWrapper size="large" type="left">
            <ArticlesList>
              <ArticleWrapper title="Project objective">
                The main objective of the project is to carry out R&D work on a system for monitoring the environmental
                impact of an open pit mine based on the analysis of satellite imagery using artificial intelligence
                algorithms.
              </ArticleWrapper>
              <ArticleWrapper title="Planned effects">
                The first Polish system for aggregation and analysis of satellite data in time series utilizing
                artificial intelligence to analyze the impact of the mining industry on the environment.
              </ArticleWrapper>
              <ArticleWrapper title="Project value" paragraphProps={{ size: 'LG', fontFamily: 'Barlow Condensed' }}>
                1 100 000 PLN
              </ArticleWrapper>
              <ArticleWrapper
                title="European fund contribution"
                paragraphProps={{ size: 'LG', fontFamily: 'Barlow Condensed' }}
              >
                880 000 PLN
              </ArticleWrapper>
            </ArticlesList>
          </PaddingWrapper>
        </Container>
      </PaddingWrapper>
    </StyledEcoSatSection>
  );
};

export default EcoSatSection;
