import { ParagraphProps } from 'ui/atoms/paragraph/Paragraph';
import { ArticleContent, ArticleTitle } from './styled';

interface ArticleWrapperProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isHeaderSmall?: boolean;
  paragraphProps?: ParagraphProps;
  boldParagraph?: boolean;
  as?: any;
}

const ArticleWrapper = ({ title, children, as, isHeaderSmall, paragraphProps, boldParagraph }: ArticleWrapperProps) => {
  return (
    <article>
      <ArticleTitle as={as} isSmall={isHeaderSmall}>
        {title}
      </ArticleTitle>
      <ArticleContent as="div" {...paragraphProps} bold={boldParagraph}>
        {children}
      </ArticleContent>
    </article>
  );
};

export default ArticleWrapper;
