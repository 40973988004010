import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { PartnershipHeading } from 'pages/HomePage/components/Partnership/styled';
import {
  StyledPartnership,
  DigitalTwinPartnershipSection,
  DigitalTwinPartnershipList,
  ImageWrapper
} from './styled';
import { digitalTwinPartnershipData } from './digitalTwinPartnershipData';

const DigitalTwinPartnership = ({ header }: { header: string }) => {
  return (
    <StyledPartnership>
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <DigitalTwinPartnershipSection>
            <div>
              <PartnershipHeading>{header}</PartnershipHeading>
            </div>
            <DigitalTwinPartnershipList>
              {digitalTwinPartnershipData.map(({ id, image, alt }) => (
                <ImageWrapper key={id}>
                  <img src={require(`assets/images/${image}`)} alt={alt} />
                </ImageWrapper>
              ))}
            </DigitalTwinPartnershipList>
          </DigitalTwinPartnershipSection>
        </Container>
      </PaddingWrapper>
    </StyledPartnership>
  );
};

export default DigitalTwinPartnership;
