import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ConfigCatProvider, LogLevel, createConsoleLogger } from 'configcat-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Style } from 'Style';
import App from 'App';

const logger = createConsoleLogger(LogLevel.Info);

const configcatKey = process.env.REACT_APP_CONFIGCAT_SDK_KEY || '';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ConfigCatProvider sdkKey={configcatKey} options={{ logger }}>
        <HelmetProvider>
          <BrowserRouter>
            <Style>
              <App />
            </Style>
          </BrowserRouter>
        </HelmetProvider>
      </ConfigCatProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
