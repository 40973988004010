import styled from "styled-components"

export const StyledLanguageSelector = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  gap: 24px;
`;

export const LanguageElement = styled.div<{ $selected: boolean, $isLightSectionsInView: boolean }>`
  position: relative;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme, $isLightSectionsInView }) => $isLightSectionsInView ? theme.colors.tuatara : theme.colors.white};
  background-color: transparent;
  transition: color 500ms ease-out;
  cursor: ${({ $selected }) => $selected ? 'default' : 'pointer'};
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  text-decoration-line: ${({ $selected }) => $selected ? 'underline' : 'none'};

  &:hover {
    color: ${({ theme, $selected }) => !$selected && theme.colors.whiskey};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  :not(:last-child) {
    &::after {
      content: '|';
      position: absolute;
      right: -13px;
      bottom: 0;
      color: ${({ theme, $isLightSectionsInView }) => $isLightSectionsInView ? theme.colors.tuatara : theme.colors.white};
      transition: color 500ms ease-out;
      pointer-events: none;

      @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
