import { CaseStudyFile } from 'utils/caseStudies';
import {
  CaseStudyIcon,
  CaseStudyItemContent,
  CaseStudyItemFileInfo,
  CaseStudyItemHeading,
  StyledCaseStudyItem,
} from './styled';

type CaseStudyItemProps = {
  file: CaseStudyFile;
};

const CaseStudyItem = ({ file }: CaseStudyItemProps) => {
  const { fileSize, fileSrc, title, titleHeading, userFileName } = file;

  const displayFileSize = (fileSize / 1024).toFixed(1);

  return (
    <StyledCaseStudyItem href={fileSrc} download={userFileName} target="_blank">
      <CaseStudyItemContent>
        <CaseStudyIcon />
        <div>
          <CaseStudyItemHeading>
            {titleHeading ? (
              <>
                <span>{titleHeading}</span>
                <span>{title}</span>
              </>
            ) : (
              title
            )}
          </CaseStudyItemHeading>
          <CaseStudyItemFileInfo>
            <span>File: PDF</span>
            <span>Size: {displayFileSize} MB</span>
          </CaseStudyItemFileInfo>
        </div>
      </CaseStudyItemContent>
    </StyledCaseStudyItem>
  );
};

export default CaseStudyItem;
