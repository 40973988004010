import { useFeatureFlag } from 'configcat-react';

import { LIGHT_SECTIONS_ID } from 'common/constants';
import PageHelmetWrapper from 'components/PageHelmetWrapper';
import DataProcessing from 'pages/HomePage/components/DataProcessing';
import { DataSource } from 'pages/DataPage/DataSource';
import { DataServices } from 'pages/DataPage/DataServices';
import CaseStudies from 'pages/HomePage/components/CaseStudies';
import CallToAction from 'pages/HomePage/components/CallToAction';
import { Newsletter } from 'pages/HomePage/components/Newsletter';
import { DataPageHero } from './DataPageHero';

const DataPage = () => {
  const { value: ENABLE_NEWSLETTER } = useFeatureFlag('ENABLE_NEWSLETTER', false);
  const enableNewsletter = ENABLE_NEWSLETTER || process.env.REACT_APP_ENABLE_NEWSLETTER;

  return (
    <PageHelmetWrapper page="data">
      <main>
        <DataPageHero />
        <div id={LIGHT_SECTIONS_ID}>
          <DataProcessing />
          <DataSource />
          <DataServices />
        </div>
        <CaseStudies />
        <CallToAction />
        {enableNewsletter && <Newsletter />}
      </main>
    </PageHelmetWrapper>
  );
};

export default DataPage;
