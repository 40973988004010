import { CaseStudy } from 'utils/caseStudies';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Paragraph } from 'ui/atoms/paragraph';
import { CaseStudyFormDescription, DescriptionList } from 'pages/CaseStudyFormPage/CaseStudyFormSection/styled';
import CaseStudyItem from './CaseStudyItem';
import { CaseStudySectionContent, DownloadFile, StyledCaseStudySection } from './styled';

type CaseStudySectionProps = {
  caseStudy: CaseStudy;
};

const CaseStudySection = ({ caseStudy }: CaseStudySectionProps) => {
  const { subpage } = caseStudy;

  const type = subpage.file.fileType;
  const listHeader = (
    <span>
      {type === 'case-study' && <span>With this Case Study </span>}
      {type === 'flyer' && <span>With this Flyer </span>}
      {type === 'booklet' && (
        <span>
          Examples of Geospatial Data Analyses for Better Environmental Monitoring, Decision-Making, ESG Reporting and
          Due Diligence for Mining.
          <br />
          <br />
          <br />
          With this Booklet{' '}
        </span>
      )}
      You will learn how:
    </span>
  );

  const downloadHeader = `Download the ${type === 'case-study' ? 'case study' : 'document'} below`;

  return (
    <StyledCaseStudySection>
      <SectionHeader title={subpage.title.main} nextLineTitle={subpage.title.additional} />
      <CaseStudySectionContent>
        <Container align="start" size="small">
          <PaddingWrapper size="large" type="left">
            <div>
              <CaseStudyFormDescription>
                <Paragraph size="LG" fontFamily="Barlow Condensed">
                  {listHeader}
                </Paragraph>
                <DescriptionList>
                  {subpage.list.map(({ id, content }) => (
                    <Paragraph key={id} as="li">
                      {content}
                    </Paragraph>
                  ))}
                </DescriptionList>
              </CaseStudyFormDescription>
            </div>

            <DownloadFile>
              <Paragraph size="LG" fontFamily="Barlow Condensed" as="h2">
                {downloadHeader}
              </Paragraph>

              <CaseStudyItem file={subpage.file} />
            </DownloadFile>
          </PaddingWrapper>
        </Container>
      </CaseStudySectionContent>
    </StyledCaseStudySection>
  );
};

export default CaseStudySection;
