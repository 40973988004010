import styled from 'styled-components';

export const StyledBenefit = styled.article`
  display: flex;
  flex-direction: column;

  img {
    width: 150px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      width: 120px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      width: 100px;
    }
  }
`;

export const BenefitTitle = styled.h3`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 44px;
  font-weight: 600;
  line-height: normal;
  margin: 32px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
    margin: 24px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
    margin: 18px 0;
  }
`;

export const BenefitDescription = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }
`;


