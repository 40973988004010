import { Trans } from 'react-i18next';

import {
  DataProcessingArticleDescription,
  DataProcessingArticleHeading,
  DataProcessingArticleWrapper,
  StyledDataProcessingArticle,
} from './styled';

type DataProcessingArticleProps = {
  title: string;
  description: string;
};

const DataProcessingArticle = ({ title, description }: DataProcessingArticleProps) => {
  return (
    <DataProcessingArticleWrapper>
      <StyledDataProcessingArticle>
        <DataProcessingArticleHeading>{title}</DataProcessingArticleHeading>
        <DataProcessingArticleDescription variant="SECONDARY">
          <Trans i18nKey={description} />
        </DataProcessingArticleDescription>
      </StyledDataProcessingArticle>
    </DataProcessingArticleWrapper>
  );
};

export default DataProcessingArticle;
