import styled from 'styled-components';

import { LinkButton } from 'ui/atoms/button/Button';

type CapabilityProps = {
  id: number;
  image: string;
  title: string;
  description: string;
  additionalDescription: string;
  buttonLabel: string;
  link: string;
};

export const Capability = ({
  image,
  title,
  description,
  additionalDescription,
  buttonLabel,
  link,
}: CapabilityProps) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Title>{title}</Title>
        <img src={image} alt={title} />
      </ImageWrapper>
      <DescriptionWrapper>
        <span>{description}</span>
        <SmallDescription>{additionalDescription}</SmallDescription>
        <SmallDescription>
          For Environment Monitoring options contact us to directly to discuss details.
        </SmallDescription>
      </DescriptionWrapper>
      <ButtonWrapper>
        <LinkButton to={link} size="SM">
          {buttonLabel}
        </LinkButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 50%;
  max-width: 470px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 100%;
    max-width: none;
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    width: 84%;
    height: 50%;
    border: 2px solid ${({ theme }) => theme.colors.rawSienna};
  }

  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h3`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  margin: 0;
  padding: 0;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 44px;
  font-weight: 600;
  line-height: 1;
  max-width: 250px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    left: 15px;
    font-size: 28px;
    max-width: 200px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    max-width: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileTiny}px) {
    font-size: 22px;
    left: 10px;
    max-width: 100px;
  }
`;

const DescriptionWrapper = styled.p`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.tuatara};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }
`;

const SmallDescription = styled.span`
  font-size: 14px;
  line-height: 1.4;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }
`;

const ButtonWrapper = styled.div`
  width: 160px;
`;
