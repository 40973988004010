import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ROUTES } from 'common/routes';
import { altTheme, theme } from 'theme';
import { GlobalStyle } from 'GlobalStyle';

export const Style = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();

  return (
    <ThemeProvider theme={pathname.includes(ROUTES.digitalTwin) ? altTheme : theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
