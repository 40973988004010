import styled from 'styled-components';

import backgroundImage from 'assets/images/data/data-services-image.png';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { dataServicesTypes } from './dataServicesTypes';

export const DataServices = () => {
  return (
    <Section url={backgroundImage}>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Data Analytics Services"
        description="At TerraEye, we are not data resellers. Instead, we provide integrated data analytics services, offering valuable insights through advanced processing and analysis of satellite imagery. Our analytics services are designed to help you make informed decisions based on the latest and most accurate EO data."
        isBackgroundLight
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <ContentWrapper>
            <Title>Explanation of Satellite Data Types</Title>
            <Description>
              Understanding the different types of satellite data is crucial for utilizing them effectively. Here is a
              brief overview of the primary data types we use:
            </Description>
            <DataTypesWrapper>
              {dataServicesTypes.map(({ id, title, description }) => (
                <DataType key={id}>
                  <DataTypeTitle>{title}</DataTypeTitle>
                  <DataTypeDescription>{description}</DataTypeDescription>
                </DataType>
              ))}
            </DataTypesWrapper>
            <Title colorful>
              By combining these diverse data sources, TerraEye delivers comprehensive analytics tailored to meet the
              specific needs of our clients.
            </Title>
          </ContentWrapper>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => theme.colors.tuatara};
  margin-top: 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 40px;
  }
`;

const Title = styled.h3<{ colorful?: boolean }>`
  font-size: 44px;
  max-width: 780px;
  color: ${({ colorful, theme }) => colorful && theme.colors.rawSienna};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  max-width: 470px;
  font-size: 22px;
  font-weight: 500;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
  }
`;

const DataTypesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: start;
  gap: 50px 150px;
  margin: 20px 0 70px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr;
    gap: 40px 60px;
    margin: 10px 0 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    gap: 30px;
    margin: 0;
  }
`;

const DataType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  font-family: 'Barlow', sans-serif;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.rawSienna};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      left: -15px;
    }
  }
`;

const DataTypeTitle = styled.h4`
  font-size: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 22px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 18px;
  }
`;

const DataTypeDescription = styled.p`
  font-size: 14px;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }
`;
