import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';

import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from '../Container';
import FooterBottomContent from './FooterBottomContent';
import { companiesData } from './companyData';
import {
  FooterGrid,
  FooterTopContent,
  FooterLinkedin,
  FooterLogo,
  FooterNav,
  FooterNavList,
  StyledFooter,
  FooterAddresses,
  FooterAddressListItem,
} from './styled';
import rsbsLogo from 'assets/images/global/rsbs-logo.svg';
import rsbsLogoText from 'assets/images/global/rsbs-logo-text.svg';
import linkedinIcon from 'assets/images/footer/linkedin.svg';
import AppLink from 'components/AppLink';

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <StyledFooter pathname={pathname}>
      <PaddingWrapper size="small" type="inline">
        <Container size="wide" align="center">
          <FooterTopContent>
            <FooterGrid>
              <FooterLogo>
                <img src={rsbsLogo} alt="Remote Sensing Business Solutions" />
                <img src={rsbsLogoText} alt="Remote Sensing Business Solutions" />
              </FooterLogo>
              <FooterAddresses>
                <address>
                  <ul>
                    {companiesData.rsbsAddressData.map(({ id, content }) => (
                      <FooterAddressListItem key={id}>
                        {content} <br />
                      </FooterAddressListItem>
                    ))}
                  </ul>
                </address>

                <address>
                  <ul>
                    {companiesData.fourPointAddressData.map(({ id, content }) => (
                      <FooterAddressListItem key={id}>
                        {content} <br />
                      </FooterAddressListItem>
                    ))}
                  </ul>
                </address>
              </FooterAddresses>
              <FooterLinkedin>
                <img src={linkedinIcon} alt="Linkedin" />
                <a href={companiesData.linkedin.url} target="_blank" rel="noopener noreferrer">
                  {companiesData.linkedin.content}
                </a>
              </FooterLinkedin>
            </FooterGrid>

            <FooterNav>
              <FooterNavList>
                <li>
                  <AppLink noStyle>TerraEye App</AppLink>
                </li>
                <li>
                  <AppLink noStyle signUp>
                    Sign up
                  </AppLink>
                </li>
                {companiesData.links.map(({ id, content, path, newTab, smooth }) => (
                  <li key={id}>
                    {smooth ? (
                      <HashLink to={path} smooth={pathname === '/'}>
                        {content}
                      </HashLink>
                    ) : (
                      <Link to={path} target={newTab ? '_blank' : ''} rel={newTab ? 'noopener noreferrer' : ''}>
                        {content}
                      </Link>
                    )}
                  </li>
                ))}
              </FooterNavList>
            </FooterNav>
          </FooterTopContent>

          <FooterBottomContent />
        </Container>
      </PaddingWrapper>
    </StyledFooter>
  );
};

export default Footer;
