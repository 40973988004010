import { ROUTES } from 'common/routes';
import styled, { css } from 'styled-components';

type StyledFooterProps = {
  pathname: string;
};

export const StyledFooter = styled.footer<StyledFooterProps>`
  padding: 87px 0 34px;
  background-color: ${({ theme }) => theme.colors.mineShaftLight};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 40px 0 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 34px 0 15px;
  }

  ${({ pathname }) =>
    (pathname === '/' || pathname === ROUTES.contactUs || pathname === ROUTES.data || pathname === ROUTES.reports) &&
    css`
      background-color: ${({ theme }) => theme.colors.mineShaft};
    `}

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const FooterTopContent = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 100px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 22px;
    gap: 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    gap: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column-reverse;
    padding-bottom: 48px;
  }
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: auto 246px;
  grid-template-areas:
    'logo address'
    'logo link';
  gap: 27px 38px;
  justify-content: space-between;
  max-width: 454px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    gap: 27px 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: 210px;
    grid-template-areas:
      'logo'
      'address'
      'link';
    gap: 24px;
    width: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: repeat(2, minmax(120px, 180px));
    grid-template-areas:
      'logo address'
      'link address';
    gap: 10px 25px;
    max-width: 100%;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-self: start;
  gap: 12px;
  grid-area: logo;
  margin-top: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 8px;

    img:first-child {
      width: 33px;
    }

    img:last-child {
      width: 71px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    margin-top: 3px;
  }
`;

export const FooterAddresses = styled.div`
  font-size: 12px;
  line-height: 18px;
  grid-area: address;
  display: flex;
  flex-direction: column;
  gap: 26px;

  address {
    font-style: normal;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 10px;
    line-height: 14px;
    margin-top: 24px;
    gap: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    margin: 0;
    max-width: 140px;
    gap: 24px;
  }
`;

export const FooterAddressListItem = styled.li`
  &:first-child {
    font-weight: 600;
  }
`;

export const FooterLinkedin = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
  margin-left: -2px;
  grid-area: link;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    margin: 0;
    font-size: 10px;
    align-items: flex-end;
  }
`;

export const FooterNav = styled.nav`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  flex-shrink: 0;
  flex-grow: 1;

  a {
    display: block;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 11px;
  }
`;

export const FooterNavList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 14px 65px;

  a {
    transition: color 250ms ease-out;

    &:hover {
      color: ${({ theme }) => theme.colors.whiskey};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 20px;
    gap: 14px 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: repeat(2, minmax(120px, 180px));
    justify-content: space-between;
    column-gap: 20px;
  }
`;
