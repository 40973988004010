import { useEffect, useState } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';
import { persistentMonitoringData as data } from './persistentMonitoringData';
import { theme } from 'theme';

export const useCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dividerValue, setDividerValue] = useState(3);

  const matchesDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`);
  const matchesTablet = useMediaQuery(`(max-width: ${theme.breakpoints.tablet}px)`);
  const matchesMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile}px)`);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < theme.breakpoints.tablet) {
        if (screenWidth < theme.breakpoints.mobile) {
          setDividerValue(1);
          return;
        }
        setDividerValue(2);
      } else {
        setDividerValue(3);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(0);
  }, [matchesMobile, matchesTablet, matchesDesktop]);

  const handleNext = () => {
    if (currentIndex === data.length - dividerValue) {
      return;
    }

    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      return;
    }

    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  return {
    currentIndex,
    dividerValue,
    handlePrev,
    handleNext,
  };
};
