import PageHelmetWrapper from 'components/PageHelmetWrapper';
import SubPageLayout from 'components/SubPage';
import EcoSatSection from './EcoSatSection';
import { SubPageImage } from 'components/SubPageImage';
import ecoSatBackgroundDesktop from 'assets/images/ecoSat/ecosat-desktop.jpg';
import ecoSatBackgroundTablet from 'assets/images/ecoSat/ecosat-tablet.jpg';

const EcoSatPage = () => {
  return (
    <PageHelmetWrapper page="ecoSat">
      <SubPageLayout image={<SubPageImage desktopUrl={ecoSatBackgroundDesktop} tabletUrl={ecoSatBackgroundTablet} />}>
        <EcoSatSection />
      </SubPageLayout>
    </PageHelmetWrapper>
  );
};

export default EcoSatPage;
