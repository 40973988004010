import styled from 'styled-components';

import rebrandingImage from 'assets/images/rebranding/rebranding.jpeg';
import { ArticleContent, ArticlesList } from 'components/Article/styled';
import LegalPageLayout from 'components/LegalContentWrapper';
import { TextLink } from 'ui/atoms/textLink';

const terraEyeTextAndLink = (
  <TextLink to={'https://pl.linkedin.com/company/terraeye?trk=public_post-text'}>TerraEye</TextLink>
);

const RebrandingPage = () => {
  return (
    <LegalPageLayout title="Closing One Chapter to Open Another">
      <ArticlesList isNarrow>
        <Image url={rebrandingImage} />
        <ArticleContent>
          Farewell Four Point, Hello {terraEyeTextAndLink}
          <br />
          <br />
          Dear friends, colleagues, and valued connections, Today marks the end of an important chapter in our journey
          as we announce that Four Point is no longer. Building Four Point was an incredible experience that taught us
          invaluable lessons and shaped who we are today. We've made mistakes along the way, but each one has
          strengthened us, making us more resilient and wiser.
          <br />
          <br />
          With renewed focus and determination, we are thrilled to direct our energy towards TerraEye—our venture aimed
          at revolutionizing the mining and environmental monitoring industries. TerraEye specializes in AI-powered
          satellite data analytics, providing innovative solutions that make exploration more efficient, sustainable,
          and less risky.
          <br />
          <br />
          Our mission is to bring space-age technology to these industries, transforming how data is collected,
          processed, and analyzed. By leveraging advanced AI algorithms and building the world's largest exploration
          database, we're set on making a significant impact.
          <br />
          <br />
          We will always cherish the memories and achievements from our time at Four Point. Your support has been
          instrumental in our growth, and we couldn't be more grateful.
          <br />
          <br />
          We invite you to join us on this exciting new journey by following TerraEye's LinkedIn page. Stay connected to
          witness how we're pushing boundaries and driving innovation in the industry.
          <br />
          <br />
          Thank you for being part of our story. The best is yet to come!
          <br />
          <br />
          Warm regards,
          <br />
          The {terraEyeTextAndLink} Team
        </ArticleContent>
      </ArticlesList>
    </LegalPageLayout>
  );
};

const Image = styled.div<{ url: string }>`
  height: min(400px, 40vw);
  background: ${({ url }) => `center / cover no-repeat url(${url});`};
`;

export default RebrandingPage;
