import { keyframes } from 'styled-components';

export const SlideDesktop = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-100% - 76px)); 
  }
`;

export const SlideTablet = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-100% - 62px)); 
  }
`;

export const SlideMobile = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-100% - 50px)); 
  }
`;
