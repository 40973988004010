import styled, { css } from 'styled-components';
import { match } from 'ui/utils';

type ParagraphVariants = 'PRIMARY' | 'SECONDARY';
type ParagraphSizes = 'XL' | 'LG' | 'MD' | 'SM';
type ParagraphFontWeights = '300' | '400' | '500' | '600' | '700';
type ParagraphFontFamilies = 'Barlow' | 'Barlow Condensed';

export interface ParagraphProps {
  variant?: ParagraphVariants;
  size?: ParagraphSizes;
  fontWeight?: ParagraphFontWeights;
  fontFamily?: ParagraphFontFamilies;
}

export const Paragraph = styled.p<ParagraphProps>`
  font-family: 'Barlow', sans-serif;

  ${({ variant }) =>
    variant &&
    match(variant, {
      PRIMARY: () => css`
        color: ${({ theme }) => theme.colors.white};
      `,
      SECONDARY: () => css`
        color: ${({ theme }) => theme.colors.tuatara};
      `,
    })}

  ${({ size }) =>
    size &&
    match(size, {
      XL: () => css`
        font-size: 28px;
        font-weight: 300;
        line-height: 33.6px;
      `,
      LG: () => css`
        font-size: 22px;
        font-weight: 500;
        line-height: 26.4px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          font-size: 20px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          font-size: 16px;
        }
      `,
      MD: () => css`
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
      `,
      SM: () => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      `,
    })}

    ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `}

    ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily}, sans-serif;
    `}
`;

Paragraph.defaultProps = {
  variant: 'PRIMARY',
  size: 'SM',
  fontFamily: 'Barlow',
};
