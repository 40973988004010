import styled from 'styled-components';

import { ReactComponent as SearchIcon } from 'assets/svg/search-icon.svg';
import { ReactComponent as ClearIcon } from 'assets/svg/rounded-cross.svg';
import { ChangeEvent } from 'react';

interface SearchInputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ value, onChange }: SearchInputProps) => {
  return (
    <StyledISearchnput>
      <SearchIcon width={14} height={14} />
      <StyledInput value={value} onChange={onChange} type="text" placeholder="Search for location..." />
      {value.length > 0 && (
        <ClearButton
          onClick={() => {
            onChange({
              target: { value: '' },
            } as ChangeEvent<HTMLInputElement>);
          }}
        >
          <ClearIcon />
        </ClearButton>
      )}
    </StyledISearchnput>
  );
};

const StyledISearchnput = styled.div`
  position: relative;
  max-width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.masala};
  border-radius: 15px;
  padding: 0 30px 0 10px;
  gap: 8px;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
`;

const StyledInput = styled.input`
  color: inherit;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 600;

  &::placeholder {
    color: inherit;
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;
