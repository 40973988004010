import styled from 'styled-components';

import { SubPageImage } from 'components/SubPageImage';
import { Paragraph } from 'ui/atoms/paragraph';

export const TerraEyeAppInfoPageImage = styled(SubPageImage)`
  background-position: 50% 90%;
`;

export const TerraEyeAppInfoContent = styled.div`
  padding-bottom: 86px;

  a {
    font-size: 12px;
    max-width: 213px;
    height: 52px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    padding-bottom: 100px;

    a {
      max-width: 100%;
      height: 50px;
    }
  }
`;

export const TerraEyeAppInfoDescription = styled(Paragraph)`
  margin: 25px 0 56px;
`;
