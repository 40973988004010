import {
  RequestAccessAreaSelectorDescription,
  RequestAccessAreaSelectorHeader,
  RequestAccessAreaSelectorWrapper,
} from 'pages/RequestAccesPage/RequestAccessSection/RequestAccessAreaSelector/styled';
import { Polygon, Status } from 'types';
import { Wrapper } from 'ui/atoms/wrapper';
import { AreaSelector } from 'ui/organisms/areaSelector';

interface RequestAccessAreaSelectorCoreProps {
  setPolygonAttachment: React.Dispatch<React.SetStateAction<Polygon | null>>;
  status: Status;
}

export const RequestAccessAreaSelector = ({ setPolygonAttachment, status }: RequestAccessAreaSelectorCoreProps) => (
  <RequestAccessAreaSelectorWrapper>
    <RequestAccessAreaSelectorHeader>Do you have an area in mind?</RequestAccessAreaSelectorHeader>
    <RequestAccessAreaSelectorDescription>
      Optionally, you can use the map below to select an area of interest. Select an area between 10 and 500 km². We can
      use this information to negotiate with you your needs and discuss costs.
    </RequestAccessAreaSelectorDescription>
    <Wrapper top={48}>
      <AreaSelector setPolygonAttachment={setPolygonAttachment} status={status} />
    </Wrapper>
  </RequestAccessAreaSelectorWrapper>
);
