import backgroundImage from 'assets/images/reports/reports-image.png';
import SectionHeader from 'components/SectionHeader';
import { SubpageHero } from 'components/SubpageHero';

export const ReportsPageHero = () => {
  return (
    <SubpageHero url={backgroundImage}>
      <SectionHeader
        variant="SECONDARY_H1"
        title="Custom Reports"
        description={
          <>
            Remote Sensing Reports with Interpretation
            <br /> tailored to your needs.
          </>
        }
      />
    </SubpageHero>
  );
};
