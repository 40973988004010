import styled from 'styled-components';

import { ReactComponent as PlusSign } from 'assets/svg/plusSign.svg';
import { ReactComponent as MinusSign } from 'assets/svg/minusSign.svg';
import { MapControlButton } from 'ui/molecules/mapControlButton';

interface CustomZoomControlProps {
  zoom: number;
  setZoom: (zoom: number) => void;
}

export const CustomZoomControl = ({ zoom, setZoom }: CustomZoomControlProps) => {
  const increaseZoom = () => setZoom(Math.ceil(Number(zoom) + 1));
  const decreaseZoom = () => setZoom(Math.floor(Number(zoom) - 1));

  return (
    <ZoomControlWrapper>
      <MapControlButton variant="UPPER" icon={<PlusSign />} iconWidth={8} onClick={increaseZoom} />
      <MapControlButton variant="LOWER" icon={<MinusSign />} iconWidth={8} onClick={decreaseZoom} />
    </ZoomControlWrapper>
  );
};

const ZoomControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;
