import { getVideoFormat } from 'utils/getVideoFormat';
import DataProcessingArticle from '../DataProcessingArticle';
import { articlesData } from '../articlesData';
import { mobileVideosData } from './mobileVideosData';
import {
  VideosFlex,
  MobileViewVideos,
  StyledMobileView,
  ElementContainer,
  MobileViewElement,
  SideIcon,
  ElementWrapper,
} from './styled';

type MobileViewProps = {
  hide: boolean;
};

const MobileView = ({ hide }: MobileViewProps) => {
  return (
    <StyledMobileView hide={hide}>
      <MobileViewVideos>
        {mobileVideosData.map(({ id, src, type, sideIconSrc }, index) => {
          const matchingArticle = articlesData[index];

          return (
            <VideosFlex key={id}>
              <ElementContainer>
                <ElementWrapper>
                  {type === 'video' && (
                    <MobileViewElement muted autoPlay playsInline loop>
                      <source src={src} type={`video/${getVideoFormat()}`} />
                    </MobileViewElement>
                  )}
                  {type === 'image' && <MobileViewElement as="img" src={require(`assets/${src}`)} alt="" />}

                  {sideIconSrc && <SideIcon src={require(`assets/${sideIconSrc}`)} alt="" />}
                </ElementWrapper>
              </ElementContainer>
              <DataProcessingArticle title={matchingArticle.title} description={matchingArticle.description} />
            </VideosFlex>
          );
        })}
      </MobileViewVideos>
    </StyledMobileView>
  );
};

export default MobileView;
