import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';

export const StyledCarouselItem = styled.article`
  font-family: 'Barlow Condensed', sans-serif;
  color: ${({ theme }) => theme.colors.white};
  padding: 106px 95px 0 0;
  position: relative;
  transition: transform 0.5s ease-in-out;
  flex: 0 0 calc(100% / 3 + 1px);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 185px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.rawSienna};
  }

  &:first-child img {
    bottom: -8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 102px 90px 0 0;
    flex: 0 0 calc(100% / 2 + 1px);

    &::before {
      top: 169px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex: 0 0 calc(100% + 1px);
  }
`;

export const CarouselItemImageContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 83px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 76px;
  }
`;

export const CarouselItemImage = styled.img`
  position: absolute;
  bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 76px;
  }
`;

export const CarouselItemTitle = styled.div`
  font-size: 34px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 28px;
  }
`;

export const CarouselItemLine = styled.div`
  position: relative;
  height: 1px;
  margin: 42px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 38px 0;
  }
`;

export const CarouselItemHeading = styled.h3`
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 20px;
  }
`;

export const CarouselItemDescription = styled(Paragraph)`
  margin-top: 23px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }
`;
