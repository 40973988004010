export const helmetMedaData = {
  home: {
    title: 'TerraEye',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  digitalTwinHome: {
    title: 'TerraEye - Digital Twin',
    description: 'Transforming Lithium Brine Extraction - revolutionize your mining operations with the cutting-edge Digital Twin Platform for Salars.',
  },
  requestAccess: {
    title: 'TerraEye - Contact Us',
    description: 'TerraEye - feel free to reach out to us with questions regarding collaboration or specific areas of interest for your exploration needs.',
  },
  digitalTwinContactUs: {
    title: 'Digital Twin - Contact Us',
    description: 'Digital Twin - feel free to reach out to us with questions regarding collaboration or specific areas of interest for your exploration needs.',
  },
  caseStudy1: {
    title: 'TerraEye: Spaceborne Mineral Mapping Using EnMap Hyperspectral Data',
    description:
      'This case study presents TerraEye\'s test results derived from a variety of different mapping methods and approaches using hyperspectral data from EnMAP mission.',
  },
  caseStudy2: {
    title: 'TerraEye: TerraEye for Mineral Prospecting',
    description:
      'A quick look at the services provided by TerraEye in terms of Mineral Prospecting, summarizing the inner-working processes and ways in which we cooperate with our clients.',
  },
  caseStudy3: {
    title: 'TerraEye: An Eye Over Mining Areas',
    description:
      'Examples of Geospatial Data Analyses for Better Environmental Monitoring, Decision-Making, ESG Reporting and Due Diligence for Mining collected into one booklet.',
  },
  caseStudy4: {
    title: 'TerraEye: Satellite-Driven Biodiversity Monitoring',
    description:
      'Leveraging advanced satellite data analytics, this study underscores the pivotal role of AI in driving tangible improvements in environmental conservation and biodiversity.',
  },
  caseStudy5: {
    title: 'TerraEye: Ensuring Infrastructure Safety through Satellite Monitoring',
    description:
      'TerraEye\'s AI-driven satellite insights safeguard critical infrastructures, offering real-time monitoring around pipelines, roads, and rails in high-risk industries.',
  },
  caseStudy6: {
    title: 'TerraEye: A Revolution in GHG Management and Sustainability',
    description:
      'TerraEye\'s satellite analytics offers transformative insights into GHG emissions, enabling businesses to enhance sustainability and meet regulatory requirements.',
  },
  caseStudy7: {
    title: 'TerraEye: Biodiversity Conservation and Mining Impact Analysis',
    description:
      'TerraEye\'s satellite analytics revolutionizes biodiversity conservation, offering insights into land use changes, water body dynamics, and mining impacts.',
  },
  caseStudy8: {
    title: 'TerraEye: Infiltration Leakage Monitoring',
    description:
      'TerraEye\'s satellite analytics unveils transformative insights into water infiltration issues, spotlighting the Sierra Gorda SCM tailing pond case.',
  },
  caseStudy9: {
    title: 'TerraEye: Land Transformation Monitoring',
    description:
      'Discover how TerraEye\'s AI-powered satellite data processing revolutionizes land transformation monitoring at an industrial site in Pune, India.',
  },
  caseStudy10: {
    title: 'TerraEye: Advanced Water Monitoring',
    description:
      'Explore how TerraEye\'s satellite analytics revolutionizes water monitoring, from detecting surface area changes to assessing water quality in reservoirs.',
  },
  ecoSat: {
    title: 'TerraEye - EcoSat',
    description: 'Remote Sensing Environmental Solutions is implementing a project subsidized by European Funds EcoSat',
  },
  mineCam: {
    title: 'TerraEye - MineCam',
    description: 'Remote Sensing Business Solutions is implementing the MineCam project subsidized by European Funds',
  },
  hype4Explor: {
    title: 'TerraEye - Hype4Explor',
    description: 'Hype4Explor: Development of an Application to Support Mineral Prospecting for Deposits Necessary in the Energy Transition Processes',
  },
  privacyPolicy: {
    title: 'TerraEye - Privacy Policy',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  digitalTwinPrivacyPolicy: {
    title: 'Digital Twin - Privacy Policy',
    description: 'Transforming Lithium Brine Extraction - revolutionize your mining operations with the cutting-edge Digital Twin Platform for Salars.',
  },
  privacyPolicyTerraeyeApp: {
    title: 'TerraEye - Privacy policy of the TerraEye App',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  newsletterRegulations: {
    title: 'TerraEye - Newsletter Regulations',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  termsAndConditionsDgp: {
    title: 'TerraEye - Terms & Conditions of Digital Goods Provision',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  termsAndConditions: {
    title: 'TerraEye - Terms & Conditions',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  gdpr: {
    title: 'TerraEye - GDPR',
    description: 'TerraEye Jumpstarting your next mineral exploration with AI-powered satellite data processing',
  },
  pricing: {
    title: 'TerraEye - Pricing',
    description: 'Test out TerraEye and choose the best pricing option for your needs.',
  },
  data: {
    title: 'TerraEye - Data',
    description: 'See how TerraEye processes data and how we used it in our case studies.',
  },
  reports: {
    title: 'TerraEye - Custom Reports',
    description: 'Remote Sensing Reports with Interpretation tailored to your needs.',
  },
};
