import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';
import checkmark from './checkmark.svg';
import altCheckmark from './altCheckmark.svg';

interface LabeledCheckboxProps {
  children?: React.ReactNode;
  id: string;
  name: string;
  checked: boolean;
  altCheck?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const LabeledCheckbox = ({ children, id, name, checked, altCheck, onChange }: LabeledCheckboxProps) => {
  return (
    <LabeledCheckboxWrapper>
      <Checkbox id={id} type="checkbox" name={name} checked={checked} onChange={onChange} altCheck={altCheck} />
      <CheckboxLabel htmlFor={id}>{children}</CheckboxLabel>
    </LabeledCheckboxWrapper>
  );
};

export const LabeledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const Checkbox = styled.input<{ altCheck?: boolean }>`
  display: block;
  appearance: none;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.rawSienna};
  transition: background-color 80ms ease-in-out;
  cursor: pointer;

  &:checked {
    background: no-repeat center url(${({ altCheck }) => altCheck ? altCheckmark : checkmark});
  }
`;

type CheckboxLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export const CheckboxLabel = styled(Paragraph).attrs({ as: 'label' })<CheckboxLabelProps>`
  margin-top: -1px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: -3px;
  }
`;
