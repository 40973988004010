export const MONITORING_PROJECTS_LIST = [
  {
    id: 0,
    label: 'Mine Detection (segmentation)',
  },
  {
    id: 1,
    label: 'Land Cover',
  },
  {
    id: 2,
    label: 'Ground Deformation',
  },
  {
    id: 3,
    label: 'Water Detection',
  },
  {
    id: 4,
    label: 'Reports',
  },
];
