import ArticleWrapper from 'components/Article/ArticleWrapper';
import { ArticleOrderedList, ArticlesList } from 'components/Article/styled';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import Hype4ExplorTable from './Hyper4ExplorTable';
import { Hype4ExplorSectionContent, StyledHype4ExplorSection } from './styled';

const Hype4ExplorSection = () => {
  return (
    <StyledHype4ExplorSection>
      <SectionHeader title="Hype4Explor" />
      <Hype4ExplorSectionContent>
        <PaddingWrapper size="large" type="right">
          <Container size="small" align="start">
            <PaddingWrapper size="large" type="left">
              <ArticlesList>
                <ArticleWrapper title="Project title" boldParagraph>
                  HYPE4EXPLOR: Development of an Application to Support Mineral Prospecting for Deposits Necessary in
                  the Energy Transition Processes, Based on a Developed Technology Utilizing Satellite Imagery and
                  Artificial Intelligence Algorithms
                </ArticleWrapper>

                <ArticleWrapper title="Project summary">
                  The project will create an application in which we will implement a decision-supporting system for the
                  mining and mineral prospecting industries, to help them in prospecting and exploration activities. The
                  development of the system will include ML and AI algorithms, as well as a range of geological data to
                  support deposit benchmarking based on satellite data. Works in the project will focus on one of the
                  previously selected geological concepts, i.e. Porphyry Copper Deposits (PCD), which are the main
                  source of copper in the world. Due to their recognized and still undiscovered potential for the
                  production of critical raw materials, this type of deposit is still the subject of scientific
                  research. Our goal is to provide a commercial tool for mining, junior mining, and mineral prospecting
                  companies to support the decision-making process in prospecting for PCDs. The proposed solution is
                  based on the analysis of available spectral data using ML and AI algorithms. The proposed service
                  based on the application resulting from the project will consist in:
                  <ArticleOrderedList isSummaryList>
                    <li>
                      Automatic acquisition and processing of hyperspectral and multispectral imagery for chosen areas
                      of interest;
                    </li>
                    <li>Generating maps of surface alteration and mineralization zones for individual minerals;</li>
                    <li>Classification and benchmarking of potential deposit parameters based on AI models.</li>
                  </ArticleOrderedList>
                  In the project we assume carrying out research and development work in order to create a fully
                  functional application.
                </ArticleWrapper>

                <ArticleWrapper
                  title="Contract ID"
                  paragraphProps={{
                    fontFamily: 'Barlow Condensed',
                    size: 'LG',
                  }}
                >
                  POIR.01.01.01-00-0419/22-00
                </ArticleWrapper>
              </ArticlesList>

              <Hype4ExplorTable />
            </PaddingWrapper>
          </Container>
        </PaddingWrapper>
      </Hype4ExplorSectionContent>
    </StyledHype4ExplorSection>
  );
};

export default Hype4ExplorSection;
