import styled, { css } from 'styled-components';

type StyledMobileViewProps = {
  hide: boolean;
};

export const StyledMobileView = styled.div<StyledMobileViewProps>`
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `};
`;

export const MobileViewVideos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideosFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    &:nth-child(4) h3 {
      max-width: 200px;
    }
  }
`;

export const ElementContainer = styled.div`
  padding: 0 126px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 40px;
  }
`;

export const ElementWrapper = styled.div`
  position: relative;
  max-width: 548px;
  width: 100%;
  margin: 0 auto;
`;

export const MobileViewElement = styled.video`
  display: block;
  width: 100%;
`;

export const SideIcon = styled.img`
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  right: -42px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 77px;
    right: -38px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 60px;
    right: -30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 44px;
    right: -22px;
  }
`;
