import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { match } from 'ui/utils';

export type BaseButtonVariants = 'PRIMARY' | 'SECONDARY' | 'SECONDARY_COLOR' | 'LIGHT';
export type BaseButtonSizes = 'LG' | 'SM';

interface BaseButtonProps {
  variant?: BaseButtonVariants;
  size?: BaseButtonSizes;
  loading?: 'true' | 'false';
  width?: number;
  height?: number;
}

export const BaseButton = styled.div<BaseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 500ms ease-out;
  cursor: pointer;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};

  ${({ size }) =>
    size &&
    match(size, {
      LG: () => css`
        font-size: 14px;
        height: 60px;
      `,
      SM: () => css`
        font-size: 14px;
        height: 40px;
      `,
    })}

  ${({ variant }) =>
    variant &&
    match(variant, {
      PRIMARY: () => css`
        background-color: ${({ theme }) => theme.colors.copper};
        transition: background-color 250ms ease-out;

        &:hover {
          background-color: ${({ theme }) => theme.colors.rawSienna};
        }
      `,
      SECONDARY: () => css`
        border: 1px solid ${({ theme }) => theme.colors.white};
        background-color: hsla(0, 0%, 100%, 0.1);

        &:hover {
          background-color: hsla(0, 0%, 100%, 0.3);
        }
      `,
      SECONDARY_COLOR: () => css`
        border: 1px solid ${({ theme }) => theme.colors.rawSienna};
        color: ${({ theme }) => theme.colors.rawSienna};
        background-color: transparent;
      `,
      LIGHT: () => css`
        color: ${({ theme }) => theme.colors.copper};
        background-color: ${({ theme }) => theme.colors.lightWhite};

        &:hover {
          background-color: ${({ theme }) => theme.colors.white};
        }
      `,
    })}

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray};
  }

  ${({ loading }) =>
    loading === 'true' &&
    css`
      pointer-events: none;
    `}
`;

BaseButton.defaultProps = {
  variant: 'PRIMARY',
  size: 'LG',
  loading: 'false',
};

export const LinkButton = styled(BaseButton).attrs({ as: Link })<LinkProps>``;
