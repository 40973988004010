export const hyper4ExplorTableData = [
  {
    id: 1,
    header: 'Industrial research',
    eligibleExpenses: 4724725,
    grant: 3779780,
  },
  {
    id: 2,
    header: 'Development work',
    eligibleExpenses: 1362355,
    grant: 817413,
  },
  {
    id: 3,
    header: 'Pre-implementation - de minimis aid',
    eligibleExpenses: 0,
    grant: 0,
  },
  {
    id: 4,
    header: 'Pre-implementation - consulting services',
    eligibleExpenses: 0,
    grant: 0,
  },
];
