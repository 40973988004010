import { ROUTES } from 'common/routes';
import { CaseStudyFileType } from 'utils/caseStudies';
import {
  CaseStudyButton,
  CaseStudyDescription,
  CaseStudyImageWrapper,
  CaseStudyTitle,
  StyledCaseStudy,
} from './styled';

type CaseStudyProps = {
  homepageTitle: string;
  homepageDescription: string;
  image: string;
  fileType: CaseStudyFileType;
  id: string;
  link: string;
  altColor: boolean;
};

const CaseStudy = ({ homepageTitle, homepageDescription, image, fileType, id, link, altColor }: CaseStudyProps) => {
  const buttonLabel = `get the ${fileType === 'flyer' ? 'flyer' : fileType === 'booklet' ? 'booklet' : 'study'}`;

  return (
    <StyledCaseStudy>
      <div>
        <CaseStudyImageWrapper altColor={altColor}>
          <CaseStudyTitle>{homepageTitle}</CaseStudyTitle>
          <img src={image} alt={homepageTitle} />
        </CaseStudyImageWrapper>
        <CaseStudyDescription variant={altColor ? 'SECONDARY' : 'PRIMARY'}>{homepageDescription}</CaseStudyDescription>
      </div>
      <CaseStudyButton to={ROUTES.caseStudies.replace(':id', link)} size="SM">
        {buttonLabel}
      </CaseStudyButton>
    </StyledCaseStudy>
  );
};

export default CaseStudy;
