import { logotypesData } from './logotypesData';
import { StyledFooterBottomContent, StyledFooterLogotypes } from './styled';

const FooterBottomContent = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooterBottomContent>
      <p>Remote Sensing Business Solutions &copy; {currentYear}</p>
      <StyledFooterLogotypes>
        {logotypesData.map(({ id, image, alt }) => (
          <img key={id} src={require(`assets/images/${image}`)} alt={alt} />
        ))}
      </StyledFooterLogotypes>
    </StyledFooterBottomContent>
  );
};

export default FooterBottomContent;
