import styled, { css } from 'styled-components';

import AppLink from 'components/AppLink';
import { SubscriptionColorsConfig } from '../types';

export const StyledSubscriptionItem = styled.article<{
  $bgColor: SubscriptionColorsConfig['bgColor'];
  color: SubscriptionColorsConfig['color'];
}>`
  display: flex;
  flex-direction: column;
  font-family: 'Barlow Condensed', sans-serif;
  position: relative;
  padding: 46px 30px 48px;
  border: 1px solid ${({ theme }) => theme.colors.tuatara};
  background-color: ${({ theme, $bgColor }) => ($bgColor ? theme.colors[$bgColor] : 'transparent')};
  color: ${({ theme, color }) => theme.colors[color]};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 46px 18px 48px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 36px 30px 42px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    padding: 36px 18px 42px;
  }

  max-width: 16.25rem;
  max-width: clamp(16.25rem, 13.6rem + 11.48vw, 19.0625rem);
`;

export const SubscriptionTitle = styled.h4`
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  max-width: 160px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    font-size: 27px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
    line-height: 28px;
    margin-right: 40px;
  }
`;

export const FeaturesList = styled.ul`
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 20px;
  margin: 0 0 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 14px;
    line-height: 18px;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }
`;

interface PointsInfoProps {
  color: SubscriptionColorsConfig['color'];
}

export const SubscriptionBottomContent = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PointsInfo = styled.p<PointsInfoProps>`
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
  margin: 30px 0;
  color: ${({ theme, color }) => theme.colors[color]};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 19px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
  }
`;

interface StartExploringButtonProps {
  $bgColor: SubscriptionColorsConfig['themeBgColor'];
}

export const StartExploringButton = styled(AppLink)<StartExploringButtonProps>`
  width: 200px;
  margin: 0 auto;
  font-family: 'Barlow', sans-serif;
  background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  transition-property: opacity;

  &:hover {
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    opacity: 0.8;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 165px;
    font-size: 12px;
  }
`;

export const PriceTile = styled.div<{ $bgColor: SubscriptionColorsConfig['themeBgColor'] }>`
  position: absolute;
  top: 20px;
  right: -20px;
  padding: 16px 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
  text-transform: uppercase;
  min-width: 124px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    right: -32px;
    padding: 10px 6px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    right: -20px;
    padding: 16px 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    right: -32px;
    padding: 10px 6px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    right: -20px;
    padding: 16px 8px;
  }
`;

export const PreviousPriceWrapper = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 4px;
  }
`;

export const PreviousPriceLabel = styled.span`
  font-family: 'Barlow', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 8px;
  }
`;

export const Price = styled.span<{ oldPrice?: boolean }>`
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: ${({ oldPrice }) => (oldPrice ? '24px' : '50px')};
  font-weight: 600;
  line-height: 1;
  color: ${({ theme, oldPrice }) => oldPrice && theme.colors.whiteTransparent50};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: ${({ oldPrice }) => (oldPrice ? '20px' : '40px')};
  }

  ${({ oldPrice }) =>
    oldPrice &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        display: block;
        height: 2px;
        width: 62px;
        background-color: ${({ theme }) => theme.colors.white};
        transform: rotate(22deg);

        @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
          top: 11px;
          width: 56px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
          width: 52px;
        }
      }
    `}
`;

export const Currency = styled.span<{ transparent?: boolean }>`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  color: ${({ theme, transparent }) => transparent && theme.colors.whiteTransparent50};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 12px;
  }
`;

export const Period = styled.span`
  display: block;
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.14em;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 14px;
  }
`;

export const ButtonWrapper = styled.span`
  width: 200px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 165px;
  }
`;

export const ButtonLabel = styled.span`
  font-family: 'Barlow', sans-serif;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }
`;

export const PriceRemark = styled.p`
  font-family: 'Barlow', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  white-space: break-spaces;
  width: 80px;
  margin-bottom: 8px;
`;
