import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as FitInIcon } from 'assets/svg/fitInIcon.svg';
import { MapControlButton } from 'ui/molecules/mapControlButton';
import { CustomZoomControl } from 'ui/molecules/customZoomControl';
import { CustomMapScale } from 'ui/molecules/customMapScale';
import { ScaleHeight, ScaleLabel } from 'types';

interface MapToolboxProps {
  children?: ReactNode;
  toolboxData: {
    scaleLabel?: ScaleLabel | undefined;
    scaleHeight?: ScaleHeight | undefined;
    isReportCreatorActive?: boolean;
    zoom?: number | undefined;
    setZoom?: (zoom: number) => void;
    alignMap?: () => void;
  };
}

export const MapToolbox = ({
  children,
  toolboxData: { scaleLabel, scaleHeight, isReportCreatorActive, zoom, setZoom, alignMap },
}: MapToolboxProps) => (
  <>
    <ToolboxWrapper>
      {setZoom && <CustomZoomControl zoom={zoom || 0} setZoom={setZoom} />}
      {alignMap && <MapControlButton icon={<FitInIcon />} iconWidth={14} active={false} onClick={alignMap} />}
      {children}
      <CustomMapScale
        label={scaleLabel?.app}
        height={scaleHeight?.app}
        variant={isReportCreatorActive ? 'DARK' : 'LIGHT'}
      />
    </ToolboxWrapper>
  </>
);

const ToolboxWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
`;

MapToolbox.defaultProps = {};
