import { Navigate, useParams } from 'react-router';

import PageHelmetWrapper from 'components/PageHelmetWrapper';
import SubPageLayout from 'components/SubPage';
import { SubPageImage } from 'components/SubPageImage';
import { helmetMedaData } from 'helmetMetaData';
import { getCaseStudy } from 'utils/caseStudies';
import CaseStudySection from './CaseStudySection';

const CaseStudyPage = () => {
  const { id } = useParams();
  const caseStudy = getCaseStudy(id);

  if (!caseStudy || !id) return <Navigate to="/" />;

  const page = `caseStudy${caseStudy.no}` as keyof typeof helmetMedaData;

  return (
    <PageHelmetWrapper page={page}>
      <SubPageLayout
        image={<SubPageImage desktopUrl={caseStudy.subpage.image.desktop} tabletUrl={caseStudy.subpage.image.tablet} />}
      >
        <CaseStudySection caseStudy={caseStudy} />
      </SubPageLayout>
    </PageHelmetWrapper>
  );
};

export default CaseStudyPage;
