import styled, { css } from 'styled-components';
import { Paragraph } from 'ui/atoms/paragraph';

interface ArticlesListProps {
  isNarrow?: boolean;
}

export const ArticlesList = styled.ul<ArticlesListProps>`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${({ isNarrow }) => `${isNarrow ? 30 : 60}px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: ${({ isNarrow }) => `${isNarrow ? 20 : 40}px`};
  }
`;

interface ArticleTitleProps {
  isSmall?: boolean;
  color?: string;
}

export const ArticleTitle = styled.h3<ArticleTitleProps>`
  font-size: 32px;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 300;
  color: ${({ theme, color }) => (color ? color : theme.colors.rawSienna)};
  margin: 0 0 22px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 18px;
    margin: 0 0 20px;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 22px;
      font-weight: 500;
      line-height: normal;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: 20px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 16px;
      }
    `}
`;

type ArticleListProps = {
  isSummaryList?: boolean;
};

const articleOrderedListStyles = css`
  padding-left: 16px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    line-height: 20px;
  }

  > li {
    padding-left: 3px;
  }
`

export const ArticleOrderedList = styled.ol<ArticleListProps>`
  ${articleOrderedListStyles};

  ${({ isSummaryList }) =>
    isSummaryList &&
    css`
      margin: 20px 0;
      gap: 10px;
    `}
`;

export const ArticleOrderedSublist = styled(ArticleOrderedList) <{mt?: number}>`
  margin-top: ${({ mt }) => `${mt !== undefined ? mt : 10}px`};
  padding-left: 4px;
  list-style-type: none;
  counter-reset: my-counter;

  > li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  > li::before {
    content: counter(my-counter) ') ';
    counter-increment: my-counter;
    align-self: flex-start;
  }
`;

const articleUnorderedListStyles = css`
  list-style: none;
  margin-top: 9px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
    padding-left: 3px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    line-height: 20px;
  }

  > li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  > li::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 8px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      width: 3px;
      height: 3px;
    }
  }
`;

export const ArticleUnorderedSublist = styled.ul<{ mt?: number }>`
  ${articleUnorderedListStyles};
  margin-top: ${({ mt }) => `${mt !== undefined ? mt : 9}px`};
`;

export const ArticleContent = styled(Paragraph) <{ bold?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    line-height: 20px;
  }

  ${({ bold }) =>
    bold &&
    css`
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: 26.4px;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 16px;
        line-height: 19.2px;
      }
    `}

    ol {
      ${articleOrderedListStyles}
    }

    ul {
      ${articleUnorderedListStyles}
    }
`;

interface TableWrapperProps {
  isQuad?: boolean;
  gridCols?: string;
  minWidth?: number;
}

export const TableWrapper = styled(ArticleContent).attrs({ as: 'div' })<TableWrapperProps>`
  display: grid;

  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    border-spacing: 0;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.tapaLight};
      border-radius: 8px;
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  }

  thead,
  tbody {
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '600px')};
    display: block;
  }

  thead {
    margin-bottom: -2px;
  }

  tr {
    display: grid;
    grid-template-columns: ${({ gridCols }) => (gridCols ? gridCols : '26fr 39fr 39fr')};
    margin-bottom: -1px;

    &:last-child {
      margin-bottom: 1px;
    }
  }

  th,
  td {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 10px;
    text-align: start;
    border: 1px solid ${({ theme }) => theme.colors.rawSienna};

    &:not(:last-child) {
      margin-right: -1px;
    }
  }
`;
