import { Heading } from 'ui/atoms/heading';
import { Paragraph } from 'ui/atoms/paragraph';
import { SubscriptionItem } from './SubscriptionItem';
import {
  SubscriptionsGrid,
  SubscriptionsInfoWrapper,
  SubscriptionsSkeleton,
} from './styled';
import { useGetSubscriptionsInfo } from './useGetSubscriptionsInfo';

export const Subscriptions = () => {
  const { subscriptionsData, status } = useGetSubscriptionsInfo();

  return (
    <section>
      <Heading as="h3" variant="SECONDARY" color="tuatara">
        Subscription Options for TerraEye Application
      </Heading>

      <SubscriptionsInfoWrapper>
        {status === 'pending' && <SubscriptionsSkeleton />}
        {status === 'success' && subscriptionsData && (
          <SubscriptionsGrid>
            {subscriptionsData.map((subscription) => (
              <SubscriptionItem key={subscription.label} subscription={subscription} />
            ))}
          </SubscriptionsGrid>
        )}
        {status === 'error' && (
          <Paragraph size="LG" fontFamily="Barlow Condensed">
            Could not get subscriptions info. Please refresh the page.
          </Paragraph>
        )}
      </SubscriptionsInfoWrapper>
    </section>
  );
};
