import styled, { css } from 'styled-components';

import { ReactComponent as LogoImage } from 'assets/images/hero/terraeye-logo.svg';
import { ReactComponent as LogoTextImage } from 'assets/images/hero/terraeye-logo-text.svg';

export const StyledNavigation = styled.nav`
  position: absolute;
  z-index: 1200;
`;

type StyledNavigationProps = {
  isLightBackground: boolean;
};

export const NavigationWrapper = styled.div<StyledNavigationProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  min-height: 90px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 65px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-height: 50px;
  }

  &::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    mix-blend-mode: normal;
    backdrop-filter: blur(15px);
    z-index: -1;
    transition: opacity 300ms linear;
  }

  &::before {
    background-image: linear-gradient(0deg, rgba(43, 42, 41, 0.5), rgba(43, 42, 41, 0.5)),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.01) 100%);
    opacity: 1;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.01) 100%);
    z-index: -1;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
  }

  ${({ isLightBackground }) =>
    isLightBackground &&
    css`
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }

      ${Hamburger} {
        span {
          background-color: ${({ theme }) => theme.colors.tuatara};
        }
      }

      ${NavigationLogos} {
        color: ${({ theme }) => theme.colors.tuatara};
      }
    `}
`;

export const NavigationContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationLogos = styled.div<{ paddingRight?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.colors.white};
  transition: color 300ms linear;
  padding-right: ${({ paddingRight }) => paddingRight && '23px'};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    gap: 12px;
    padding-right: 19px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 10px;
    padding-right: 17px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileTiny}px) {
    padding-right: 10px;
  }
`;

export const NavigationLogosWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NavigationLogo = styled(LogoImage)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    width: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 18px;
  }
`;

export const NavigationLogoText = styled(LogoTextImage)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    width: 118px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 108px;
  }
`;

export const NavigationDigitalTwinHeader = styled.h2`
  position: relative;
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.whiskey};
  padding-bottom: 2px;
  margin-left: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    font-size: 20px;
    padding-bottom: 0;
    margin-left: 21px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
    margin-top: 1px;
    margin-left: 19px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileTiny}px) {
    font-size: clamp(8px, 3.5vw, 14px);
    margin-top: 2px;
    margin-right: 1em;
    margin-left: 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -25px;
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.whiskey};

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
      left: -21px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      left: -19px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileTiny}px) {
      display: none;
    }
  }
`;

type HamburgerProps = {
  open: boolean;
};

export const Hamburger = styled.button<HamburgerProps>`
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  position: relative;
  width: 24px;
  height: 18px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 20px;
    height: 14px;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 1;
    transform: rotate(0deg);
    transition: all 300ms linear;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    :nth-child(3) {
      top: 50%;
    }

    &:nth-child(4) {
      top: 100%;
    }

    ${({ open }) =>
    open &&
    css`
        &:nth-child(1),
        :nth-child(4) {
          top: 12px;
          width: 0%;
          left: 50%;
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      `}
  }
`;

type BackdropProps = {
  open: boolean;
};

export const Backdrop = styled.div<BackdropProps>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 88;
  background-color: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;

  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
