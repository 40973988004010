import styled from 'styled-components';

import { ROUTES } from 'common/routes';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import { Capability } from './Capability/Capability';

const capabilitiesData = [
  {
    id: 1,
    image: require('assets/images/capabilities/custom-reports.jpg'),
    title: 'Custom Reports',
    description:
      'Discover comprehensive remote sensing reports tailored to your mineral exploration needs. Leveraging advanced satellite data processing and artificial intelligence, TerraEye delivers precise and actionable insights to enhance your exploration projects.',
    additionalDescription:
      'Flexible pricing for Basic reports and Advanced versions based on multiple satellite constellations, Bare earth composite and ML algorithms.',
    buttonLabel: 'learn more',
    link: ROUTES.reports,
  },
  {
    id: 2,
    image: require('assets/images/capabilities/environmental-solutions.jpg'),
    title: 'Environmental Solutions',
    description:
      "Environmental monitoring is one of TerraEye's strong capabilities. Thanks to our application, you can have an in-depth environmental analysis of selected areas at any time.",
    additionalDescription:
      'We provide many features related to environment monitoring. Because of the multitude of possibilities we discuss and customize the plan directly with you to optimize both the results and costs associated with your area of interest.',
    buttonLabel: 'contact us',
    link: ROUTES.contactUs,
  },
];

export const Capabilities = () => {
  return (
    <Section light>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Other Capabilities"
        description={
          <>
            Mineral Prospecting in our app is not the only thing we do.
            <br /> We also provide more complex and custom solutions for Environment Monitoring, as well, as in depth
            analysis Report Creation for your needs.
          </>
        }
        isBackgroundLight
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <CapibilitiesContainer>
            {capabilitiesData.map((data) => (
              <Capability key={data.id} {...data} />
            ))}
          </CapibilitiesContainer>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const CapibilitiesContainer = styled.div`
  display: flex;
  gap: 60px;
  justify-content: space-between;
  margin-top: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 50px;
    margin-top: 70px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 40px;
    margin-top: 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    flex-direction: column;
    gap: 60px;
  }
`;
