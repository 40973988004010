import {
  StyledBenefit,
  BenefitTitle,
  BenefitDescription,
} from './styled';

type BenefitProps = {
  title: string;
  description: string;
  imageName: string;
};

const Benefit = ({ title, description, imageName }: BenefitProps) => {
  return (
    <StyledBenefit>
      <img src={require(`assets/images/benefits/${imageName}.png`)} alt={title} />
      <BenefitTitle>{title}</BenefitTitle>
      <BenefitDescription>{description}</BenefitDescription>
    </StyledBenefit>
  );
};

export default Benefit;
