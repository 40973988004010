import styled from 'styled-components';
import { keyframes } from 'styled-components';

export const SubscriptionsInfoWrapper = styled.div`
  margin-top: 70px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 20px;
  }
`;

export const SubscriptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 305px));
  justify-content: space-between;
  gap: 80px 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(2, minmax(0, 305px));
    justify-content: start;
    gap: 40px 80px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletSmall}px) {
    gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const skeletonAnimation = keyframes`
  from {
    background-position-x: 125%;
  }
  to {
    background-position-x: -25%;
  }
`;

export const SubscriptionsSkeleton = styled.div`
  width: 100%;
  height: 450px;
  background: ${({ theme }) =>
    `linear-gradient(65deg, ${theme.colors.gallery} 32%, ${theme.colors.alto} 50%, ${theme.colors.gallery} 68%)`};
  background-size: 250% 100%;
  animation: 1s ${skeletonAnimation} linear infinite;
`;
