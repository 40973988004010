import styled from 'styled-components';
import { Paragraph } from 'ui/atoms/paragraph';

export const StyledEcosystemItem = styled.li<{ digitalTwin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ digitalTwin }) => digitalTwin ? 'center' : 'normal' };
  gap: 50px;
  color: ${({ theme, digitalTwin }) => digitalTwin ? theme.colors.tuatara : theme.colors.white };

  &:nth-child(odd) {
    padding-left: 80px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      flex-direction: column-reverse;
      padding-left: 0;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    padding-right: 80px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      flex-direction: column-reverse;
      padding-right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 38px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    &:first-child h3 {
      max-width: 190px;
    }
  }
`;

export const EcosystemItemInfo = styled.article`
  max-width: 468px;
  width: 100%;
  margin-top: -13px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 100%;
  }
`;

export const HeadingWrapper = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 11%;
    left: -20px;
    width: 1px;
    height: 85%;
    background-color: ${({ theme }) => theme.colors.rawSienna};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      left: -15px;
    }
  }
`;

export const EcosystemItemHeading = styled.h3`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 44px;
  font-weight: 600;
  line-height: normal;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }
`;

export const EcosystemItemSubheading = styled.div`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  margin-top: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
    margin-top: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
  }
`;

export const EcosystemItemDescription = styled(Paragraph)`
  line-height: 20px;
  margin: 30px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    margin: 35px 0 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: disc;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    margin-left: 10px;


    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 12px;
    }
  }
`;

export const EcosystemItemVideo = styled.video`
  display: block;
  min-width: 420px;
  max-width: 630px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 100%;
    min-width: auto;
  }
`;
