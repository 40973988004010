import { type ReactNode } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, TileLayerProps } from 'react-leaflet';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';

import { type BaseMapVariant } from 'ui/atoms/baseMap/types';
import { mapStyle } from 'ui/atoms/baseMap/mapStyle';
import { isArcGISMap, isOpenStreetMap } from 'ui/atoms/baseMap/typeguards';
import { ZoomControlPlugin } from 'ui/atoms/baseMap/ZoomControlPlugin';
import { MAP_DEFAULT_CENTER, MAP_INITIAL_ZOOM, MAP_MAX_ZOOM, MAP_MIN_ZOOM } from './constants';
import { LatLngExpression } from 'leaflet';

interface BaseMapProps {
  variant: BaseMapVariant;
  windowed?: boolean;
  height?: number;
  center: string[] | number[];
  zoom?: number;
  children?: ReactNode;
  selectionMode?: boolean;
}

export const BaseMap = ({ variant, windowed, height, center, zoom, children, selectionMode }: BaseMapProps) => {
  const MAP_STYLE = mapStyle[variant];

  return (
    <StyledMapContainer
      windowed={windowed}
      height={height}
      // @ts-ignore
      attributionControl={false}
      zoomControl={false}
      center={(center || MAP_DEFAULT_CENTER) as LatLngExpression | undefined}
      zoom={zoom || MAP_INITIAL_ZOOM}
      minZoom={MAP_MIN_ZOOM}
      maxZoom={MAP_MAX_ZOOM}
      zoomSnap={0.5}
      zoomDelta={0.5}
      wheelPxPerZoomLevel={90}
      selectionMode={selectionMode}
    >
      {isOpenStreetMap(MAP_STYLE) && (
        <TileLayer
          url={MAP_STYLE.styleUrl}
          // @ts-ignore
          attribution={`&copy; <a href="${MAP_STYLE.vendor.url}">${MAP_STYLE.vendor.name}</a>` as keyof TileLayerProps}
        />
      )}
      {isArcGISMap(MAP_STYLE) && (
        <VectorBasemapLayer apiKey={process.env.REACT_APP_ARCGIS_MAP_KEY} name={MAP_STYLE.name} />
      )}
      <ZoomControlPlugin zoom={zoom} />
      {children}
    </StyledMapContainer>
  );
};

const StyledMapContainer = styled(MapContainer)<{
  windowed?: boolean;
  selectionMode?: boolean;
  height?: number;
}>`
  position: ${({ windowed }) => (windowed ? 'static' : 'absolute')};
  height: ${({ height }) => height}px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  cursor: ${({ selectionMode }) => (selectionMode ? 'crosshair' : 'grab')};
`;
