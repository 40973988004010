import { Trans } from 'react-i18next';

import { PUBLIC_CONTAINER_URL } from 'common/constants';
import { ABOUT_SECTION_ID } from 'common/constants';
import { ROUTES } from 'common/routes';
import euFundFlag from 'assets/images/global/eu-fund-flag.svg';
import euFundText from 'assets/images/global/eu-fund-text.svg';
import AppLink from 'components/AppLink';
import { Container } from 'components/Container';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { BaseButton } from 'ui/atoms/button/Button';
import { getVideoFormat } from 'utils/getVideoFormat';
import { Paragraph } from 'ui/atoms/paragraph';
import {
  EuFundLogo,
  HeaderArticle,
  HeaderButtons,
  HeaderHeading,
  HeaderLogos,
  HeaderVideo,
  ImagesWrapper,
  StyledHeader,
  StyledHashLink,
} from './styled';

interface HeaderProps {
  header: string;
  description: string;
  buttonLabel?: string;
  madeBy?: string;
  digitalTwin?: boolean;
}

const Header = ({ header, description, buttonLabel, digitalTwin }: HeaderProps) => {
  const videoUrl = `${PUBLIC_CONTAINER_URL}/videos/${
    digitalTwin ? 'digital-twin' : 'main'
  }/hero/hero.${getVideoFormat()}`;

  return (
    <StyledHeader>
      <HeaderVideo autoPlay playsInline muted loop>
        <source src={videoUrl} type={`video/${getVideoFormat()}`} />
      </HeaderVideo>
      <HeaderLogos>
        <PaddingWrapper size="small" type="inline">
          <Container size="wide" align="center">
            <ImagesWrapper>
              <div> </div>
              {!digitalTwin && (
                <EuFundLogo>
                  <img src={euFundFlag} alt="European regional development fund" />
                  <img src={euFundText} alt="European regional development fund" />
                </EuFundLogo>
              )}
            </ImagesWrapper>
          </Container>
        </PaddingWrapper>
      </HeaderLogos>

      <PaddingWrapper size="large" type="inline">
        <Container size="medium" align="center">
          <HeaderArticle>
            <HeaderHeading>
              <Trans i18nKey={header} />
              <Paragraph size="XL">{description}</Paragraph>
            </HeaderHeading>
            <HeaderButtons>
              {digitalTwin ? (
                <BaseButton as={undefined} width={150} target="_blank" rel="noopener noreferrer" variant="PRIMARY">
                  <StyledHashLink to={`${ROUTES.digitalTwin}#${ABOUT_SECTION_ID}`} smooth>
                    {buttonLabel}
                  </StyledHashLink>
                </BaseButton>
              ) : (
                <AppLink signUp>free demo</AppLink>
              )}
            </HeaderButtons>
          </HeaderArticle>
        </Container>
      </PaddingWrapper>
    </StyledHeader>
  );
};

export default Header;
