export const logotypesData = [
  {
    id: 1,
    image: 'footer/smart-growth-funds.svg',
    alt: 'European Union Smart Growth Funds',
  },
  {
    id: 2,
    image: 'footer/poland.svg',
    alt: 'The Republic of Poland',
  },
  {
    id: 3,
    image: 'global/ncbr.svg',
    alt: 'National Centre for Research and Development',
  },
  {
    id: 4,
    image: 'footer/regional-development-funds.svg',
    alt: 'European Fund Regional Development',
  },
];
