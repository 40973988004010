import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { Paragraph } from 'ui/atoms/paragraph';

export const StyledHeader = styled.header`
  position: relative;
  min-height: 896px;
  background: center / cover no-repeat fixed;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.2) 100%);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 768px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-height: 670px;
  }
`;

export const HeaderLogos = styled.div`
  position: absolute;
  top: 117px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    top: 92px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    top: 66px;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.5rem;
    font-size: clamp(0.5rem, -0.043rem + 1.13vw, 0.875rem);
    line-height: 16.8px;
    user-select: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 10px;
  }
`;

export const RsbsLogo = styled.div`
  img {
    display: block;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 46px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 34px;
  }
`;

export const EuFundLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;

  img:last-child {
    width: 117px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 6.5px;

    img:first-child {
      width: 35px;
    }

    img:last-child {
      width: 69px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 4px;

    img:first-child {
      width: 27px;
    }

    img:last-child {
      width: 50px;
    }
  }
`;

export const HeaderArticle = styled.article`
  max-width: 640px;
  width: 100%;
  padding-top: 350px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 460px;
    padding-top: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 370px;
    padding-top: 270px;
  }
`;

export const HeaderHeading = styled.h1`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 66px;
  font-weight: 600;
  line-height: 66px;
  margin: 0;

  ${Paragraph} {
    margin-top: 18px;
    line-height: 1.2;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 50px;
    line-height: 100%;

    ${Paragraph} {
      margin-top: 10px;
      font-size: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 36px;

    ${Paragraph} {
      font-size: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    br {
      display: none;
    }
  }
`;

export const HeaderButtons = styled.div`
  margin-top: 52px;
  display: flex;
  align-items: stretch;
  gap: 20px;

  a {
    max-width: 224px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 12px;
      line-height: 31px;
      letter-spacing: 1.225px;
      max-width: 213px;
      height: 52px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
      max-width: 282px;
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 50px;
    gap: 15px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 38px;
    gap: 16px;
  }
`;

export const HeaderVideo = styled.video`
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
  min-height: 896px;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 768px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    min-height: 670px;
  }
`;

export const StyledHashLink = styled(HashLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
