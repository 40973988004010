import PageHelmetWrapper from 'components/PageHelmetWrapper';
import SubPageLayout from 'components/SubPage';
import MineCamSection from './MineCamSection';
import mineCamBackgroundDesktop from 'assets/images/mineCam/mine-cam-desktop.jpg';
import mineCamBackgroundTablet from 'assets/images/mineCam/mine-cam-tablet.jpg';
import { SubPageImage } from 'components/SubPageImage';

const MineCamPage = () => {
  return (
    <PageHelmetWrapper page="mineCam">
      <SubPageLayout image={<SubPageImage desktopUrl={mineCamBackgroundDesktop} tabletUrl={mineCamBackgroundTablet} />}>
        <MineCamSection />
      </SubPageLayout>
    </PageHelmetWrapper>
  );
};

export default MineCamPage;
