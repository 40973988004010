import { useFeatureFlag } from 'configcat-react';

import { LIGHT_SECTIONS_ID, ECOSYSTEM_SECTION_ID, PRICING_SECTION_ID } from 'common/constants';
import PageHelmetWrapper from 'components/PageHelmetWrapper';
import Header from './components/Header';
import Partnership from './components/Partnership';
import { About } from './components/About/About';
import PersistentMonitoring from './components/PersistentMonitoring';
import CaseStudies from './components/CaseStudies';
import Ecosystem from './components/Ecosystem';
import { ecosystemData } from './components/Ecosystem/ecosystemData';
import { MineralProspectingPricing } from './components/MineralProspectingPricing/MineralProspectingPricing';
import { Capabilities } from './components/Capabilities/Capabilities';
import CallToAction from './components/CallToAction';
import { Newsletter } from './components/Newsletter';

const HomePage = () => {
  const { value: ENABLE_NEWSLETTER } = useFeatureFlag('ENABLE_NEWSLETTER', false);
  const enableNewsletter = ENABLE_NEWSLETTER || process.env.REACT_APP_ENABLE_NEWSLETTER;

  return (
    <PageHelmetWrapper page="home">
      <>
        <Header
          header="Jumpstarting your next<br /> mineral exploration"
          description="with AI-powered satellite data processing"
        />
        <Partnership />
        <About />
        <PersistentMonitoring />
        <div id={LIGHT_SECTIONS_ID}>
          <CaseStudies shortSection />
        </div>
        <Ecosystem
          id={ECOSYSTEM_SECTION_ID}
          title="TerraEye Ecosystem"
          description="Discover the comprehensive suite of tools and services that TerraEye offers to enhance your mineral exploration projects."
          data={ecosystemData.list}
          subDescription={ecosystemData.description}
        />
        <div id={LIGHT_SECTIONS_ID}>
          <MineralProspectingPricing id={PRICING_SECTION_ID} />
          <Capabilities />
        </div>
        <CallToAction />
        {enableNewsletter && <Newsletter />}
      </>
    </PageHelmetWrapper>
  );
};

export default HomePage;
