import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

import { helmetMedaData } from 'helmetMetaData';

type PageHelmetWrapperProps = PropsWithChildren<{
  page: keyof typeof helmetMedaData;
}>;

const PageHelmetWrapper = ({ children, page }: PageHelmetWrapperProps) => {
  const { title, description } = helmetMedaData[page];

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </>
  );
};

export default PageHelmetWrapper;
