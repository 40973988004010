import styled from 'styled-components';

export const DataProcessingContent = styled.div`
  margin-top: 158px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    margin-top: 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 60px;
  }
`;
