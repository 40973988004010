import { theme } from 'theme';
import SectionHeader from 'components/SectionHeader';
import { Section } from 'components/Section';
import useMediaQuery from 'hooks/useMediaQuery';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { DataProcessingContent } from './styled';

const DataProcessing = () => {
  const matchesTabletLarge = useMediaQuery(`(max-width: ${theme.breakpoints.tabletLarge}px)`);

  return (
    <Section light large>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Data processing"
        description="How does TerraEye work?"
        isBackgroundLight
      />

      <DataProcessingContent>
        <DesktopView hide={matchesTabletLarge} />
        <MobileView hide={!matchesTabletLarge} />
      </DataProcessingContent>
    </Section>
  );
};

export default DataProcessing;
