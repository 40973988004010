import { PointArray, Polygon } from 'types';

export const createPolygonFromPoints = (points: PointArray[]): Polygon => {
  const nodes = [points[0], [points[1][0], points[0][1]], points[1], [points[0][0], points[1][1]], points[0]];

  const alteredNodes = nodes.map((node) => [node[1], node[0]]) as PointArray[];

  const polygon = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [alteredNodes],
    },
    properties: {},
  };

  return polygon;
};
