import styled, { css } from 'styled-components';

import { ThemeColors } from 'types';

type HeadingVariants = 'PRIMARY' | 'SECONDARY';

interface HeadingProps {
  variant?: HeadingVariants;
  color?: ThemeColors;
}

export const Heading = styled.h1<HeadingProps>`
  margin: 0;
  font-family: 'Barlow Condensed', sans-serif;

  ${({ variant }) =>
    variant === 'PRIMARY' &&
    css`
      font-size: 66px;
      font-weight: 600;
      line-height: 79.2px;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: 50px;
        line-height: normal;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 36px;
      }
    `}

  ${({ variant }) =>
    variant === 'SECONDARY' &&
    css`
      line-height: normal;
      font-size: 44px;
      font-weight: 600;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: 34px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 24px;
      }
    `}

  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.copper)};
`;

Heading.defaultProps = {
  variant: 'PRIMARY',
};
