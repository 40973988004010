import { useEffect, useState } from 'react';

import { Container } from 'components/Container';
import { CookiesBarContent, CookiesBarContentWrapper, StyledCookiesBar } from './styled';
import { saveValueToLocalStorage } from 'utils/localStorage';

const CookiesBar = () => {
  const [isBarOpen, setIsBarOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsBarOpen(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const onButtonClick = () => {
    setIsBarOpen(false);
    saveValueToLocalStorage('acceptedCookies', true);
  };

  return (
    <StyledCookiesBar open={isBarOpen}>
      <CookiesBarContentWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <CookiesBarContent>
            <p>
              This site uses cookies to provide a better browsing experience. By continuing to visit this site you agree
              to our use of cookies.
            </p>
            <button onClick={onButtonClick}>close</button>
          </CookiesBarContent>
        </Container>
      </CookiesBarContentWrapper>
    </StyledCookiesBar>
  );
};

export default CookiesBar;
