import { t } from 'i18next';

import { LIGHT_SECTIONS_ID, FEATURES_SECTION_ID } from 'common/constants';
import PageHelmetWrapper from 'components/PageHelmetWrapper';
import Header from 'pages/HomePage/components/Header';
import DigitalTwinPartnership from 'pages/HomePage/components/DigitalTwinPartnership';
import DigitalTwinPlatform from 'pages/HomePage/components/DigitalTwinPlatform';
import Ecosystem from 'pages/HomePage/components/Ecosystem';
import Benefits from 'pages/HomePage/components/Benefits';
import CallToAction from 'pages/HomePage/components/CallToAction';

const DigitalTwinHomePage = () => {
  return (
    <PageHelmetWrapper page="digitalTwinHome">
      <>
        <Header
          header={t("hero.header")}
          description={t("hero.description")}
          madeBy={t("hero.madeBy")}
          buttonLabel={t("hero.buttonLabel")}
          digitalTwin
        />
        <DigitalTwinPartnership header={t("partnership.header")} />
        <DigitalTwinPlatform 
          header={t("about.header")}
          description={t("about.description")}
        />
        <div id={LIGHT_SECTIONS_ID}>
          <Ecosystem id={FEATURES_SECTION_ID} title={t("features.title")} description={t("features.description")} data={t("features.data", { returnObjects: true })} digitalTwin />
          <Benefits title={t("benefits.title")} description={t("benefits.description")} data={t("benefits.data", { returnObjects: true })} />
        </div>
        <CallToAction
          header={t("callToAction.header")}
          description={t("callToAction.description")}
          buttonLabel={t("callToAction.buttonLabel")}
          butonWidth={225}
          digitalTwin
        />
      </>
    </PageHelmetWrapper>
  );
};

export default DigitalTwinHomePage;
