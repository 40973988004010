import PageHelmetWrapper from 'components/PageHelmetWrapper';
import SubPageLayout from 'components/SubPage';
import { Hype4ExplorImage } from './styled';
import Hype4ExplorSection from './Hype4ExplorSection';
import hype4ExplorBackgroundDesktop from 'assets/images/hype4explor/hype4explor-desktop.jpg';
import hype4ExplorBackgroundTablet from 'assets/images/hype4explor/hype4explor-tablet.jpg';

const Hype4ExplorPage = () => {
  return (
    <PageHelmetWrapper page="hype4Explor">
      <SubPageLayout
        image={<Hype4ExplorImage desktopUrl={hype4ExplorBackgroundDesktop} tabletUrl={hype4ExplorBackgroundTablet} />}
      >
        <Hype4ExplorSection />
      </SubPageLayout>
    </PageHelmetWrapper>
  );
};

export default Hype4ExplorPage;
