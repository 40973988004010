import { ArticlesList } from 'components/Article/styled';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import LegalPageLayout from 'components/LegalContentWrapper';
import { ArticleOrderedList, ArticleUnorderedSublist } from 'components/Article/styled';

const PrivacyPolicySection = () => {
  return (
    <LegalPageLayout title="Privacy Policy">
      <ArticlesList>
        <ArticleWrapper title="Privacy Policy for RSBS P.S.A.">
          <ArticleOrderedList>
            <li>
              At Remote Sensing Business Solutions, one of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is collected and recorded by Remote Sensing
              Business Solutions and how we use it.
            </li>
            <li>
              If you have additional questions or require more information about our Privacy Policy, do not hesitate to
              contact us.
            </li>
            <li>
              This Privacy Policy applies only to our online activities and is valid for visitors to our website with
              regards to the information that they shared and/or collect in Remote Sensing Business Solutions. This
              policy is not applicable to any information collected offline or via channels other than this website.
            </li>
          </ArticleOrderedList>
        </ArticleWrapper>

        <ArticleWrapper title="Information we collect">
          <ArticleOrderedList>
            <li>
              When you contact us directly, we will collect additional information about you such as your name, email
              address, phone number, the contents of the message and/or attachments you may send us, and any other
              information you may choose to provide.
            </li>
            <li>
              When you register for an Account, we may ask for your contact information, including items such as name,
              company name, address, email address, and telephone number.
            </li>
          </ArticleOrderedList>
        </ArticleWrapper>

        <ArticleWrapper title="How we use your information">
          <ArticleOrderedList>
            <li>
              We use the information we collect in various ways, including to:
              <ArticleUnorderedSublist>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>
                  Communicate with you, including for customer service, to provide you with updates and other
                  information relating to the website, and for marketing and promotional purposes (only if you gave your
                  consent to process your personal data for marketing purposes)
                </li>
                <li>Find and prevent fraud</li>
              </ArticleUnorderedSublist>
            </li>
          </ArticleOrderedList>
        </ArticleWrapper>

        <ArticleWrapper title="Information clause on the processing of personal data">
          <ArticleOrderedList>
            <li>
              Your personal Data Controller is Remote Sensing Business Solutions with its registered office in Wrocław
              at the following address: ul. Jana Długosza 60a, 51-162 Wrocław.
            </li>
            <li>
              Your personal data will be used for the purpose of processing your request for access to our products.
            </li>
            <li>
              Providing your personal data is voluntary, however, failure to provide the indicated data will prevent the
              Data Controller from conducting activities. Your consent may be withdrawn at any time, but the withdrawal
              of consent does not affect the lawfulness of the processing of data which was made on the basis of consent
              prior to its withdrawal.
            </li>
            <li>
              Your data will be processed on the basis of your consent, i.e. pursuant to Article 6 (1) (a) of Regulation
              (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural
              persons with regard to the processing of personal data and on the free movement of such data, and
              repealing Directive 95/46/EC (General Data Protection Regulation, “GDPR”).
            </li>
            <li>
              Your personal data will be processed until you withdraw your consent to the processing of personal data.
            </li>
            <li>Your personal data will not be used for automated individual decision-making, including profiling.</li>
            <li>
              Your personal data are transferred to entities providing IT support to Remote Sensing Business Solutions
              or to authorized employees of Remote Sensing Business Solutions; the transfer of data is performed
              pursuant to the concluded personal data processing agreements, and the processing entities process them
              exclusively at the request of Remote Sensing Business Solutions, i.e. they are not authorized to process
              such data for their own purposes.
            </li>
            <li>
              You have the following rights:
              <ArticleUnorderedSublist>
                <li>the right to access - you have the right to request copies of your personal data.</li>
                <li>
                  the right to rectification - you have the right to request that we correct any information you believe
                  is inaccurate. you also have the right to request that we complete the information you believe is
                  incomplete.
                </li>
                <li>
                  the right to erasure - you have the right to request that we erase your personal data, under certain
                  conditions.
                </li>
                <li>
                  the right to restrict processing - you have the right to request that we restrict the processing of
                  your personal data, under certain conditions.
                </li>
                <li>
                  the right to object to processing - you have the right to object to our processing of your personal
                  data, under certain conditions.
                </li>
                <li>
                  the right to data portability - you have the right to request that we transfer the data that we have
                  collected to another organization, or directly to you, under certain conditions.
                </li>
              </ArticleUnorderedSublist>
            </li>
          </ArticleOrderedList>
        </ArticleWrapper>

        <ArticleWrapper title="Log files">
          Remote Sensing Business Solutions follows a standard procedure of using log files. These files log visitors
          when they visit websites. All hosting companies do this and a part of hosting services' analytics. The
          information collected by log files include internet protocol (IP) addresses, browser type, Internet Service
          Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose of the information is for analyzing
          trends, administering the site, tracking users' movement on the website, and gathering demographic
          information.
        </ArticleWrapper>

        <ArticleWrapper title="Cookies and web beacons">
          Like any other website, Remote Sensing Business Solutions uses 'cookies'. These cookies are used to store
          information including visitors' preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users' experience by customizing our web page content based
          on visitors' browser type and/or other information.For more general information on cookies, please read "What
          Are Cookies".
        </ArticleWrapper>

        <ArticleWrapper title="Advertising partners privacy policies">
          You may consult this list to find the Privacy Policy for each of the advertising partners of Remote Sensing
          Business Solutions.Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
          Beacons that are used in their respective advertisements and links that appear on Remote Sensing Business
          Solutions, which are sent directly to users' browser. They automatically receive your IP address when this
          occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to
          personalize the advertising content that you see on websites that you visit.Note that Remote Sensing Business
          Solutions has no access to or control over these cookies that are used by third-party advertisers.
        </ArticleWrapper>

        <ArticleWrapper title="Third party privacy policies">
          Remote Sensing Business Solutions's Privacy Policy does not apply to other advertisers or websites. Thus, we
          are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how to opt-out of certain options.You can
          choose to disable cookies through your individual browser options. To know more detailed information about
          cookie management with specific web browsers, it can be found at the browsers' respective websites.
        </ArticleWrapper>
      </ArticlesList>
    </LegalPageLayout>
  );
};

export default PrivacyPolicySection;
