import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { partnershipData } from './partnershipData';
import {
  ImageWrapper,
  PartnershipHeading,
  PartnershipSliderList,
  PartnershipSlider,
  SliderWrapper,
  StyledPartnership,
  PartnershipContent,
} from './styled';

const Partnership = () => {
  return (
    <StyledPartnership>
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <PartnershipHeading>our partners</PartnershipHeading>
        </Container>
      </PaddingWrapper>

      <PartnershipContent>
        <Container align="center" size="wide">
          <SliderWrapper>
            <PartnershipSlider>
              <PartnershipSliderList>
                {partnershipData.map(({ id, image, alt }) => (
                  <ImageWrapper key={id}>
                    <img src={require(`assets/images/${image}`)} alt={alt} />
                  </ImageWrapper>
                ))}
              </PartnershipSliderList>
              <PartnershipSliderList>
                {partnershipData.map(({ id, image, alt }) => (
                  <ImageWrapper key={id}>
                    <img src={require(`assets/images/${image}`)} alt={alt} />
                  </ImageWrapper>
                ))}
              </PartnershipSliderList>
            </PartnershipSlider>
          </SliderWrapper>
        </Container>
      </PartnershipContent>
    </StyledPartnership>
  );
};

export default Partnership;
