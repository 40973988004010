import styled from "styled-components";
import { Paragraph } from "ui/atoms/paragraph";

export const DigitalTwinPlatformSection = styled.section`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 50px 30px;
  padding: 0;
  margin-top: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 30px;
  }
`;

export const DigitalTwinPlatformSectionHeader = styled.h3`
  font-family: 'Barlow', sans-serif;
  font-size: 96px;
  font-weight: 900;
  line-height: 1;
  color: ${({ theme }) => theme.colors.rawSienna};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 72px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 48px;
  }
`;

export const DigitalTwinPlatformSectionDescription = styled(Paragraph)`
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;

  strong {
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }
`;

export const LogosWrapper = styled.div`
  padding-top: 10px;
`;
