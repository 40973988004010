import styled from 'styled-components';

export const StyledCaseStudyFormSection = styled.section`
  h1 {
    font-size: 48px;
    max-width: 598px;
    line-height: normal;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 40px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      font-size: 32px;
    }
  }
`;

export const CaseStudyFormSectionWrapper = styled.div`
  form {
    padding: 60px 0 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      padding: 40px 0 0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      padding: 28px 0 0;
    }
  }
`;

export const CaseStudyFormSectionContent = styled.div`
  padding: 0 80px 140px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 0 50px 140px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 126px 90px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 40px 73px 0;
  }
`;

export const CaseStudyFormDescription = styled.div`
  max-width: 630px;
  margin: 42px 0 0;
`;

export const DescriptionList = styled.ul`
  padding-left: 24px;
  margin: 10px 0 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 10px 0 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 10px 0 66px;
  }
`;
