import { TERRAEYE_APP_BASE_URL, TERRAEYE_APP_SIGN_UP_URL } from 'common/constants';
import { ROUTES } from 'common/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { Link } from 'react-router-dom';
import { theme } from 'theme';
import { BaseButton } from 'ui/atoms/button';
import { BaseButtonSizes, BaseButtonVariants, LinkButton } from 'ui/atoms/button/Button';

interface AppLinkProps {
  children?: React.ReactNode;
  variant?: BaseButtonVariants;
  size?: BaseButtonSizes;
  signUp?: boolean;
  noStyle?: boolean;
}

const AppLink = ({ children = 'explore our platform', noStyle, signUp, ...props }: AppLinkProps) => {
  const matchesTablet = useMediaQuery(`(max-width: ${theme.breakpoints.tablet}px)`);

  if (noStyle) {
    return matchesTablet ? (
      <Link to={ROUTES.terraeyeApp} children={children} />
    ) : (
      <a href={signUp ? TERRAEYE_APP_SIGN_UP_URL : TERRAEYE_APP_BASE_URL} target="_blank" rel="noopener noreferrer" children={children} />
    );
  }

  return matchesTablet ? (
    <LinkButton to={ROUTES.terraeyeApp} children={children} {...props} />
  ) : (
    <BaseButton
      as="a"
      href={signUp ? TERRAEYE_APP_SIGN_UP_URL : TERRAEYE_APP_BASE_URL}
      target="_blank"
      rel="noopener noreferrer"
      children={children}
      {...props}
    />
  );
};

export default AppLink;
