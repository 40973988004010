import CarouselItem from './CarouselItem';
import { persistentMonitoringData as data } from './persistentMonitoringData';
import {
  CarouselContainer,
  CarouselWrapper,
  CrossLine,
  HideOverflowWrapper,
  LeftGradient,
  NextButton,
  PreviousButton,
  RightGradient,
  StyledCarousel,
} from './styled';
import { useCarousel } from './useCarousel';

const Carousel = () => {
  const { currentIndex, dividerValue, handleNext, handlePrev } = useCarousel();

  return (
    <>
      <CarouselContainer>
        <CarouselWrapper>
          <LeftGradient hide={currentIndex === 0} />
          <CrossLine />
          <HideOverflowWrapper>
            <StyledCarousel
              style={{
                transform: `translateX(-${(currentIndex * 100) / dividerValue}%)`,
              }}
            >
              {data.map((item) => (
                <CarouselItem
                  key={item.id}
                  image={item.image}
                  title={item.title}
                  heading={item.heading}
                  description={item.description}
                />
              ))}
            </StyledCarousel>
          </HideOverflowWrapper>
          <PreviousButton onClick={handlePrev} hide={currentIndex === 0} />
          <NextButton onClick={handleNext} hide={currentIndex === data.length - dividerValue} />
          <RightGradient hide={currentIndex === data.length - dividerValue} />
        </CarouselWrapper>
      </CarouselContainer>
    </>
  );
};

export default Carousel;
