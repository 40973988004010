import { emailRegExp, phomeRegExp } from './formRegExp';

export const isValidName = (name: string) => name.length > 0;

export const isValidPhone = (phone: string) => phomeRegExp.test(phone);

export const isValidEmail = (email: string) => emailRegExp.test(email);

export const isValidCompany = (company: string) => company.length > 0;

export const isValidMessage = (message: string) => message.length > 0;

export const isValidJob = (message: string) => message.length > 0;

export const isValidCountry = (message: string) => message.length > 0;
