import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { BaseButton } from 'ui/atoms/button/Button';

type NavigationListProps = {
  open?: boolean;
  isLightSectionsInView: boolean;
};

export const StyledNavigationList = styled.ul<NavigationListProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;

  @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    z-index: 99;
    height: 100vh;
    width: 44%;
    min-width: 350px;
    background-color: ${({ theme }) => theme.colors.copper};
    transition: transform 300ms ease-in-out;

    ${({ open }) =>
    open &&
    css`
        transform: translate(0);
      `}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 100%;
    min-width: auto;
  }

  ${({ isLightSectionsInView, open }) =>
    isLightSectionsInView &&
    !open &&
    css`
      ${SignUpLink} {
        border: 1px solid ${({ theme }) => theme.colors.tuatara};
        color: ${({ theme }) => theme.colors.tuatara};
        background-color: ${({ theme }) => theme.colors.whiteTransparent10};

        &:hover {
          background-color: ${({ theme }) => theme.colors.rawSiennaTransparent15};
        }
      }
    `}
`;

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 125px;
    padding-left: 47px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 100px;
  }
`;

export const NavigationListItem = styled.li`
  position: relative;
  margin: 0 60px 0 0;

  &:last-child {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
    margin: 0 0 14px;
  }
`;

interface StyledHashLinkProps {
  $isLightSectionsInView?: boolean;
  $altColor?: boolean
}

export const StyledHashLink = styled(HashLink) <StyledHashLinkProps>`
  display: block;
  font-weight: 700;
  line-height: 46px;
  height: 46px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme, $altColor }) => $altColor ? theme.colors.neptune : theme.colors.white};
  background-color: transparent;
  cursor: pointer;
  transition: color 500ms ease-out;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: 36px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    transform: translateX(-50%);
    opacity: 0;
    background-color: ${({ theme, $altColor }) => $altColor ? theme.colors.neptune : theme.colors.whiskey};
    transition: opacity 500ms ease-out;
  }

  &:hover {
    color: ${({ theme, $altColor }) => $altColor ? theme.colors.neptune : theme.colors.whiskey};

    &::after {
      opacity: 1;
    }
  }

  ${({ $isLightSectionsInView }) =>
    $isLightSectionsInView &&
    css<{ $altColor?: boolean }>`
      color: ${({ theme, $altColor }) => $altColor ? theme.colors.easternBlue : theme.colors.tuatara};

      &::after {
        background-color: ${({ theme, $altColor }) => $altColor ? theme.colors.easternBlue : theme.colors.muleFawn};
      }

      &:hover {
        color: ${({ theme, $altColor }) => $altColor ? theme.colors.easternBlue : theme.colors.muleFawn};
      }
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.navigation}px) {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.white};

      &::after {
        display: none;
      }
    }
  }
`;

export const SignUpLink = styled(BaseButton)`
  width: 197px;
  transition-property: background-color, color, border;
  transition-duration: 300ms;
  transition-timing-function: linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.whiteTransparent30};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    width: 213px;
  }
`;
