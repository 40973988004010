import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'common/routes';
import CookiesBar from 'components/CookiesBar';
import CaseStudiesPage from 'pages/CaseStudyPage';
import EcoSatPage from 'pages/EcoSatPage';
import HomePage from 'pages/HomePage';
import DigitalTwinHomePage from 'pages/DigitalTwinHomePage';
import Hype4ExplorPage from 'pages/Hype4ExplorPage';
import MineCamPage from 'pages/MineCamPage';
import { NewsletterCancelPage } from 'pages/NewsletterCancelPage';
import NewsletterRegulations from 'pages/NewsletterRegulations';
import DataPage from 'pages/DataPage/DataPage';
import ReportsPage from 'pages/ReportsPage/ReportsPage';
import PrivacyPolicyTerraeyeApp from 'pages/PrivacyPolicyTerraeyeApp';
import RequestAccessPage from 'pages/RequestAccesPage';
import TermsAndConditions from 'pages/TermsAndConditions';
import TermsAndConditionsGDP from 'pages/TermsAndConditionsGDP';
import TerraEyeAppInfoPage from 'pages/TerraEyeAppInfoPage';
import RebrandingPage from 'pages/RebrandingPage';
import { getValueFromLocalStorage } from 'utils/localStorage';
import Footer from './components/Footer';
import DigitalTwinFooter from './components/DigitalTwinFooter';
import Navigation from './components/Navigation';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import './i18n';
const { version } = require('../package.json');

console.log(`TerraEye-landing v${version}`);

const App = () => {
  const { pathname } = useLocation();
  const hasAcceptedCookies = !!getValueFromLocalStorage('acceptedCookies');

  const savedLanguage = localStorage.getItem('i18nextLng');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!savedLanguage) {
      i18n.changeLanguage(navigator.language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!hasAcceptedCookies && <CookiesBar />}
      <Navigation digitalTwin={pathname.includes(ROUTES.digitalTwin)} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={ROUTES.digitalTwin} element={<DigitalTwinHomePage />} />
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.digitalTwinPrivacyPolicy} element={<PrivacyPolicyPage digitalTwin />} />
        <Route path={ROUTES.privacyPolicyTerraeyeApp} element={<PrivacyPolicyTerraeyeApp />} />
        <Route path={ROUTES.newsletterRegulations} element={<NewsletterRegulations />} />
        <Route path={ROUTES.termsAndConditionsDgp} element={<TermsAndConditionsGDP />} />
        <Route path={ROUTES.termsAndConditions} element={<TermsAndConditions />} />
        <Route path={ROUTES.ecoSat} element={<EcoSatPage />} />
        <Route path={ROUTES.mineCam} element={<MineCamPage />} />
        <Route path={ROUTES.hype4Explor} element={<Hype4ExplorPage />} />
        <Route path={ROUTES.contactUs} element={<RequestAccessPage />} />
        <Route path={ROUTES.digitalTwinContactUs} element={<RequestAccessPage digitalTwin />} />
        <Route path={ROUTES.caseStudies} element={<CaseStudiesPage />} />
        <Route path={ROUTES.terraeyeApp} element={<TerraEyeAppInfoPage />} />
        <Route path={ROUTES.newsletterCancel} element={<NewsletterCancelPage />} />
        <Route path={ROUTES.data} element={<DataPage />} />
        <Route path={ROUTES.reports} element={<ReportsPage />} />
        <Route path={ROUTES.rebranding} element={<RebrandingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {pathname.includes(ROUTES.digitalTwin) ? <DigitalTwinFooter /> : <Footer />}
    </>
  );
};

export default App;
