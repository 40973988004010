import styled, { css } from 'styled-components';

interface IconWrapperProps {
  active?: boolean;
  disabled?: boolean;
  iconWidth?: number;
  iconColor?: string;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  color: ${({ active, theme, iconColor }) =>
    active ? theme.colors.masala : iconColor ? iconColor : theme.colors.white};
  width: ${({ iconWidth }) => iconWidth}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray};
    `}
`;

IconWrapper.defaultProps = {
  iconWidth: 20,
};
