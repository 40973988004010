import styled, { css } from 'styled-components';
import { AltCheckmark, Checkmark, Input, InputWrapper } from '../styled';

type FocusEvent = React.FocusEvent<HTMLInputElement, Element>;

interface FormInputProps {
  name: string;
  inputValue: string;
  isRequired: boolean;
  showError: boolean;
  isValid: boolean;
  type: React.HTMLInputTypeAttribute;
  isFocused: boolean;
  errorMessage: string;
  customPlaceholderName: string;
  $maxWidth?: number;
  digitalTwin?: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputFocus: (event: FocusEvent) => void;
  onInputBlur: (event: FocusEvent) => void;
}

export const FormInput = (props: FormInputProps) => {
  return (
    <InputWrapper maxWidth={props.$maxWidth}>
      {props.showError && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      <Input
        value={props.inputValue}
        onChange={(event) => props.onInputChange(event)}
        onFocus={(event) => props.onInputFocus(event)}
        onBlur={(event) => props.onInputBlur(event)}
        invalid={props.showError}
        type={props.type}
        name={props.name}
        required={props.isRequired}
      />
      <Placeholder active={props.isFocused} invalid={props.showError}>
        {props.isRequired ? `${props.customPlaceholderName}*` : props.customPlaceholderName}
      </Placeholder>
      {props.isValid && (props.digitalTwin ? <AltCheckmark type="input" /> : <Checkmark type="input" />)}
    </InputWrapper>
  );
};

const ErrorMessage = styled.span`
  position: absolute;
  bottom: 26px;
  right: 0;
  z-index: 3;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    bottom: 22px;
  }
`;

type PlaceholderProps = {
  invalid: boolean;
  active: boolean;
};

export const Placeholder = styled.span<PlaceholderProps>`
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
  color: ${({ theme }) => theme.colors.rawSienna};
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  background-color: transparent;
  transition: bottom 200ms ease-out, font-size 200ms ease-in-out, background-color 100ms linear;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }

  ${({ active }) =>
    active &&
    css`
      bottom: 20px;
      left: 0;
      font-size: 10px;
      background-color: transparent;

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 8px;
        bottom: 16px;
      }
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `}
`;
