import styled from 'styled-components';
import { Paragraph } from 'ui/atoms/paragraph';

export const StyledRequestAccessFooter = styled.footer`
  padding: 175px 0 207px;
  background-color: ${({ theme }) => theme.colors.mineShaft_2};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 105px 0 107px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 75px 0 100px;
  }
`;

export const RequestAccessFooterLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img:first-child {
    width: 52px;
  }

  img:last-child {
    width: 113px;
  }
`;

export const RequestAccessFooterInfo = styled(Paragraph).attrs({ as: 'div' })`
  margin-top: 55px;
  display: grid;
  grid-template-columns: minmax(0, 305px) minmax(0, 286px);
  justify-content: space-between;
  gap: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 38px;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const RequestAccessFooterDetails = styled.div`
  address {
    font-style: normal;
  }

  article {
    margin-top: 39px;

    h2 {
      text-transform: uppercase;
      font-size: inherit;
      font-weight: 400;
      margin: 0;
      color: ${({ theme }) => theme.colors.rawSienna};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    article {
      margin-top: 32px;
    }
  }
`;

export const RequestAccessFooterMessages = styled.div`
  position: relative;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -19px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 94%;
    background-color: ${({ theme }) => theme.colors.rawSienna};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      left: -15px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      display: none;
    }
  }

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      gap: 20px;
    }
  }

  p {
    margin: 0;
  }
`;
