import styled from 'styled-components';

import { ROUTES } from 'common/routes';
import { LinkButton } from 'ui/atoms/button';
import SectionHeader from 'components/SectionHeader';
import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container } from 'components/Container';
import { Section } from 'components/Section';

export const MineralizationMapsSection = () => {
  return (
    <Section dark>
      <SectionHeader
        variant="SECONDARY_H2"
        title="Reports & Mineralization Maps"
        description="Discover comprehensive remote sensing reports tailored to your mineral exploration needs. Leveraging advanced satellite data processing and artificial intelligence, TerraEye delivers precise and actionable insights to enhance your exploration projects."
      />
      <PaddingWrapper size="large" type="inline">
        <Container align="center" size="medium">
          <StyledButton>
            <LinkButton to={ROUTES.contactUs} size="SM">
              <span>contact us to get a quote</span>
            </LinkButton>
          </StyledButton>
          <ListHeader>Who is this service for?</ListHeader>
          <List>
            <li>
              <span>Mining Companies</span> — Optimize your mineral exploration and reduce operational risks.
            </li>
            <li>
              <span>Geological Consultants</span> — Access high-resolution data and expert interpretations for detailed
              analysis.
            </li>
            <li>
              <span>Research Institutions</span> — Utilize cutting-edge technology for academic and field research.
            </li>
          </List>
        </Container>
      </PaddingWrapper>
    </Section>
  );
};

const StyledButton = styled.div`
  margin: 50px 0 130px;
  width: 300px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
    width: 100%;
  }

  span {
    @media (max-width: ${({ theme }) => theme.breakpoints.mobileSmall}px) {
      font-size: 12px;
    }
  }
`;

const ListHeader = styled.h3`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 44px;
  font-weight: 600;
  line-height: normal;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
  max-width: 800px;
  padding-left: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 16px;
  }

  > li {
    padding-top: 40px;
  }

  > li::marker {
    color: ${({ theme }) => theme.colors.rawSienna};
  }

  > li > span {
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.rawSienna};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      font-size: 16px;
    }
  }
`;
