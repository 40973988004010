import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  url?: string;
  bold?: boolean;
}

export const ListItem = ({ children, url, bold }: Props) => {
  return (
    <StyledListItem {...{ bold }}>
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        children
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled.li<{ bold?: boolean }>`
  font-weight: ${({ bold }) => bold && 600};
`;
