import { Trans } from 'react-i18next';
import { ArticlesList } from 'components/Article/styled';
import ArticleWrapper from 'components/Article/ArticleWrapper';
import LegalPageLayout from 'components/LegalContentWrapper';

interface DigitalTwinPrivacyPolicySectionProps {
  title: string;
  list: {
    id: number;
    title: string;
    description: string;
  }[];
}

const DigitalTwinPrivacyPolicySection = ({ title, list }: DigitalTwinPrivacyPolicySectionProps) => {
  return (
    <LegalPageLayout title={title} digitalTwin={true}>
      <ArticlesList>
        {list.map(({ id, title, description }) => (
          <ArticleWrapper key={id} title={title}>
            <Trans i18nKey={description} components={{ 1: <ol />, 2: <ul />, 3: <li /> }} />
          </ArticleWrapper>
        ))}
      </ArticlesList>
    </LegalPageLayout>
  );
};

export default DigitalTwinPrivacyPolicySection;
