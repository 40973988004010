import styled from 'styled-components';

export const StyledRequestAccessForm = styled.form`
  padding-top: 87px;
  max-width: 711px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 100%;
    padding-top: 84px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-top: 40px;
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    gap: 56px;
  }
`;

export const GridWWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 42px 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 42px 90px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;
