import styled from 'styled-components';

export const StyledCaseStudySection = styled.section`
  h1 {
    font-size: 48px;
    max-width: 598px;
    line-height: normal;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 40px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      font-size: 32px;
    }
  }
`;

export const CaseStudySectionContent = styled.div`
  padding: 0 80px 140px 0;
  margin: 42px 0 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}px) {
    padding: 0 50px 140px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 126px 102px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 0 40px 80px 0;
  }
`;

export const DownloadFile = styled.div`
  margin: 60px 0 0;
  display: flex;
  flex-direction: column;
  gap: 42px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 54px 0 0;
    gap: 35px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 42px 0 0;
    gap: 38px;
  }
`;
