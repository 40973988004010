import styled, { css } from 'styled-components';

import { type CSSProperties } from 'react';
import { type Palette } from 'ui/types';
import { APP_SCALE_MAX_HEIGHT, EXTERNAL_SCALE_MAX_HEIGHT } from 'common/constants';

type CustomMapScaleVariants = 'LIGHT' | 'DARK' | 'EXTERNAL';

type CustomMapScaleStyles = Required<Pick<CSSProperties, 'color' | 'borderColor' | 'maxHeight'>>;

const styles: Palette<CustomMapScaleVariants, CustomMapScaleStyles> = {
  LIGHT: {
    color: 'white',
    borderColor: 'white',
    maxHeight: APP_SCALE_MAX_HEIGHT,
  },
  DARK: {
    color: 'masala',
    borderColor: 'masala',
    maxHeight: APP_SCALE_MAX_HEIGHT,
  },
  EXTERNAL: {
    color: 'masala',
    borderColor: 'masala',
    maxHeight: EXTERNAL_SCALE_MAX_HEIGHT,
  },
};

interface CustomScaleControlProps {
  variant: CustomMapScaleVariants;
  label?: string;
  height?: number;
}

export const CustomMapScale = ({ label, height, variant }: CustomScaleControlProps) => (
  <Scale visible={!!height} variant={variant}>
    <Ruler height={height} variant={variant} />
    {label}
  </Scale>
);

CustomMapScale.defaultProps = {
  variant: 'LIGHT' as CustomMapScaleVariants,
};

const Scale = styled.div<{ visible: boolean; variant: CustomMapScaleVariants }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  margin-top: 4px;
  border-color: ${({ theme, variant }) => theme.colors[styles[variant!].borderColor as keyof typeof theme.colors]};
  color: ${({ theme, variant }) => theme.colors[styles[variant!].color as keyof typeof theme.colors]};
  font-size: 8px;
  font-weight: 500;
  line-height: 2.5;

  ${({ variant }) =>
    variant === 'EXTERNAL' &&
    css`
      position: absolute;
      z-index: 500;
      bottom: 5px;
      right: 25px;
      font-size: 28px;
    `};
`;

const Ruler = styled.div<{ height?: number; variant: CustomMapScaleVariants }>`
  height: ${({ height }) => (height ? `${height + 2}px` : 'none')};
  max-height: ${({ variant }) => `${Number(styles[variant].maxHeight) + 2}px`};
  width: ${({ variant }) => (variant === 'EXTERNAL' ? 16 : 8)}px;
  border: ${({ variant }) => (variant === 'EXTERNAL' ? 3 : 2)}px solid;
  border-color: inherit;
  border-left: none;
`;
