import { useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

import { ROUTES } from 'common/routes';
import { PaddingWrapper } from 'components/PaddingWrapper';
import useMediaQuery from 'hooks/useMediaQuery';
import { theme } from 'theme';
import { Container } from '../Container';
import NavigationList from './NavigationList';
import { useLightSectionsInView } from './useLightSectionsInView';
import {
  Backdrop,
  Hamburger,
  NavigationContentWrapper,
  NavigationLogosWrapper,
  NavigationLogo,
  NavigationLogoText,
  NavigationDigitalTwinHeader,
  NavigationLogos,
  NavigationWrapper,
  StyledNavigation,
} from './styled';

const Navigation = ({ digitalTwin = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const matchesNavigation = useMediaQuery(`(max-width: ${theme.breakpoints.navigation}px)`);
  const { isLightSectionsInView } = useLightSectionsInView();

  const closeMenu = () => setIsMenuOpen(false);

  const onLogoClick = () => {
    closeMenu();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleMenuOpen = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  return (
    <StyledNavigation>
      <NavigationWrapper isLightBackground={isLightSectionsInView}>
        <PaddingWrapper size="small" type="inline">
          <Container size="wide" align="center">
            <NavigationContentWrapper>
              <NavigationLogosWrapper>
                <Link to="/" onClick={onLogoClick}>
                  <NavigationLogos paddingRight={digitalTwin}>
                    <NavigationLogo />
                    <NavigationLogoText />
                  </NavigationLogos>
                </Link>
                {digitalTwin && <Link to={ROUTES.digitalTwin} onClick={onLogoClick}><NavigationDigitalTwinHeader>{t("navigation.productName")}</NavigationDigitalTwinHeader></Link>}
              </NavigationLogosWrapper>
              {!matchesNavigation && <NavigationList isLightSectionsInView={isLightSectionsInView} digitalTwin={digitalTwin} />}

              {matchesNavigation && (
                <Hamburger onClick={toggleMenuOpen} open={isMenuOpen}>
                  <span />
                  <span />
                  <span />
                  <span />
                </Hamburger>
              )}
            </NavigationContentWrapper>
          </Container>
        </PaddingWrapper>
      </NavigationWrapper>
      {matchesNavigation && (
        <NavigationList isMenuOpen={isMenuOpen} closeMenu={closeMenu} isLightSectionsInView={isLightSectionsInView} digitalTwin={digitalTwin} />
      )}
      {matchesNavigation && <Backdrop onClick={() => setIsMenuOpen(false)} open={isMenuOpen} />}
    </StyledNavigation>
  );
};

export default Navigation;
