import styled, { css } from 'styled-components';

import callToAction from 'assets/images/callToAction/call-to-action.jpg';
import reportsCallToAction from 'assets/images/callToAction/reports-call-to-action.jpg';
import { Paragraph } from 'ui/atoms/paragraph';

export const StyledCallToAction = styled.section`
  padding: 104px 0;
  background-color: ${({ theme }) => theme.colors.copper};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 117px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 56px 0 76px;
  }
`;

export const CallToActionContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 630px) 468px;
  grid-template-rows: 385px;
  gap: 100px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 346px;
    gap: 90px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: 28px;
  }
`;

export const CallToActionImage = styled.div<{ reports?: boolean }>`
  height: 100%;
  align-self: flex-end;
  background: ${({ reports }) => `no-repeat center / cover url(${reports ? reportsCallToAction : callToAction})`};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-position-x: 70%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    aspect-ratio: 18 / 11;
  }
`;

export const CallToActionArticle = styled.article`
  margin-top: -10px;
  display: flex;
  flex-direction: column;
`;

export const CallToActionHeading = styled.h2`
  position: relative;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 50px;
  font-weight: 600;
  margin: 0 0 15px;
  min-height: 133px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    top: 10%;
    height: 94px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 34px;
    max-width: 350px;
    min-height: auto;
    margin: 0 0 25px;

    &::before {
      height: 65px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 24px;
    margin: 0 0 35px;
    max-width: 200px;

    &::before {
      left: -10px;
      height: 45px;
    }
  }
`;

export const CallToActionDescription = styled(Paragraph) <{ reports?: boolean }>`
  min-height: 158px;

  strong {
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 12px;
    line-height: 18px;
    min-height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-size: 14px;
    line-height: 20px;
  }

  ${({ reports }) =>
    reports &&
    css`
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: normal;

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: 20px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
        font-size: 16px;
      }
    `}
`;

export const CallToActionButtons = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    margin-top: auto;

    a {
      max-width: 230px;
      height: 56px;
      font-size: 13px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 40px 0 0 0;

    a {
      max-width: 100%;
      height: 50px;
      width: 100%;
    }
  }
`;
