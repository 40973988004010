export const PUBLIC_CONTAINER_URL = process.env.REACT_APP_PUBLIC_CONTAINER_URL;

export const TERRAEYE_APP_BASE_URL = 'https://app.terraeye.co';
export const TERRAEYE_APP_SIGN_UP_URL = 'https://app.terraeye.co/#/signup';

export const SCALE_DISTANCES = [
  200000, 100000, 50000, 30000, 20000, 10000, 5000, 3000, 2000, 1000, 500, 300, 200, 100, 50, 30, 20, 10, 5, 3, 2, 1,
];

export const APP_SCALE_MAX_HEIGHT = 100;
export const EXTERNAL_SCALE_MAX_HEIGHT = 200;

export const CASE_STUDIES_SECTION_ID = 'case-studies';
export const ECOSYSTEM_SECTION_ID = 'ecosystem';
export const PRICING_SECTION_ID = 'pricing';

export const ABOUT_SECTION_ID = 'about';
export const FEATURES_SECTION_ID = 'features';
export const BENEFITS_SECTION_ID = 'benefits';

export const LIGHT_SECTIONS_ID = 'light-sections';
