import { PaddingWrapper } from 'components/PaddingWrapper';
import { Container as LayoutContainer } from 'components/Container';
import { NewsletterForm } from './NewsletterForm';
import styled from 'styled-components';

export const Newsletter = () => {
  return (
    <StyledNewsletter>
      <PaddingWrapper size="small" type="inline">
        <LayoutContainer size="wide" align="center">
          <h2>stay in the know</h2>
          <NewsletterForm />
        </LayoutContainer>
      </PaddingWrapper>
    </StyledNewsletter>
  );
};

const StyledNewsletter = styled.section`
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.mineShaftLight};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding: 75px 0;
  }

  h2 {
    margin: 0;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.rawSienna};

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
