import DataProcessingArticle from '../DataProcessingArticle';
import DesktopVideos from './DesktopVideos';
import { articlesData } from '../articlesData';
import { DesktopViewArticlesList, StyledDesktopView, DesktopViewContent } from './styled';

type DesktopViewProps = {
  hide: boolean;
};

const DesktopView = ({ hide }: DesktopViewProps) => {
  return (
    <StyledDesktopView hide={hide}>
      <DesktopViewContent>
        <DesktopViewArticlesList>
          {articlesData.map(({ id, title, description }) => (
            <li key={id}>
              <DataProcessingArticle title={title} description={description} />
            </li>
          ))}
        </DesktopViewArticlesList>

        <DesktopVideos />
      </DesktopViewContent>
    </StyledDesktopView>
  );
};

export default DesktopView;
